import { Select } from "antd";

function generateOptions(minHour, maxHour) {
    const options = [];
    for (let i = minHour; i <= maxHour; i++) {
        options.push({
            label: `${i.toString().padStart(2, '0')}:00`,
            value: i,
        });
    }

    return options;
}


export default function AirbnbCheckoutTimeInput(props) {
    const {
        value,
        onChange,
        minHour,
        maxHour,
    } = props;

    return (
        <Select
            options={generateOptions(minHour, maxHour)}
            value={value}
            onChange={value => onChange(value)}
            style={{
                width: '100px',
            }}
        />
    );
}