import { useState } from "react"
import { Col, Form, message, Modal, Row, Typography } from "antd";
import { green, grey } from "@ant-design/colors";
import { CheckCircleFilled, HourglassOutlined, SaveOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { taskPromise } from "common/task";
import ApartmentAvailabilitySettingsForm from "apartments/forms/ApartmentAvailabilitySettingsForm";

const QUERY = gql`
    query GetApartmentForOnboarding($apartmentId: ID!) {
        apartment(apartmentId: $apartmentId) {
            id
            maxAvailability
            cutOffTime
            weekdayMinStay
            onboardingAvailabilityDone
        }
    }
`;

const MUTATION_ONBOARDING = gql`
    mutation UpdateApartmentOnboarding($input: UpdateApartmentOnboardingInput!) {
        updateApartmentOnboarding(input: $input) {
            error {
                type
                message
            }
            apartment {
                id
                onboardingAvailabilityDone
            }
        }
    }
`;

const MUTATION_AVAILABILITY_SETTINGS = gql`
    mutation UpdateApartmentAvailabilityOnboarding($input: UpdateApartmentAvailabilitySettingsInput!) {
        updateApartmentAvailabilitySettings(input: $input) {
            error {
                type
                message
            }
            apartment {
                id
                maxAvailability
                cutOffTime
                weekdayMinStay
                onboardingAvailabilityDone
            }
            syncApartmentTask {
                id
            }
        }
    }
`;

export default function ApartmentAvailabilityOnboardingItem(props) {
    const {
        apartmentId,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { data } = useQuery(QUERY, { variables: { apartmentId } });
    const [updateApartmentOnboarding] = useMutation(MUTATION_ONBOARDING);
    const [updateApartmentAvailabilitySettings] = useMutation(MUTATION_AVAILABILITY_SETTINGS);

    const [form] = Form.useForm();

    const done = data?.apartment?.onboardingAvailabilityDone;

    async function handleSave() {
        try {
            const values = await form.validateFields();
            setLoading(true);

            const updateAvailabilityResponse = await updateApartmentAvailabilitySettings({
                variables: {
                    input: {
                        apartmentId,
                        maxAvailability: values.unitSize.choice === 'single' ? 1 : values.unitSize.value,
                        cutOffTime: values.cutOffTime,
                        weekdayMinStay: values.weekdayMinStay,
                    },
                },
            });

            if (updateAvailabilityResponse?.data?.updateApartmentAvailabilitySettings?.error) {
                message.error("Failed to update apartment availability");
                setLoading(false);
                return;
            }

            try {
                await taskPromise(updateAvailabilityResponse?.data?.updateApartmentAvailabilitySettings?.syncApartmentTask?.id);
            }
            catch {
                message.error("Failed to update apartment availability");
                setLoading(false);
                return;
            }

            const updateOnboardingResponse = await updateApartmentOnboarding({
                variables: {
                    input: {
                        apartmentId,
                        onboardingAvailabilityDone: true,
                    },
                },
            });

            if (updateOnboardingResponse?.data?.updateApartmentOnboarding?.error) {
                message.error("Failed to update apartment onboarding");
                setLoading(false);
                return;
            }

            message.success("Apartment availability updated");
            setLoading(false);
            setModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setLoading(false);
        }
    }

    return (
        <Row gutter={[16, 16]}>
            <Col flex={0}>
                {done && (
                    <CheckCircleFilled
                        style={{
                            color: green.primary,
                        }}
                    />
                )}
                {!done && (
                    <HourglassOutlined
                        style={{
                            color: grey.primary,
                        }}
                    />
                )}
            </Col>
            <Col flex={1}>
                Setup availability
            </Col>
            <Col>
                <Typography.Link
                    onClick={() => setModalOpen(true)}
                >
                    Setup
                </Typography.Link>
            </Col>
            <Modal
                open={modalOpen}
                title="Setup availability"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: loading,
                }}
                onOk={() => handleSave()}
                onCancel={() => setModalOpen(false)}
                destroyOnClose
                width={700}
            >
                <ApartmentAvailabilitySettingsForm
                    form={form}
                    preserve={false}
                    apartment={data?.apartment}
                    initialValues={{
                        unitSize: {
                            choice: data?.apartment.maxAvailability === 1 ? 'single' : 'multiple',
                            validate: data?.apartment.maxAvailability,
                        },
                        cutOffTime: data?.apartment?.cutOffTime,
                        weekdayMinStay: data?.apartment?.weekdayMinStay ?? [1, 1, 1, 1, 1, 1, 1],
                    }}
                />
            </Modal>
        </Row>
    );
}