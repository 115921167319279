const FEE_TYPE_FROM_BACKEND = {
    service_charge: 'serviceCharge',
    resort_fee: 'resortFee',
    cleaning_fee: 'cleaningFee',
    towel_fee: 'towelFee',
    electricity_fee: 'electricityFee',
    bed_linen_fee: 'bedLinenFee',
    gas_fee: 'gasFee',
    oil_fee: 'oilFee',
    wood_fee: 'woodFee',
    water_fee: 'waterFee',
    environment_fee: 'environmentFee',
    spa_fee: 'spaFee',
    transfer_fee: 'transferFee',
}

const FEE_TYPE_TO_BACKEND = {
    serviceCharge: 'service_charge',
    resortFee: 'resort_fee',
    cleaningFee: 'cleaning_fee',
    towelFee: 'towel_fee',
    electricityFee: 'electricity_fee',
    bedLinenFee: 'bed_linen_fee',
    gasFee: 'gas_fee',
    oilFee: 'oil_fee',
    woodFee: 'wood_fee',
    waterFee: 'water_fee',
    environmentFee: 'environment_fee',
    spaFee: 'spa_fee',
    transferFee: 'transfer_fee',
}

const FEE_UNIT_FROM_BACKEND = {
    per_reservation: 'perReservation',
    per_guest: 'perGuest',
    per_night: 'perNight',
    per_guest_per_night: 'perGuestPerNight',
    percent_of_reservation_price: 'percentOfReservationPrice',
};

const FEE_UNIT_TO_BACKEND = {
    perReservation: 'per_reservation',
    perGuest: 'per_guest',
    perNight: 'per_night',
    perGuestPerNight: 'per_guest_per_night',
    percentOfReservationPrice: 'percent_of_reservation_price',
};

const DISCOUNT_TYPE_FROM_BACKEND = {
    length_of_stay: 'lengthOfStay',
    last_minute: 'lastMinute',
    first_minute: 'firstMinute',
};

const DISCOUNT_TYPE_TO_BACKEND = {
    lengthOfStay: 'length_of_stay',
    lastMinute: 'last_minute',
    firstMinute: 'first_minute',
};

const ALLOW_PETS_FROM_BACKEND = {
    not_allowed: 'notAllowed',
    allowed: 'allowed',
    allowed_on_request: 'allowedOnRequest',
};

const ALLOW_PETS_TO_BACKEND = {
    notAllowed: 'not_allowed',
    allowed: 'allowed',
    allowedOnRequest: 'allowed_on_request',
};

export function bookingFromBackend(data) {
    const guestPricing = {
        mode: data?.guest_pricing?.mode,
    };

    if (data?.guest_pricing?.mode === 'simple') {
        guestPricing.pricing = {
            baseGuestCount: data?.guest_pricing?.pricing?.base_guest_count,
            extraGuestFee: data?.guest_pricing?.pricing?.extra_guest_fee,
        }
    }
    if (data?.guest_pricing?.mode === 'complex') {
        guestPricing.pricing = data?.guest_pricing?.pricing;
    }

    const fees = [...data?.fees ?? []].map(fee => ({
        type: FEE_TYPE_FROM_BACKEND[fee.type],
        unit: FEE_UNIT_FROM_BACKEND[fee.unit],
        amount: fee.amount,
    }));

    const discounts = [...data?.discounts ?? []].map(discount => ({
        type: DISCOUNT_TYPE_FROM_BACKEND[discount.type],
        amount: discount.amount,
    }));

    const nonRefundableRate = {
        enabled: data?.non_refundable_rate?.enabled ?? false,
    };

    if (data?.non_refundable_rate?.enabled) {
        nonRefundableRate.discount = data?.non_refundable_rate?.discount;
    }

    const cancelationPolicy = {
        gracePeriod: data?.cancelation_policy?.grace_period ?? 'disabled',
        advanceCancelation: data?.cancelation_policy?.advance_cancelation ?? 'disabled',
    }

    const curfew = {
        enabled: data?.curfew?.enabled ?? false,
    };

    if (data?.curfew?.enabled) {
        curfew.start = data?.curfew?.start;
        curfew.end = data?.curfew?.end;
    }

    const quietHours = {
        enabled: data?.quiet_hours?.enabled ?? false,
    };

    if (data?.quiet_hours?.enabled) {
        quietHours.start = data?.quiet_hours?.start;
        quietHours.end = data?.quiet_hours?.end;
    }

    const renovation = {
        enabled: data?.renovation?.enabled ?? false,
    }

    if (data?.renovation?.enabled) {
        renovation.start = data?.renovation?.start;
        renovation.end = data?.renovation?.end;
    }

    const ageRestriction = {
        enabled: data?.age_restriction?.enabled ?? false,
    };

    if (data?.age_restriction?.enabled) {
        ageRestriction.min = data?.age_restriction?.min;
        ageRestriction.max = data?.age_restriction?.max;
    }

    const allowChildren = {
        enabled: data?.allow_children?.enabled ?? false,
    };

    if (!data?.allow_children?.enabled) {
        allowChildren.minAge = data?.allow_children?.min_age;
    }

    return {
        guestPricing,
        fees,
        discounts,
        nonRefundableRate,
        cancelationPolicy,
        checkInTimeStart: data?.check_in_time_start,
        checkInTimeEnd: data?.check_in_time_end,
        checkOutTimeStart: data?.check_out_time_start,
        checkOutTimeEnd: data?.check_out_time_end,
        curfew,
        quietHours,
        renovation,
        ageRestriction,
        allowChildren,
        allowPets: ALLOW_PETS_FROM_BACKEND[data?.allow_pets],
        allowSmoking: data?.allow_smoking,
    };
}

export function bookingToBackend(data) {
    const guest_pricing = {
        mode: data?.guestPricing?.mode,
    };

    if (data?.guestPricing?.mode === 'simple') {
        guest_pricing.pricing = {
            base_guest_count: data?.guestPricing?.pricing?.baseGuestCount,
            extra_guest_fee: data?.guestPricing?.pricing?.extraGuestFee,
        }
    }
    if (data?.guestPricing?.mode === 'complex') {
        guest_pricing.pricing = data?.guestPricing?.pricing;
    }

    const fees = [...data?.fees ?? []].map(fee => ({
        type: FEE_TYPE_TO_BACKEND[fee.type],
        unit: FEE_UNIT_TO_BACKEND[fee.unit],
        amount: fee.amount,
    }));

    const discounts = [...data?.discounts ?? []].map(discount => ({
        type: DISCOUNT_TYPE_TO_BACKEND[discount.type],
        amount: discount.amount,
    }));

    const non_refundable_rate = {
        enabled: data?.nonRefundableRate?.enabled ?? false,
    };

    if (data?.nonRefundableRate?.enabled) {
        non_refundable_rate.discount = data?.nonRefundableRate?.discount;
    }

    const cancelation_policy = {};
    if (data?.cancelationPolicy?.gracePeriod !== 'disabled') {
        cancelation_policy.grace_period = data?.cancelationPolicy?.gracePeriod;
    }
    if (data?.cancelationPolicy?.advanceCancelation !== 'disabled') {
        cancelation_policy.advance_cancelation = data?.cancelationPolicy?.advanceCancelation;
    }

    const curfew = {
        enabled: data?.curfew?.enabled ?? false,
    };

    if (data?.curfew?.enabled) {
        curfew.start = data?.curfew?.start;
        curfew.end = data?.curfew?.end;
    }

    const quiet_hours = {
        enabled: data?.quietHours?.enabled ?? false,
    };

    if (data?.quietHours?.enabled) {
        quiet_hours.start = data?.quietHours?.start;
        quiet_hours.end = data?.quietHours?.end;
    }

    const renovation = {
        enabled: data?.renovation?.enabled ?? false,
    }

    if (data?.renovation?.enabled) {
        renovation.start = data?.renovation?.start;
        renovation.end = data?.renovation?.end;
    }

    const age_restriction = {
        enabled: data?.ageRestriction?.enabled ?? false,
    };

    if (data?.ageRestriction?.enabled) {
        age_restriction.min = data?.ageRestriction?.min;
        age_restriction.max = data?.ageRestriction?.max;
    }

    const allow_children = {
        enabled: data?.allowChildren?.enabled ?? false,
    };

    if (!data?.allowChildren?.enabled) {
        allow_children.min_age = data?.allowChildren?.minAge;
    }

    return {
        guest_pricing,
        fees,
        discounts,
        non_refundable_rate,
        cancelation_policy,
        check_in_time_start: data?.checkInTimeStart,
        check_in_time_end: data?.checkInTimeEnd,
        check_out_time_start: data?.checkOutTimeStart,
        check_out_time_end: data?.checkOutTimeEnd,
        curfew,
        quiet_hours,
        renovation,
        age_restriction,
        allow_children,
        allow_pets: ALLOW_PETS_TO_BACKEND[data?.allowPets],
        allow_smoking: data?.allowSmoking,
    };
}