import { Col, InputNumber, Row, Select } from "antd";

export default function AirbnbDiscountInput(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <Row
            gutter={[16, 16]}
            align="middle"
            wrap={false}
        >
            <Col span={8}>
                <Select
                    options={[
                        {
                            label: 'Weekly discount',
                            value: 'weeklyDiscount',
                        },
                        {
                            label: 'Monthly discount',
                            value: 'monthlyDiscount',
                        },
                        {
                            label: 'Length of stay',
                            value: 'lengthOfStay',
                        },
                        {
                            label: 'Last minute',
                            value: 'lastMinute',
                        },
                        {
                            label: 'First minute',
                            value: 'firstMinute',
                        },
                    ]}
                    value={value?.type}
                    onChange={type => onChange({ ...value ?? {}, type })}
                    placeholder="Type"
                    style={{
                        width: '100%',
                    }}
                />
            </Col>
            <Col span={8}>
                <InputNumber
                    value={value?.amount}
                    onChange={amount => onChange({ ...value ?? {}, amount })}
                    style={{
                        textAlignLast: 'right',
                    }}
                    controls={false}
                    min={1}
                    max={100}
                    addonAfter="%"
                    placeholder="Discount"
                />
            </Col>
            <Col span={8}>
                {['lengthOfStay', 'lastMinute', 'firstMinute'].includes(value?.type) && (
                    <InputNumber
                        value={value?.threshold}
                        onChange={threshold => onChange({ ...value ?? {}, threshold })}
                        style={{
                            textAlignLast: 'right',
                        }}
                        controls={false}
                        min={1}
                        max={100}
                        addonAfter="days"
                        placeholder="Threshold"
                    />
                )}
            </Col>
        </Row>
    );
}