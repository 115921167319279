import { gql, useQuery } from "@apollo/client";
import { Alert, Col, Row, Skeleton } from "antd";
import ApartmentTagsOnboardingItem from "./ApartmentTagsOnboardingItem";
import ApartmentLocationOnboardingItem from "./ApartmentLocationOnboardingItem";
import ApartmentCalendarOnboardingItem from "./ApartmentCalendarOnboardingItem";
import ApartmentOccupancyOnboardingItem from "./ApartmentOccupancyOnboardingItem";
import ApartmentAvailabilityOnboardingItem from "./ApartmentAvailabilityOnboardingItem";
import ApartmentPricingOnboardingItem from "./ApartmentPricingOnboardingItem";
import ApartmentChannelsOnboardingItem from "./ApartmentChannelsOnboardingItem";
import ApartmentOpenCalendarOnboardingItem from "./ApartmentOpenCalendarOnboardingItem";

const QUERY = gql`
    query GetApartmentForOnboarding($apartmentId: ID!) {
        apartment(apartmentId: $apartmentId) {
            id
            name
            tags
            address
            latitude
            longitude
            storage {
                id
            }
            timezone
            calendarLength
            maxAvailability
            maxGuestCount
            cutOffTime
            weekdayMinStay
            cleaningPrice
            currency
            defaultPrice
            occupancyPriceModifiers
            channels {
                id
                active
                otaName
                mapping
                otaSettings
                status
                statusMessage
                createdAt
            }
            onboardingTagsDone
            onboardingMetadataDone
            onboardingLocationDone
            onboardingCalendarDone
            onboardingOccupancyDone
            onboardingAvailabilityDone
            onboardingPricingDone
            onboardingChannelsDone
            onboardingOpenCalendarDone
        }
    }
`;

export default function ApartmentOnboarding(props) {
    const {
        apartmentId,
    } = props;

    const { loading, error } = useQuery(QUERY, { variables: { apartmentId } });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load apartment for onboarding"
            />
        );
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <ApartmentTagsOnboardingItem
                    apartmentId={apartmentId}
                />
            </Col>
            <Col span={24}>
                <ApartmentLocationOnboardingItem
                    apartmentId={apartmentId}
                />
            </Col>
            <Col span={24}>
                <ApartmentCalendarOnboardingItem
                    apartmentId={apartmentId}
                />
            </Col>
            <Col span={24}>
                <ApartmentOccupancyOnboardingItem
                    apartmentId={apartmentId}
                />
            </Col>
            <Col span={24}>
                <ApartmentAvailabilityOnboardingItem
                    apartmentId={apartmentId}
                />
            </Col>
            <Col span={24}>
                <ApartmentPricingOnboardingItem
                    apartmentId={apartmentId}
                />
            </Col>
            <Col span={24}>
                <ApartmentChannelsOnboardingItem
                    apartmentId={apartmentId}
                />
            </Col>
            <Col span={24}>
                <ApartmentOpenCalendarOnboardingItem
                    apartmentId={apartmentId}
                />
            </Col>
        </Row>
    );
}