import { Form, Select, Switch } from "antd";
import { TRIGGER_DEFINITIONS } from "automation/common";
import CodeEditor from "stayql/components/code-editor/CodeEditor";

export default function TriggerAutomationForm(props) {
    const {
        form,
        availableTriggers,
        ...otherProps
    } = props;

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="dryRun"
                label="Dry run"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            <Form.Item
                name="trigger"
                label="Trigger"
                rules={[{ required: true, message: 'Trigger is required' }]}
            >
                <Select
                    options={
                        TRIGGER_DEFINITIONS
                            .filter(trigger => availableTriggers.includes(trigger.type))
                            .map(trigger => ({
                                value: trigger.type,
                                label: trigger.label,
                            }))
                    }
                />
            </Form.Item>
            <Form.Item
                name="triggerData"
                label="Data"
                rules={[{ required: true, message: 'Data is required' }]}
            >
                <CodeEditor
                    language="json"
                />
            </Form.Item>
        </Form>
    );
}