import { Form } from "antd";
import UserGroupSelect from "user-groups/components/user-group-select/UserGroupSelect";

export default function UserGroupIdForm(props) {
    const {
        form,
        ...otherProps
    } = props;

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="value"
                label="User group"
                rules={[{ required: true, message: 'User group is required' }]}
            >
                <UserGroupSelect />
            </Form.Item>
        </Form>
    );
}