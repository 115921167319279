import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { Checkbox, Modal, Popover, Tooltip } from "antd";
import { ClockCircleOutlined, EllipsisOutlined, MinusCircleOutlined, UserOutlined, WarningFilled } from "@ant-design/icons";
import { differenceInDays, formatISO, isEqual } from "date-fns";
import classNames from "classnames";
import NoDepartureInformation from "./NoDepartureInformation";
import NoArrivalInformation from "./NoArrivalInformation";
import CheckinTime from "./CheckinTime";
import CheckoutTime from "./CheckoutTime";
import JobsList from "./JobsList";
import ContextMenu from "./ContextMenu";
import "./style.css";
import { JOB_DATE_TYPE_FIXED } from "jobs-new/common";

const QUERY = gql`
    query GetApartmentForCleaningApartment($apartmentId: ID!, $date: Date!) {
        apartment(apartmentId: $apartmentId) {
            id
            name
            # increasePriceAbove
            storage {
                id
                name
                order
            }
            previousReservation(date: $date) {
                id
                endDate
                checkoutTime
                numberOfGuests
            }
            arrivingReservation(date: $date) {
                id
                airbnbThreadId
            }
            nextReservation(date: $date) {
                id
                startDate
                checkinTime
                numberOfGuests
            }
            jobsNew(filter: {dates: [$date]}) {
                id
            }
        }
    }
`;

export default function UnassignedApartmentCleaning(props) {
    const {
        apartmentId,
        date,
        selected,
        size,
        onSelect,
        disabled,
    } = props;

    const navigate = useNavigate();
    const [jobsModalOpen, setJobsModalOpen] = useState(false);
    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            apartmentId,
            date: formatISO(date, { representation: 'date' }),
        },
    });

    if (loading) {
        return (
            <div
                className={classNames({
                    "cleaning-apartment-container": true,
                    "cleaning-apartment-container-small": !size || size === 'small',
                    "cleaning-apartment-container-medium": size === 'medium',
                    "cleaning-apartment-container-large": size === 'large',
                })}
            >
                <div className="cleaning-apartment-apartment-name">
                    <div className="cleaning-apartment-loading" />
                </div>
                <div className="cleaning-apartment-storage-name">
                    <div className="cleaning-apartment-loading" />
                </div>
                <div className="cleaning-apartment-departure-container">
                    <div className="cleaning-apartment-loading" />
                </div>
                <div className="cleaning-apartment-arrival-container">
                    <div className="cleaning-apartment-loading" />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div
                className={classNames({
                    "cleaning-apartment-container": true,
                    "cleaning-apartment-container-small": !size || size === 'small',
                    "cleaning-apartment-container-medium": size === 'medium',
                    "cleaning-apartment-container-large": size === 'large',
                })}
            >
                <div className="cleaning-apartment-error">
                    Failed to load apartment
                </div>
            </div>
        );
    }

    const goToConversationDisabled = !data.apartment.arrivingReservation?.airbnbThreadId;

    function handleAction(action) {
        if (action === 'jobs') {
            handleJobs();
        }
        if (action === 'addJob') {
            handleAddJob();
        }
        if (action === 'goToConversation') {
            handleGoToConversation();
        }
    }

    function handleJobs() {
        setJobsModalOpen(true);
    }

    function handleAddJob() {
        navigate('/jobs-new/create', {
            state: {
                location: { apartmentId },
                dateType: JOB_DATE_TYPE_FIXED,
                date,
                nextUrl: window.location.pathname,
            },
        });
    }

    function handleGoToConversation() {
        window.open(`https://www.airbnb.pl/hosting/inbox/folder/all/thread/${data.apartment.arrivingReservation.airbnbThreadId}`);
    }

    return (
        <ContextMenu
            editDisabled
            fixDisabled
            moveToGroupDisabled
            moveToNewGroupDisabled
            addJobForCleanerDisabled
            goToConversationDisabled={goToConversationDisabled}
            unassignDisabled
            onClick={action => handleAction(action)}
            trigger={["contextMenu"]}
        >
            <div
                className={classNames({
                    "cleaning-apartment-container": true,
                    "cleaning-apartment-selected": selected,
                    "cleaning-apartment-disabled": disabled,
                    "cleaning-apartment-container-small": !size || size === 'small',
                    "cleaning-apartment-container-medium": size === 'medium',
                    "cleaning-apartment-container-large": size === 'large',
                })}
            >
                <div className="cleaning-apartment-head">
                    <Checkbox
                        checked={selected}
                        onClick={() => onSelect(apartmentId)}
                        disabled={disabled}
                    />
                </div>
                <div className="cleaning-apartment-apartment-name">
                    {data.apartment.name}
                    {data.apartment.previousReservation && differenceInDays(data.apartment.previousReservation.endDate, data.apartment.previousReservation.startDate) > 14 && (
                        <Tooltip title="Cleaning after long reservation">
                            <WarningFilled className="cleaning-apartment-warning" />
                        </Tooltip>
                    )}
                </div>
                <div className="cleaning-apartment-storage-name">
                    {data.apartment.storage.name}
                </div>

                <div className="cleaning-apartment-no-ongoing-container">
                    <div className="cleaning-apartment-guests">
                        <div className="cleaning-apartment-guests-icon">
                            <UserOutlined />
                        </div>
                        <div className="cleaning-apartment-guests-container">
                            <div className="cleaning-apartment-guests-departing">
                                {!data.apartment.previousReservation && (
                                    <Tooltip title={`There was no reservation in this apartment before`}>
                                        <MinusCircleOutlined />
                                    </Tooltip>
                                )}
                                {data.apartment.previousReservation && (
                                    <span>
                                        {data.apartment.previousReservation.numberOfGuests}
                                    </span>
                                )}
                            </div>
                            <div className="cleaning-apartment-divider">
                                →
                            </div>
                            <div className="cleaning-apartment-guests-arriving">
                                {!data.apartment.nextReservation && (
                                    <Tooltip title={`There is no arriving reservation. This apartment will be planned for ${data.apartment.increasePriceAbove ?? 2} guests`}>
                                        <MinusCircleOutlined />
                                    </Tooltip>
                                )}
                                {data.apartment.nextReservation && (
                                    <span>
                                        {data.apartment.nextReservation.numberOfGuests}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="cleaning-apartment-time">
                        <div className="cleaning-apartment-time-icon">
                            <ClockCircleOutlined />
                        </div>
                        <div className="cleaning-apartment-time-container">
                            <div className="cleaning-apartment-checkout-time">
                                {!data.apartment.previousReservation && (
                                    <span className="cleaning-apartment-no-reservation">
                                        vacant
                                    </span>
                                )}
                                {data.apartment.previousReservation && !isEqual(data.apartment.previousReservation.endDate, date) && (
                                    <Popover
                                        content={
                                            <NoDepartureInformation
                                                apartmentId={apartmentId}
                                                date={date}
                                            />
                                        }
                                        trigger="click"
                                        placement="left"
                                        destroyTooltipOnHide
                                    >
                                        <span className="cleaning-apartment-no-departure">
                                            vacant
                                        </span>
                                    </Popover>
                                )}
                                {data.apartment.previousReservation && isEqual(data.apartment.previousReservation.endDate, date) && (
                                    <CheckoutTime
                                        checkoutTime={data.apartment.previousReservation.checkoutTime}
                                    />
                                )}
                            </div>
                            <div className="cleaning-apartment-divider">
                                →
                            </div>
                            <div className="cleaning-apartment-checkin-time">
                                {!data.apartment.nextReservation && (
                                    <span className="cleaning-apartment-no-reservation">
                                        vacant
                                    </span>
                                )}
                                {data.apartment.nextReservation && !isEqual(data.apartment.nextReservation.startDate, date) && (
                                    <Popover
                                        content={
                                            <NoArrivalInformation
                                                apartmentId={apartmentId}
                                                date={date}
                                            />
                                        }
                                        trigger="click"
                                        placement="left"
                                    >
                                        <span className="cleaning-apartment-no-arrival">
                                            vacant
                                        </span>
                                    </Popover>
                                )}
                                {data.apartment.nextReservation && isEqual(data.apartment.nextReservation.startDate, date) && (
                                    <CheckinTime checkinTime={data.apartment.nextReservation.checkinTime} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cleaning-apartment-jobs">
                    {data.apartment.jobsNew.length > 0 && (
                        <span
                            className="cleaning-apartment-jobs-link"
                            onClick={() => setJobsModalOpen(true)}
                        >
                            {data.apartment.jobsNew.length} {data.apartment.jobsNew.length === 1 ? 'job' : 'jobs'}
                        </span>
                    )}
                </div>
                <div className="cleaning-apartment-actions">
                    <ContextMenu
                        editDisabled
                        fixDisabled
                        moveToGroupDisabled
                        moveToNewGroupDisabled
                        addJobForCleanerDisabled
                        goToConversationDisabled={goToConversationDisabled}
                        unassignDisabled
                        onClick={action => handleAction(action)}
                        trigger={["click"]}
                    >
                        <EllipsisOutlined />
                    </ContextMenu>
                </div>
                <div className="cleaning-apartment-actions" />
                <Modal
                    open={jobsModalOpen}
                    title="Jobs"
                    closable={true}
                    onCancel={() => setJobsModalOpen(false)}
                    footer={false}
                    width={800}
                >
                    <JobsList
                        apartmentId={data.apartment.id}
                        date={date}
                    />
                </Modal>
            </div>
        </ContextMenu>
    );
}