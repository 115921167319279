import { Col, InputNumber, Row } from "antd";

export default function GuestPricingSimpleInput(props) {
    const {
        value,
        maxGuestCount,
        currency,
        onChange,
    } = props;

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <InputNumber
                    value={value?.baseGuestCount}
                    onChange={baseGuestCount => onChange({ ...value, baseGuestCount })}
                    placeholder="Base guest count"
                    min={1}
                    max={maxGuestCount}
                    addonAfter="guests"
                />
            </Col>
            <Col span={24}>
                <InputNumber
                    value={value?.extraGuestFee}
                    onChange={extraGuestFee => onChange({ ...value, extraGuestFee })}
                    placeholder="Extra guest fee"
                    min={1}
                    addonAfter={currency}
                />
            </Col>
        </Row>
    );
}