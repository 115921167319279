import { Handle, Position } from "@xyflow/react";

export function createNodeType({ inputs, outputs, label }) {
    return () => {
        return (
            <>
                {[...inputs ?? []].map((input, index) => (
                    <Handle
                        type="target"
                        id={input.key}
                        position={Position.Left}
                        style={{
                            top: 36 + (index * 24),
                        }}
                        className="node-handle"
                        key={input.key}
                    />
                ))}
                <div className="node-container">
                    <div className="node-title">
                        <span>
                            {label}
                        </span>
                    </div>
                    <div className="node-labels">
                        <div className="node-input-labels">
                            {[...inputs ?? []].map(input => (
                                <div
                                    className="node-input-label"
                                    key={input.key}
                                >
                                    {input.label}
                                </div>
                            ))}
                        </div>
                        <div className="node-output-labels">
                            {[...outputs ?? []].map(output => (
                                <div
                                    className="node-output-label"
                                    key={output.key}
                                >
                                    {output.label}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {[...outputs ?? []].map((output, index) => (
                    <Handle
                        type="source"
                        id={output.key}
                        position={Position.Right}
                        style={{
                            top: 36 + (index * 24)
                        }}
                        className="node-handle"
                        key={output.key}
                    />
                ))}
            </>
        );
    };
}

export function fixNodeWidth(ref) {
    if (ref.current) {
        const currentWidth = ref.current.offsetWidth;
        const extraWidth = currentWidth % 24;

        if (extraWidth > 0) {
            const missingWidth = 24 - extraWidth;
            ref.current.style.width = `${currentWidth + missingWidth}px`;
        }
    }
}