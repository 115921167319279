import { Form, InputNumber } from "antd";

export default function ArrayLengthForm(props) {
    const {
        form,
        ...otherProps
    } = props;

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="length"
                label="Array length"
                rules={[{ required: true, message: 'Array length is required' }]}
            >
                <InputNumber
                    min={0}
                />
            </Form.Item>
        </Form>
    );
}