import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Form, Modal, Popover, Tooltip, message } from "antd";
import { ClockCircleOutlined, EllipsisOutlined, HolderOutlined, MailOutlined, MinusCircleOutlined, QuestionCircleOutlined, TagOutlined, UserOutlined, WarningFilled } from "@ant-design/icons";
import { differenceInDays, formatISO, isAfter, isBefore } from "date-fns";
import classNames from "classnames";
import ApartmentCleaningForm from "cleaning-new/forms/ApartmentCleaningForm";
import NoDepartureInformation from "./NoDepartureInformation";
import NoArrivalInformation from "./NoArrivalInformation";
import CheckoutTime from "./CheckoutTime";
import CheckinTime from "./CheckinTime";
import ReservationInformation from "./ReservationInformation";
import JobsList from "./JobsList";
import ContextMenu from "./ContextMenu";
import { JOB_DATE_TYPE_FIXED } from "jobs-new/common";
import "./style.css";
import { fixCleaningApartment, isApartmentCleaningToFix } from "cleaning-new/common";

const QUERY = gql`
    query GetAssignedApartmentCleaning($cleaningGroupApartmentId: ID!, $date: Date!) {
        cleaningGroupApartment(cleaningGroupApartmentId: $cleaningGroupApartmentId) {
            id
            checkinTime
            checkinTimeOverridenAt
            checkoutTime
            checkoutTimeOverridenAt
            numberOfGuests
            numberOfGuestsOverridenAt
            order
            tags
            note
            apartment {
                id
                name
                # increasePriceAbove
                storage {
                    id
                    name
                }
                previousReservation(date: $date) {
                    id
                    endDate
                    numberOfGuests
                }
                departingReservation(date: $date) {
                    id
                    checkoutTime
                    numberOfGuests
                    bookedAt
                }
                ongoingReservation(date: $date) {
                    id
                    numberOfGuests
                }
                arrivingReservation(date: $date) {
                    id
                    checkinTime
                    numberOfGuests
                    airbnbThreadId
                    bookedAt
                }
                nextReservation(date: $date) {
                    id
                    numberOfGuests
                    bookedAt
                }
                cleanings(filter: {dateLt: $date, order: "desc", limit: 1}) {
                    id
                    group {
                        id
                        date
                    }
                }
                jobsNew(filter: {dates: [$date]}) {
                    id
                }
            }
            storage {
                id
                name
            }
            group {
                id
                cleaners {
                    id
                }
            }
        }
    }
`;

const UPDATE_MUTATION = gql`
    mutation UpdateCleaningGroupApartment($input: UpdateCleaningGroupApartmentInput!) {
        updateCleaningGroupApartment(input: $input) {
            error {
                type
                message
            }
            cleaningGroupApartment {
                id
                checkinTime
                checkinTimeOverridenAt
                checkoutTime
                checkoutTimeOverridenAt
                numberOfGuests
                numberOfGuestsOverridenAt
                tags
                note
                storage {
                    id
                    name
                    order
                }
            }
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation DeleteCleaningGroupApartment($input: DeleteCleaningGroupApartmentInput!) {
        deleteCleaningGroupApartment(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

export default function AssignedApartmentCleaning(props) {
    const {
        cleaningGroupId,
        cleaningGroupApartmentId,
        date,
        selected,
        size,
        order,
        onReorder,
        onDrop,
        onMoveToGroup,
        onMoveToNewGroup,
        disabled,
    } = props;

    const navigate = useNavigate();
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [jobsModalOpen, setJobsModalOpen] = useState(false);
    const [form] = Form.useForm();

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            cleaningGroupApartmentId,
            date: formatISO(date, { representation: 'date' }),
        },
    });
    const [updateCleaningGroupApartment, { loading: updateCleaningGroupApartmentLoading }] = useMutation(UPDATE_MUTATION);
    const [deleteCleaningGroupApartment] = useMutation(DELETE_MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'CleaningGroup',
                    id: cleaningGroupId,
                }),
                fieldName: 'apartments',
            });
            cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'apartmentsForCleaning',
            });
        },
    });

    const ref = useRef();

    const [{ isDragging: dragging }, drag] = useDrag(() => ({
        type: `apartment:${cleaningGroupId}`,
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        isDragging: monitor => monitor.getItem()?.id === cleaningGroupApartmentId,
        item: () => ({
            id: cleaningGroupApartmentId,
            order: order,
        }),
        end: (item, monitor) => {
            if (monitor.didDrop()) {
                onDrop(item);
            }
        },
    }), [cleaningGroupApartmentId, order]);

    const [{ handlerId }, drop] = useDrop(() => ({
        accept: `apartment:${cleaningGroupId}`,
        collect: monitor => ({
            handlerId: monitor.getHandlerId(),
        }),
        hover: (item, monitor) => {
            if (!ref.current) {
                return;
            }

            if (item.id === cleaningGroupApartmentId) {
                // If hovering above itself - do nothing
                return;
            }

            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverHeight = hoverBoundingRect.bottom - hoverBoundingRect.top;
            const mouseOffsetY = monitor.getClientOffset().y;
            const threshold = hoverBoundingRect.top - (hoverHeight / 2);

            let movingDown = false;
            if (order > item.order) {
                // If hovering above element with greater index, it means that the move is downwards
                movingDown = true;
            }

            // Check if dragged element as crossed the middle line - from above or from below
            if (movingDown) {
                const isMouseBelowMiddle = mouseOffsetY > threshold;
                if (!isMouseBelowMiddle) {
                    return;
                }
            }
            else {
                const isMouseAboveMiddle = mouseOffsetY < threshold;
                if (isMouseAboveMiddle) {
                    return;
                }
            }

            // Reorder dragged element to order of the current element
            onReorder({
                fromId: item.id,
                fromOrder: item.order,
                toOrder: order,
            });

            // For performance reasons update the order of dragged element immediately
            item.order = order;
        },
    }), [cleaningGroupApartmentId, order]);

    drag(drop(ref));

    if (dragging) {
        return (
            <div
                ref={ref}
                className={classNames({
                    "cleaning-apartment-container": true,
                    "cleaning-apartment-dragging": true,
                    "cleaning-apartment-container-small": !size || size === 'small',
                    "cleaning-apartment-container-medium": size === 'medium',
                    "cleaning-apartment-container-large": size === 'large',
                })}
                data-handler-id={handlerId}
            />
        );
    }

    if (loading) {
        return (
            <div
                ref={ref}
                className={classNames({
                    "cleaning-apartment-container": true,
                    "cleaning-apartment-container-small": !size || size === 'small',
                    "cleaning-apartment-container-medium": size === 'medium',
                    "cleaning-apartment-container-large": size === 'large',
                })}
                data-handler-id={handlerId}
            >
                <div className="cleaning-apartment-apartment-name">
                    <div className="cleaning-apartment-loading" />
                </div>
                <div className="cleaning-apartment-storage-name">
                    <div className="cleaning-apartment-loading" />
                </div>
                <div className="cleaning-apartment-departure-container">
                    <div className="cleaning-apartment-loading" />
                </div>
                <div className="cleaning-apartment-arrival-container">
                    <div className="cleaning-apartment-loading" />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div
                className={classNames({
                    "cleaning-apartment-container": true,
                    "cleaning-apartment-container-small": !size || size === 'small',
                    "cleaning-apartment-container-medium": size === 'medium',
                    "cleaning-apartment-container-large": size === 'large',
                })}
            >
                <div className="cleaning-apartment-error">
                    Failed to load apartment
                </div>
            </div>
        );
    }

    const cleaningToFix = isApartmentCleaningToFix(data.cleaningGroupApartment);
    const goToConversationDisabled = !data.cleaningGroupApartment.apartment.arrivingReservation?.airbnbThreadId;

    function isAlreadyClean() {
        const previousReservation = data.cleaningGroupApartment.apartment.previousReservation;
        const lastCleaning = data.cleaningGroupApartment?.apartment.cleanings?.[0];

        if (previousReservation && lastCleaning) {
            return !isBefore(lastCleaning.group.date, previousReservation.endDate);
        }
        if (!previousReservation && lastCleaning) {
            return true;
        }

        return false;
    }

    function handleAction(action) {
        if (action === 'edit') {
            handleEdit();
        }
        if (action === 'moveToGroup') {
            handleMoveToGroup();
        }
        if (action === 'moveToNewGroup') {
            handleMoveToNewGroup();
        }
        if (action === 'fix') {
            handleFix();
        }
        if (action === 'jobs') {
            handleJobs();
        }
        if (action === 'addJob') {
            handleAddJob();
        }
        if (action === 'addJobForCleaner') {
            handleAddJobForCleaner();
        }
        if (action === 'goToConversation') {
            handleGoToConversation();
        }
        if (action === 'unassign') {
            handleUnassign();
        }
    }

    function handleEdit() {
        setEditModalOpen(true);
    }

    function handleMoveToGroup() {
        onMoveToGroup();
    }

    function handleMoveToNewGroup() {
        onMoveToNewGroup();
    }

    function handleFix() {
        updateCleaningGroupApartment({
            variables: {
                input: {
                    cleaningGroupApartmentId,
                    ...fixCleaningApartment(data.cleaningGroupApartment),
                },
            },
        });
    }

    function handleJobs() {
        setJobsModalOpen(true);
    }

    function handleAddJob() {
        navigate('/jobs-new/create', {
            state: {
                location: { apartmentId: data.cleaningGroupApartment.apartment.id },
                dateType: JOB_DATE_TYPE_FIXED,
                date,
                nextUrl: window.location.pathname,
            },
        });
    }

    function handleAddJobForCleaner() {
        navigate('/jobs-new/create', {
            state: {
                location: { apartmentId: data.cleaningGroupApartment.apartment.id },
                assignerIds: data.cleaningGroupApartment.group.cleaners.map(cleaner => cleaner.id),
                dateType: JOB_DATE_TYPE_FIXED,
                date,
                nextUrl: window.location.pathname,
            },
        });
    }

    function handleGoToConversation() {
        window.open(`https://www.airbnb.pl/hosting/inbox/folder/all/thread/${data.cleaningGroupApartment.apartment.arrivingReservation.airbnbThreadId}`);
    }

    function handleSave() {
        form
            .validateFields()
            .then(values => {
                updateCleaningGroupApartment({
                    variables: {
                        input: {
                            cleaningGroupApartmentId,
                            storageId: values.storageId,
                            ...(values.numberOfGuests !== data.cleaningGroupApartment.numberOfGuests ? { numberOfGuests: values.numberOfGuests } : {}),
                            ...(values.checkinTime !== data.cleaningGroupApartment.checkinTime ? { checkinTime: values.checkinTime } : {}),
                            ...(values.checkoutTime !== data.cleaningGroupApartment.checkoutTime ? { checkoutTime: values.checkoutTime } : {}),
                            tags: values.tags,
                            note: values.note,
                            override: true,
                        },
                    },
                })
                    .then(response => {
                        if (response.data.updateCleaningGroupApartment.error) {
                            message.error("Failed to update apartment cleaning");
                        }
                        setEditModalOpen(false);
                    })
            });
    }

    function handleUnassign() {
        deleteCleaningGroupApartment({
            variables: {
                input: {
                    cleaningGroupApartmentId,
                },
            },
        });
    }

    return (
        <div
            ref={ref}
            data-handler-id={handlerId}
        >
            <ContextMenu
                fixDisabled={!cleaningToFix}
                addJobForCleanerDisabled={data.cleaningGroupApartment.group.cleaners.length === 0}
                goToConversationDisabled={goToConversationDisabled}
                onClick={action => handleAction(action)}
                trigger={["contextMenu"]}
            >
                <div
                    className={classNames({
                        "cleaning-apartment-container": true,
                        "cleaning-apartment-selected": selected,
                        "cleaning-apartment-disabled": disabled,
                        "cleaning-apartment-container-small": !size || size === 'small',
                        "cleaning-apartment-container-medium": size === 'medium',
                        "cleaning-apartment-container-large": size === 'large',
                    })}
                    // This is to prevent opening context menu from parent elements
                    onContextMenu={e => e.stopPropagation()}
                >
                    <div className="cleaning-apartment-head">
                        <HolderOutlined className="cleaning-apartment-handle" />
                    </div>
                    <div className="cleaning-apartment-apartment-name">
                        <span>
                            {data.cleaningGroupApartment.apartment.name}
                        </span>
                        {data.cleaningGroupApartment.tags?.length > 0 && (
                            <Tooltip title={data.cleaningGroupApartment.tags.join(', ')}>
                                <TagOutlined />
                            </Tooltip>
                        )}
                        {data.cleaningGroupApartment.note && (
                            <Tooltip title={data.cleaningGroupApartment.note}>
                                <MailOutlined />
                            </Tooltip>
                        )}
                        {data.cleaningGroupApartment.apartment.previousReservation && differenceInDays(data.cleaningGroupApartment.apartment.previousReservation.endDate, data.cleaningGroupApartment.apartment.previousReservation.startDate) > 14 && (
                            <Tooltip title="Cleaning after long reservation">
                                <WarningFilled className="cleaning-apartment-warning" />
                            </Tooltip>
                        )}
                        {isAlreadyClean() && (
                            <Tooltip title="Apartment should be already clean">
                                <WarningFilled className="cleaning-apartment-warning" />
                            </Tooltip>
                        )}
                    </div>
                    <div className="cleaning-apartment-storage-name">
                        {data.cleaningGroupApartment.storage.id !== data.cleaningGroupApartment.apartment.storage.id && (
                            <Tooltip title={`Apartment ${data.cleaningGroupApartment.apartment.name} has storage ${data.cleaningGroupApartment.apartment.storage.name}`}>
                                <span className="overriden">
                                    {data.cleaningGroupApartment.storage.name}
                                </span>
                            </Tooltip>
                        )}
                        {data.cleaningGroupApartment.storage.id === data.cleaningGroupApartment.apartment.storage.id && (
                            <span>
                                {data.cleaningGroupApartment.storage.name}
                            </span>
                        )}
                    </div>
                    {data.cleaningGroupApartment.apartment.ongoingReservation && (
                        <div className="cleaning-apartment-ongoing-container">
                            <div className="cleaning-apartment-guests">
                                <div className="cleaning-apartment-guests-icon">
                                    <UserOutlined />
                                </div>
                                <div className="cleaning-apartment-guests-container">
                                    <div className="cleaning-apartment-guests-ongoing">
                                        {data.cleaningGroupApartment.numberOfGuests !== data.cleaningGroupApartment.apartment.ongoingReservation.numberOfGuests && (
                                            <Tooltip title={`This cleaning is planned for ${data.cleaningGroupApartment.numberOfGuests} guests but reservation has ${data.cleaningGroupApartment.apartment.ongoingReservation.numberOfGuests} guests`}>
                                                <span className="difference">
                                                    {data.cleaningGroupApartment.apartment.ongoingReservation.numberOfGuests}
                                                </span>
                                            </Tooltip>
                                        )}
                                        {data.cleaningGroupApartment.numberOfGuests === data.cleaningGroupApartment.apartment.ongoingReservation.numberOfGuests && (
                                            <span>
                                                {data.cleaningGroupApartment.numberOfGuests}
                                            </span>
                                        )}
                                    </div>
                                    <div className="cleaning-apartment-divider" />
                                    <div className="cleaning-apartment-guests-arriving" />
                                </div>
                            </div>
                            <div className="cleaning-apartment-ongoing-reservation">
                                <Popover
                                    content={
                                        <ReservationInformation
                                            reservationId={data.cleaningGroupApartment.apartment.ongoingReservation.id}
                                        />
                                    }
                                    trigger="click"
                                    placement="left"
                                >
                                    <span className="cleaning-apartment-ongoing-label">
                                        ongoing reservation
                                    </span>
                                </Popover>
                            </div>
                        </div>
                    )}
                    {!data.cleaningGroupApartment.apartment.ongoingReservation && (
                        <div className="cleaning-apartment-no-ongoing-container">
                            <div className="cleaning-apartment-guests">
                                <div className="cleaning-apartment-guests-icon">
                                    <UserOutlined />
                                </div>
                                <div className="cleaning-apartment-guests-container">
                                    <div className="cleaning-apartment-guests-departing">
                                        {!data.cleaningGroupApartment.apartment.previousReservation && (
                                            <QuestionCircleOutlined />
                                        )}
                                        {data.cleaningGroupApartment.apartment.previousReservation && !data.cleaningGroupApartment.apartment.departingReservation && (
                                            <span>
                                                {data.cleaningGroupApartment.apartment.previousReservation.numberOfGuests}
                                            </span>
                                        )}
                                        {data.cleaningGroupApartment.apartment.departingReservation && (
                                            <span>
                                                {data.cleaningGroupApartment.apartment.departingReservation.numberOfGuests}
                                            </span>
                                        )}
                                    </div>
                                    <div className="cleaning-apartment-divider">
                                        →
                                    </div>
                                    <div className="cleaning-apartment-guests-arriving">
                                        {!data.cleaningGroupApartment.apartment.nextReservation && (
                                            <Tooltip title={`There is no arriving reservation. This cleaning is planned for ${data.cleaningGroupApartment.numberOfGuests} guests`}>
                                                <MinusCircleOutlined />
                                            </Tooltip>
                                        )}
                                        {data.cleaningGroupApartment.apartment.nextReservation && !data.cleaningGroupApartment.apartment.arrivingReservation && (
                                            <>
                                                {data.cleaningGroupApartment.numberOfGuests === data.cleaningGroupApartment.apartment.nextReservation.numberOfGuests && (
                                                    <span>
                                                        {data.cleaningGroupApartment.numberOfGuests}
                                                    </span>
                                                )}
                                                {data.cleaningGroupApartment.numberOfGuests !== data.cleaningGroupApartment.apartment.nextReservation.numberOfGuests && data.cleaningGroupApartment.numberOfGuestsOverridenAt && isAfter(data.cleaningGroupApartment.numberOfGuestsOverridenAt, data.cleaningGroupApartment.apartment.nextReservation.bookedAt) && (
                                                    <Tooltip title={`This cleaning is planned for ${data.cleaningGroupApartment.numberOfGuests} guests but arriving reservation has ${data.cleaningGroupApartment.apartment.nextReservation.numberOfGuests} guests`}>
                                                        <span className="overriden">
                                                            {data.cleaningGroupApartment.numberOfGuests}
                                                        </span>
                                                    </Tooltip>
                                                )}
                                                {data.cleaningGroupApartment.numberOfGuests !== data.cleaningGroupApartment.apartment.nextReservation.numberOfGuests && !(data.cleaningGroupApartment.numberOfGuestsOverridenAt && isAfter(data.cleaningGroupApartment.numberOfGuestsOverridenAt, data.cleaningGroupApartment.apartment.nextReservation.bookedAt)) && (
                                                    <Tooltip title={`This cleaning is planned for ${data.cleaningGroupApartment.numberOfGuests} guests but arriving reservation has ${data.cleaningGroupApartment.apartment.nextReservation.numberOfGuests} guests`}>
                                                        <span className="difference">
                                                            {data.cleaningGroupApartment.apartment.nextReservation.numberOfGuests}
                                                        </span>
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                        {data.cleaningGroupApartment.apartment.arrivingReservation && (
                                            <>
                                                {data.cleaningGroupApartment.numberOfGuests === data.cleaningGroupApartment.apartment.arrivingReservation.numberOfGuests && (
                                                    <span>
                                                        {data.cleaningGroupApartment.numberOfGuests}
                                                    </span>
                                                )}
                                                {data.cleaningGroupApartment.numberOfGuests !== data.cleaningGroupApartment.apartment.arrivingReservation.numberOfGuests && data.cleaningGroupApartment.numberOfGuestsOverridenAt && isAfter(data.cleaningGroupApartment.numberOfGuestsOverridenAt, data.cleaningGroupApartment.apartment.arrivingReservation.bookedAt) && (
                                                    <Tooltip title={`This cleaning is planned for ${data.cleaningGroupApartment.numberOfGuests} guests but arriving reservation has ${data.cleaningGroupApartment.apartment.arrivingReservation.numberOfGuests} guests`}>
                                                        <span className="overriden">
                                                            {data.cleaningGroupApartment.numberOfGuests}
                                                        </span>
                                                    </Tooltip>
                                                )}
                                                {data.cleaningGroupApartment.numberOfGuests !== data.cleaningGroupApartment.apartment.arrivingReservation.numberOfGuests && !(data.cleaningGroupApartment.numberOfGuestsOverridenAt && isAfter(data.cleaningGroupApartment.numberOfGuestsOverridenAt, data.cleaningGroupApartment.apartment.arrivingReservation.bookedAt)) && (
                                                    <Tooltip title={`This cleaning is planned for ${data.cleaningGroupApartment.numberOfGuests} guests but arriving reservation has ${data.cleaningGroupApartment.apartment.arrivingReservation.numberOfGuests} guests`}>
                                                        <span className="difference">
                                                            {data.cleaningGroupApartment.apartment.arrivingReservation.numberOfGuests}
                                                        </span>
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="cleaning-apartment-time">
                                <div className="cleaning-apartment-time-icon">
                                    <ClockCircleOutlined />
                                </div>
                                <div className="cleaning-apartment-time-container">
                                    <div className="cleaning-apartment-checkout-time">
                                        {!data.cleaningGroupApartment.apartment.previousReservation && (
                                            <>
                                                {!data.cleaningGroupApartment.checkoutTime && (
                                                    <span className="cleaning-apartment-no-reservation">
                                                        vacant
                                                    </span>
                                                )}
                                                {data.cleaningGroupApartment.checkoutTime && data.cleaningGroupApartment.checkoutTimeOverridenAt && (
                                                    <Tooltip title={`This cleaning is planned for check-out at ${data.cleaningGroupApartment.checkoutTime} but there is no reservation before this date`}>
                                                        <span className="cleaning-apartment-no-reservation overriden">
                                                            {data.cleaningGroupApartment.checkoutTime}
                                                        </span>
                                                    </Tooltip>
                                                )}
                                                {data.cleaningGroupApartment.checkoutTime && !data.cleaningGroupApartment.checkoutTimeOverridenAt && (
                                                    <Tooltip title={`This cleaning is planned for check-out at ${data.cleaningGroupApartment.checkoutTime} but there is no reservation before this date`}>
                                                        <span className="cleaning-apartment-no-reservation difference">
                                                            vacant
                                                        </span>
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                        {data.cleaningGroupApartment.apartment.previousReservation && !data.cleaningGroupApartment.apartment.departingReservation && (
                                            <Popover
                                                content={
                                                    <NoDepartureInformation
                                                        apartmentId={data.cleaningGroupApartment.apartment.id}
                                                        date={date}
                                                    />
                                                }
                                                trigger="click"
                                                placement="left"
                                            >
                                                {!data.cleaningGroupApartment.checkoutTime && (
                                                    <div className="cleaning-apartment-no-departure">
                                                        vacant
                                                    </div>
                                                )}
                                                {data.cleaningGroupApartment.checkoutTime && data.cleaningGroupApartment.checkoutTimeOverridenAt && (
                                                    <Tooltip title={`This cleaning is planned for check-out at ${data.cleaningGroupApartment.checkoutTime} but there is no reservation departing on this date`}>
                                                        <div className="cleaning-apartment-no-departure overriden">
                                                            {data.cleaningGroupApartment.checkoutTime}
                                                        </div>
                                                    </Tooltip>
                                                )}
                                                {data.cleaningGroupApartment.checkoutTime && !data.cleaningGroupApartment.checkoutTimeOverridenAt && (
                                                    <Tooltip title={`This cleaning is planned for check-out at ${data.cleaningGroupApartment.checkoutTime} but there is no reservation departing on this date`}>
                                                        <div className="cleaning-apartment-no-departure difference">
                                                            vacant
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </Popover>
                                        )}
                                        {data.cleaningGroupApartment.apartment.departingReservation && (
                                            <>
                                                {data.cleaningGroupApartment.checkoutTime === data.cleaningGroupApartment.apartment.departingReservation.checkoutTime && (
                                                    <CheckoutTime
                                                        checkoutTime={data.cleaningGroupApartment.checkoutTime}
                                                    />
                                                )}
                                                {data.cleaningGroupApartment.checkoutTime !== data.cleaningGroupApartment.apartment.departingReservation.checkoutTime && data.cleaningGroupApartment.checkoutTimeOverridenAt && isAfter(data.cleaningGroupApartment.checkoutTimeOverridenAt, data.cleaningGroupApartment.apartment.departingReservation.bookedAt) && (
                                                    <Tooltip title={`This cleaning is planned for check-out at ${data.cleaningGroupApartment.checkoutTime || 'unknown time'} but reservation has check-out at ${data.cleaningGroupApartment.apartment.departingReservation.checkoutTime || 'unknown time'}`}>
                                                        <CheckoutTime
                                                            checkoutTime={data.cleaningGroupApartment.checkoutTime}
                                                            overriden
                                                        />
                                                    </Tooltip>
                                                )}
                                                {data.cleaningGroupApartment.checkoutTime !== data.cleaningGroupApartment.apartment.departingReservation.checkoutTime && !(data.cleaningGroupApartment.checkoutTimeOverridenAt && isAfter(data.cleaningGroupApartment.checkoutTimeOverridenAt, data.cleaningGroupApartment.apartment.departingReservation.bookedAt)) && (
                                                    <Tooltip title={`This cleaning is planned for check-out at ${data.cleaningGroupApartment.checkoutTime || 'unknown time'} but reservation has check-out at ${data.cleaningGroupApartment.apartment.departingReservation.checkoutTime || 'unknown time'}`}>
                                                        <CheckoutTime
                                                            checkoutTime={data.cleaningGroupApartment.apartment.departingReservation.checkoutTime}
                                                            difference
                                                        />
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div className="cleaning-apartment-divider">
                                        →
                                    </div>
                                    <div className="cleaning-apartment-checkin-time">
                                        {!data.cleaningGroupApartment.apartment.nextReservation && (
                                            <>
                                                {!data.cleaningGroupApartment.checkinTime && (
                                                    <span className="cleaning-apartment-no-reservation">
                                                        vacant
                                                    </span>
                                                )}
                                                {data.cleaningGroupApartment.checkinTime && data.cleaningGroupApartment.checkinTimeOverridenAt && (
                                                    <Tooltip title={`This cleaning is planned for check-in at ${data.cleaningGroupApartment.checkinTime} but there is no reservation after this date`}>
                                                        <span className="cleaning-apartment-no-reservation overriden">
                                                            {data.cleaningGroupApartment.checkinTime}
                                                        </span>
                                                    </Tooltip>
                                                )}
                                                {data.cleaningGroupApartment.checkinTime && !data.cleaningGroupApartment.checkinTimeOverridenAt && (
                                                    <Tooltip title={`This cleaning is planned for check-in at ${data.cleaningGroupApartment.checkinTime} but there is no reservation after this date`}>
                                                        <span className="cleaning-apartment-no-reservation difference">
                                                            vacant
                                                        </span>
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                        {data.cleaningGroupApartment.apartment.nextReservation && !data.cleaningGroupApartment.apartment.arrivingReservation && (
                                            <Popover
                                                content={
                                                    <NoArrivalInformation
                                                        apartmentId={data.cleaningGroupApartment.apartment.id}
                                                        date={date}
                                                    />
                                                }
                                                trigger="click"
                                                placement="left"
                                            >
                                                {!data.cleaningGroupApartment.checkinTime && (
                                                    <div className="cleaning-apartment-no-arrival">
                                                        vacant
                                                    </div>
                                                )}
                                                {data.cleaningGroupApartment.checkinTime && data.cleaningGroupApartment.checkinTimeOverridenAt && (
                                                    <Tooltip title={`This cleaning is planned for check-in at ${data.cleaningGroupApartment.checkinTime} but there is no reservation arriving on this date`}>
                                                        <div className="cleaning-apartment-no-arrival overriden">
                                                            {data.cleaningGroupApartment.checkinTime}
                                                        </div>
                                                    </Tooltip>
                                                )}
                                                {data.cleaningGroupApartment.checkinTime && !data.cleaningGroupApartment.checkinTimeOverridenAt && (
                                                    <Tooltip title={`This cleaning is planned for check-in at ${data.cleaningGroupApartment.checkinTime} but there is no reservation arriving on this date`}>
                                                        <div className="cleaning-apartment-no-arrival difference">
                                                            vacant
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </Popover>
                                        )}
                                        {data.cleaningGroupApartment.apartment.arrivingReservation && (
                                            <>
                                                {data.cleaningGroupApartment.checkinTime === data.cleaningGroupApartment.apartment.arrivingReservation.checkinTime && (
                                                    <CheckinTime
                                                        checkinTime={data.cleaningGroupApartment.checkinTime}
                                                    />
                                                )}
                                                {data.cleaningGroupApartment.checkinTime !== data.cleaningGroupApartment.apartment.arrivingReservation.checkinTime && data.cleaningGroupApartment.checkinTimeOverridenAt && isAfter(data.cleaningGroupApartment.checkinTimeOverridenAt, data.cleaningGroupApartment.apartment.arrivingReservation.bookedAt) && (
                                                    <Tooltip title={`This cleaning is planned for check-in at ${data.cleaningGroupApartment.checkinTime || 'unknown time'} but reservation has check-in at ${data.cleaningGroupApartment.apartment.arrivingReservation.checkinTime || 'unknown time'}`}>
                                                        <CheckinTime
                                                            checkinTime={data.cleaningGroupApartment.checkinTime}
                                                            overriden
                                                        />
                                                    </Tooltip>
                                                )}
                                                {data.cleaningGroupApartment.checkinTime !== data.cleaningGroupApartment.apartment.arrivingReservation.checkinTime && !(data.cleaningGroupApartment.checkinTimeOverridenAt && isAfter(data.cleaningGroupApartment.checkinTimeOverridenAt, data.cleaningGroupApartment.apartment.arrivingReservation.bookedAt)) && (
                                                    <Tooltip title={`This cleaning is planned for check-in at ${data.cleaningGroupApartment.checkinTime || 'unknown time'} but reservation has check-in at ${data.cleaningGroupApartment.apartment.arrivingReservation.checkinTime || 'unknown time'}`}>
                                                        <CheckinTime
                                                            checkinTime={data.cleaningGroupApartment.apartment.arrivingReservation.checkinTime}
                                                            difference
                                                        />
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="cleaning-apartment-jobs">
                        {data.cleaningGroupApartment.apartment.jobsNew.length > 0 && (
                            <span
                                className="cleaning-apartment-jobs-link"
                                onClick={() => setJobsModalOpen(true)}
                            >
                                {data.cleaningGroupApartment.apartment.jobsNew.length} {data.cleaningGroupApartment.apartment.jobsNew.length === 1 ? 'job' : 'jobs'}
                            </span>
                        )}
                    </div>
                    <div className="cleaning-apartment-actions">
                        <ContextMenu
                            fixDisabled={!cleaningToFix}
                            onClick={action => handleAction(action)}
                            trigger={["click"]}
                        >
                            <EllipsisOutlined />
                        </ContextMenu>
                    </div>
                    <Modal
                        open={editModalOpen}
                        title="Update apartment cleaning"
                        onOk={() => handleSave()}
                        okButtonProps={{
                            loading: updateCleaningGroupApartmentLoading,
                        }}
                        onCancel={() => setEditModalOpen(false)}
                    >
                        <ApartmentCleaningForm
                            date={date}
                            form={form}
                            apartmentCleaning={data.cleaningGroupApartment}
                            disableApartment
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                        />
                    </Modal>
                    <Modal
                        open={jobsModalOpen}
                        title="Jobs"
                        closable={true}
                        onCancel={() => setJobsModalOpen(false)}
                        footer={false}
                        width={800}
                    >
                        <JobsList
                            apartmentId={data.cleaningGroupApartment.apartment.id}
                            date={date}
                        />
                    </Modal>
                </div >
            </ContextMenu>
        </div>
    );
}