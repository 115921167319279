import { green, red } from "@ant-design/colors";
import { CheckCircleFilled, ExclamationCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { Alert, Col, Dropdown, Form, message, Modal, Row, Typography } from "antd";
import { airbnbFromBackend, airbnbToBackend } from "apartments/common/airbnb";
import { bookingFromBackend, bookingToBackend } from "apartments/common/booking";
import AirbnbSettingsForm from "apartments/forms/AirbnbSettingsForm";
import ApartmentMappingForm from "apartments/forms/ApartmentMappingForm";
import BookingSettingsForm from "apartments/forms/BookingSettingsForm";
import { cache } from "apollo";
import { taskPromise } from "common/task";
import { useState } from "react";

const FORM_LAYOUT = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

const MUTATION_UPDATE_CHANNEL = gql`
    mutation UpdateApartmentChannel($input: UpdateApartmentChannelInput!) {
        updateApartmentChannel(input: $input) {
            error {
                type
                message
            }
            apartmentChannel {
                id
                otaName
                active
                mapping
                otaSettings
                status
                statusMessage
                createdAt
            }
            syncApartmentChannelUpTask {
                id
            }
        }
    }
`;

const MUTATION_DISCONNECT = gql`
    mutation DisconnectApartmentChannel($input: DisconnectApartmentChannelInput!) {
        disconnectApartmentChannel(input: $input) {
            error {
                type
                message
            }
            apartmentChannel {
                id
                active
                mapping
                otaSettings
                status
                statusMessage
            }
            syncApartmentChannelUpTask {
                id
            }
        }
    }
`;

const MUTATION_CONNECT = gql`
    mutation ConnectApartmentChannel($input: ConnectApartmentChannelInput!) {
        connectApartmentChannel(input: $input) {
            error {
                type
                message
            }
            apartmentChannel {
                id
                active
                mapping
                otaSettings
                status
                statusMessage
            }
            syncApartmentChannelUpTask {
                id
            }
        }
    }
`;

const MUTATION_DELETE = gql`
    mutation DeleteApartmentChannel($input: DeleteApartmentChannelInput!) {
        deleteApartmentChannel(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

export default function ApartmentChannelOnboardingItem(props) {
    const {
        channel,
        apartment,
    } = props;

    const [mappingModalOpen, setMappingModalOpen] = useState(false);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [disconnectModalOpen, setDisconnectModalOpen] = useState(false);
    const [connectModalOpen, setConnectModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [mappingLoading, setMappingLoading] = useState(false);
    const [settingsLoading, setSettingsLoading] = useState(false);
    const [disconnectLoading, setDisconnectLoading] = useState(false);
    const [connectLoading, setConnectLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const [updateApartmentChannel] = useMutation(MUTATION_UPDATE_CHANNEL);
    const [disconnectApartmentChannel] = useMutation(MUTATION_DISCONNECT);
    const [connectApartmentChannel] = useMutation(MUTATION_CONNECT);
    const [deleteApartmentChannel] = useMutation(MUTATION_DELETE);

    const [mappingForm] = Form.useForm();
    const [settingsForm] = Form.useForm();

    function handleAction(action) {
        if (action === 'fix') {
            setMappingModalOpen(true);
        }
        if (action === 'settings') {
            setSettingsModalOpen(true);
        }
        if (action === 'disconnect') {
            setDisconnectModalOpen(true);
        }
        if (action === 'connect') {
            setConnectModalOpen(true);
        }
        if (action === 'delete') {
            setDeleteModalOpen(true);
        }
    }

    async function handleUpdateChannelMapping() {
        try {
            const values = await mappingForm.validateFields();
            setMappingLoading(true);

            const updateApartmentChannelResponse = await updateApartmentChannel({
                variables: {
                    input: {
                        apartmentChannelId: channel.id,
                        mapping: values.mapping,
                    },
                },
            });

            const updateApartmentChannelResult = await taskPromise(updateApartmentChannelResponse?.data?.updateApartmentChannel?.syncApartmentChannelUpTask?.id);

            cache.evict({
                id: cache.identify({
                    __typename: 'ApartmentChannel',
                    id: channel.id,
                }),
                fieldName: 'status',
            });

            cache.evict({
                id: cache.identify({
                    __typename: 'ApartmentChannel',
                    id: channel.id,
                }),
                fieldName: 'statusMessage',
            });

            if (updateApartmentChannelResult.status !== 'success') {
                message.error('Failed to connect');
                setMappingLoading(false);
                return;
            }

            message.success("Connected");
            setMappingLoading(false);
            setMappingModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setMappingLoading(false);
        }
    }

    async function handleUpdateChannelSettings() {
        try {
            const values = await settingsForm.validateFields();
            setSettingsLoading(true);

            let otaSettings = {};
            if (channel.otaName === 'AirBNB') {
                otaSettings = airbnbToBackend(values);
            }
            else if (channel.otaName === 'BookingCom') {
                otaSettings = bookingToBackend(values);
            }

            const updateApartmentChannelResponse = await updateApartmentChannel({
                variables: {
                    input: {
                        apartmentChannelId: channel.id,
                        otaSettings,
                    },
                },
            });

            const updateApartmentChannelResult = await taskPromise(updateApartmentChannelResponse?.data?.updateApartmentChannel?.syncApartmentChannelUpTask?.id);

            cache.evict({
                id: cache.identify({
                    __typename: 'ApartmentChannel',
                    id: channel.id,
                }),
                fieldName: 'status',
            });

            cache.evict({
                id: cache.identify({
                    __typename: 'ApartmentChannel',
                    id: channel.id,
                }),
                fieldName: 'statusMessage',
            });

            if (updateApartmentChannelResult.status !== 'success') {
                message.error('Failed to update settings');
                setSettingsLoading(false);
                return;
            }

            message.success("Channel updated");
            setSettingsLoading(false);
            setSettingsModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setSettingsLoading(false);
        }
    }

    async function handleDisconnect() {
        try {
            setDisconnectLoading(true);
            const response = await disconnectApartmentChannel({
                variables: {
                    input: {
                        apartmentChannelId: channel.id,
                    },
                },
            });

            if (response?.data?.disconnectApartmentChannel?.error) {
                message.error("Failed to disconnect");
                setDisconnectLoading(false);
                return;
            }

            await taskPromise(response?.data?.disconnectApartmentChannel?.syncApartmentChannelUpTask?.id);

            message.success("Disconnected");
            setDisconnectLoading(false);
            setDisconnectModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            else {
                message.error('Failed to disconnect');
            }
            setDisconnectLoading(false);
        }
    }

    async function handleConnect() {
        try {
            setConnectLoading(true);
            const response = await connectApartmentChannel({
                variables: {
                    input: {
                        apartmentChannelId: channel.id,
                    },
                },
            });

            if (response?.data?.connectApartmentChannel?.error) {
                message.error("Failed to connect");
                setConnectLoading(false);
                return;
            }

            await taskPromise(response?.data?.connectApartmentChannel?.syncApartmentChannelUpTask?.id);

            message.success("Connected");
            setConnectLoading(false);
            setConnectModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            else {
                message.error('Failed to connect');
            }
            setConnectLoading(false);
        }
    }

    async function handleDelete() {
        try {
            setDeleteLoading(true);
            const response = await deleteApartmentChannel({
                variables: {
                    input: {
                        apartmentChannelId: channel.id,
                    },
                },
            });

            if (response?.data?.deleteApartmentChannel?.error) {
                message.error("Failed to delete");
                setDeleteLoading(false);
                return;
            }

            cache.evict({
                id: cache.identify({
                    __typename: 'ApartmentChannel',
                    id: channel.id,
                }),
            });

            message.success("Deleted");
            setDeleteLoading(false);
            setDeleteModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            else {
                message.error('Failed to delete');
            }
            setDeleteLoading(false);
        }
    }

    function channelIdentifier() {
        if (channel.otaName === 'AirBNB') {
            return `Airbnb.com (${channel.mapping.listing_id})`;
        }
        if (channel.otaName === 'BookingCom') {
            return `Booking.com (${channel.mapping.hotel_id})`;
        }
    }

    const dropdownOptions = [];
    if (channel.status === 'success' || channel.status === 'settings_error') {
        dropdownOptions.push({
            label: 'Settings',
            key: 'settings',
        });
    }
    else {
        dropdownOptions.push({
            label: 'Fix',
            key: 'fix',
        });
    }
    if (channel.active) {
        dropdownOptions.push({
            label: 'Disconnect',
            key: 'disconnect',
        });
    }
    else {
        dropdownOptions.push({
            label: 'Connect',
            key: 'connect',
        });
        dropdownOptions.push({
            label: 'Delete',
            key: 'delete',
            danger: true,
        });
    }

    return (
        <Row gutter={[16, 16]}>
            <Col flex={0}>
                {channel.status === 'success' && (
                    <CheckCircleFilled
                        style={{
                            color: green.primary,
                        }}
                    />
                )}
                {channel.status !== 'success' && (
                    <ExclamationCircleOutlined
                        style={{
                            color: red.primary,
                        }}
                    />
                )}
            </Col>
            <Col flex={1}>
                <Typography.Text
                    type={!channel.active && 'secondary'}
                >
                    Channel {channelIdentifier()}
                </Typography.Text>
            </Col>
            <Col>
                <Dropdown
                    menu={{
                        items: dropdownOptions,
                        onClick: ({ key }) => handleAction(key),
                    }}
                    trigger={["click"]}
                    placement="bottom"
                    arrow
                >
                    <Typography.Link>
                        Options
                    </Typography.Link>
                </Dropdown>
            </Col>
            <Modal
                open={mappingModalOpen}
                title="Fix mapping"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: mappingLoading,
                }}
                onOk={() => handleUpdateChannelMapping()}
                onCancel={() => setMappingModalOpen(false)}
                destroyOnClose
                width={700}
            >
                <Row gutter={[16, 16]}>
                    {channel.statusMessage && (
                        <Col span={24}>
                            <Alert
                                type="error"
                                showIcon
                                message={channel.statusMessage}
                            />
                        </Col>
                    )}
                    <Col span={24}>
                        <ApartmentMappingForm
                            form={mappingForm}
                            preserve={false}
                            initialValues={{
                                otaName: channel.otaName,
                                mapping: channel.mapping,
                            }}
                            {...FORM_LAYOUT}
                        />
                    </Col>
                </Row>
            </Modal>
            {channel.otaName === 'AirBNB' && (
                <Modal
                    open={settingsModalOpen}
                    title={`Setup channel - ${channelIdentifier()}`}
                    okText="Save"
                    okButtonProps={{
                        icon: <SaveOutlined />,
                        loading: settingsLoading,
                    }}
                    onOk={() => handleUpdateChannelSettings()}
                    onCancel={() => setSettingsModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    <Row gutter={[16, 16]}>
                        {channel.statusMessage && (
                            <Col span={24}>
                                <Alert
                                    type="error"
                                    showIcon
                                    message={channel.statusMessage}
                                />
                            </Col>
                        )}
                        <Col span={24}>
                    <AirbnbSettingsForm
                        apartment={apartment}
                        form={settingsForm}
                        initialValues={airbnbFromBackend(channel?.otaSettings)}
                        preserve={false}
                        {...FORM_LAYOUT}
                    />
                        </Col>
                    </Row>
                </Modal>
            )}
            {channel.otaName === 'BookingCom' && (
                <Modal
                    open={settingsModalOpen}
                    title={`Setup channel - ${channelIdentifier()}`}
                    okText="Save"
                    okButtonProps={{
                        icon: <SaveOutlined />,
                        loading: settingsLoading,
                    }}
                    onOk={() => handleUpdateChannelSettings()}
                    onCancel={() => setSettingsModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    <Row gutter={[16, 16]}>
                        {channel.statusMessage && (
                            <Col span={24}>
                                <Alert
                                    type="error"
                                    showIcon
                                    message={channel.statusMessage}
                                />
                            </Col>
                        )}
                        <Col span={24}>
                    <BookingSettingsForm
                        apartment={apartment}
                        form={settingsForm}
                        initialValues={bookingFromBackend(channel?.otaSettings)}
                        preserve={false}
                        {...FORM_LAYOUT}
                    />
                        </Col>
                    </Row>
                </Modal>
            )}
            <Modal
                open={disconnectModalOpen}
                title={`Disconnect channel - ${channelIdentifier()}`}
                okText="Disconnect"
                okButtonProps={{
                    loading: disconnectLoading,
                }}
                onOk={() => handleDisconnect()}
                onCancel={() => setDisconnectModalOpen(false)}
                destroyOnClose
            />
            <Modal
                open={connectModalOpen}
                title={`Connect channel - ${channelIdentifier()}`}
                okText="Connect"
                okButtonProps={{
                    loading: connectLoading,
                }}
                onOk={() => handleConnect()}
                onCancel={() => setConnectModalOpen(false)}
                destroyOnClose
            />
            <Modal
                open={deleteModalOpen}
                title={`Delete channel - ${channelIdentifier()}`}
                okText="Delete"
                okButtonProps={{
                    loading: deleteLoading,
                    danger: true,
                }}
                onOk={() => handleDelete()}
                onCancel={() => setDeleteModalOpen(false)}
                destroyOnClose
            />
        </Row>
    );
}