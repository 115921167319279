import { Col, InputNumber, Row, Select } from "antd";

export default function AirbnbFeeInput(props) {
    const {
        value,
        onChange,
        currency,
    } = props;

    function getUnits(type) {
        const units = [
            {
                label: `${currency} per reservation`,
                value: 'perReservation',
            },
        ];

        if (['petFee'].includes(type)) {
            units.push({
                label: `${currency} per pet`,
                value: 'perPet',
            });
        }

        if (['managementFee', 'communityFee', 'linenFee', 'resortFee'].includes(type)) {
            units.push({
                label: `${currency} per guest`,
                value: 'perGuest',
            });
        }

        if (['petFee', 'managementFee', 'communityFee', 'resortFee'].includes(type)) {
            units.push({
                label: `${currency} per night`,
                value: 'perNight',
            });
        }

        if (['petFee'].includes(type)) {
            units.push({
                label: `${currency} per pet per night`,
                value: 'perPetPerNight',
            });
        }

        if (['managementFee', 'communityFee', 'resortFee'].includes(type)) {
            units.push({
                label: `${currency} per guest per night`,
                value: 'perGuestPerNight',
            });
        }

        if (['managementFee', 'communityFee', 'resortFee'].includes(type)) {
            units.push({
                label: '% of reservation price',
                value: 'percentOfReservationPrice',
            });
        }

        return units;
    }

    return (
        <Row
            gutter={[16, 16]}
            justify="space-between"
            align="middle"
            wrap={false}
        >
            <Col flex={0}>
                <Select
                    options={[
                        {
                            label: 'Cleaning fee',
                            value: 'cleaningFee',
                        },
                        {
                            label: 'Short-term cleaning fee',
                            value: 'shortTermCleaningFee',
                        },
                        {
                            label: 'Pet fee',
                            value: 'petFee',
                        },
                        {
                            label: 'Management fee',
                            value: 'managementFee',
                        },
                        {
                            label: 'Community fee',
                            value: 'communityFee',
                        },
                        {
                            label: 'Linen fee',
                            value: 'linenFee',
                        },
                        {
                            label: 'Resort fee',
                            value: 'resortFee',
                        },
                    ]}
                    value={value?.type}
                    onChange={type => onChange({ ...value ?? {}, type, unit: 'perReservation' })}
                    placeholder="Type"
                    style={{
                        width: '200px',
                    }}
                />
            </Col>
            {value?.type && (
                <>
                    <Col flex={1}>
                        <InputNumber
                            value={value?.amount}
                            onChange={amount => onChange({ ...value ?? {}, amount })}
                            placeholder="Amount"
                            style={{
                                width: '100%',
                                textAlignLast: 'right',
                            }}
                            controls={false}
                            min={0}
                            precision={0}
                        />
                    </Col>
                    <Col flex={0}>
                        <Select
                            options={getUnits(value?.type)}
                            value={value?.unit}
                            onChange={unit => onChange({ ...value ?? {}, unit })}
                            style={{
                                width: '250px',
                            }}
                        />
                    </Col>
                </>
            )}
        </Row>
    );
}