import { Form, InputNumber } from "antd";
import MinimumStayInput from "apartments/components/minimum-stay-input/MinimumStayInput";
import { useAuth } from "auth";
import InputDefaultWrapper from "components/InputDefaultWrapper";
import RadioGroupInput from "components/radio-group-input/RadioGroupInput";
import TimeSelect from "components/TimeSelect";

export default function ApartmentAvailabilitySettingsForm(props) {
    const {
        form,
        ...otherProps
    } = props;

    const { user } = useAuth();

    return (
        <Form
            form={form}
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 18,
            }}
            {...otherProps}
        >
            <Form.Item
                name="unitSize"
                label="Unit size"
                rules={[
                    { required: true, message: 'Unit size is required' },
                    {
                        validator(_, unitSize) {
                            if (unitSize.choice === 'multiple') {
                                if (unitSize.value === null) {
                                    return Promise.reject(new Error('Number of units is required'));
                                }
                            }
                            return Promise.resolve();
                        }
                    },
                ]}
            >
                <RadioGroupInput
                    options={[
                        {
                            key: 'single',
                            label: 'Single',
                            description: 'This apartment is sold as single unit',
                        },
                        {
                            key: 'multiple',
                            label: 'Multiple',
                            description: 'This apartment is sold as multiple units',
                            input: (
                                <InputNumber
                                    min={2}
                                    addonAfter="units"
                                />
                            ),
                        },
                    ]}
                    defaultOption="single"
                />
            </Form.Item>
            <Form.Item
                name="cutOffTime"
                label="Cut-off time"
                rules={[{ required: true, message: 'Cut-off time is required' }]}
            >
                <InputDefaultWrapper
                    defaultWrapperValue={user.organization.defaultApartmentCutOffTime}
                    description="From organization defaults"
                >
                    <TimeSelect
                        startTime="15:00"
                        endTime="23:30"
                        minutesInterval={30}
                    />
                </InputDefaultWrapper>
            </Form.Item>
            <Form.Item
                name="weekdayMinStay"
                label="Minimum stay"
                rules={[
                    { required: true, message: 'Minimum stay is required' },
                    {
                        validator(_, value) {
                            if (!value.every(minStay => minStay)) {
                                return Promise.reject(new Error('Minimum stay is required'));
                            }
                            return Promise.resolve();
                        }
                    }
                ]}
            >
                <MinimumStayInput />
            </Form.Item>
        </Form>
    );
}