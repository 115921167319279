import { useState } from "react";
import { Alert, Form, Modal, Tooltip, message } from "antd";
import { EllipsisOutlined, MailOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import CleaningGroupForm from "cleaning-new/forms/CleaningGroupForm";
import ApartmentCleaningForm from "cleaning-new/forms/ApartmentCleaningForm";
import CleaningGroupCleaners from "./CleaningGroupCleaners";
import CleaningGroupApartments from "./CleaningGroupApartments";
import ContextMenu from "./ContextMenu";
import "./style.css";
import { CLEANING_GROUP_STATUS_CONFIRMED, CLEANING_GROUP_STATUS_FINISHED, CLEANING_GROUP_STATUS_PLANNED, CLEANING_GROUP_STATUS_STARTED, CLEANING_GROUP_STATUS_SUBMITTED } from "cleaning-new/common";
import classNames from "classnames";
import { formatISO } from "date-fns";

const DEFAULT_CLEANING_START_TIME = "undefined";

const QUERY = gql`
    query GetCleaningGroup($cleaningGroupId: ID!, $date: Date!) {
        cleaningGroup(cleaningGroupId: $cleaningGroupId) {
            id
            status
            startTime
            endTime
            note
            apartments {
                id
                checkinTime
                checkinTimeOverridenAt
                checkoutTime
                checkoutTimeOverridenAt
                numberOfGuests
                numberOfGuestsOverridenAt
                order
                tags
                note
                apartment {
                    id
                    name
                    # increasePriceAbove
                    storage {
                        id
                        name
                        order
                    }
                    previousReservation(date: $date) {
                        id
                        endDate
                        checkoutTime
                        numberOfGuests
                    }
                    departingReservation(date: $date) {
                        id
                        endDate
                        checkoutTime
                        numberOfGuests
                        bookedAt
                    }
                    ongoingReservation(date: $date) {
                        id
                        numberOfGuests
                    }
                    arrivingReservation(date: $date) {
                        id
                        startDate
                        checkinTime
                        numberOfGuests
                        airbnbThreadId
                        bookedAt
                    }
                    nextReservation(date: $date) {
                        id
                        startDate
                        checkinTime
                        numberOfGuests
                        bookedAt
                    }
                    cleanings(filter: {dateLt: $date, order: "desc", limit: 1}) {
                        id
                        group {
                            id
                            date
                        }
                    }
                    jobsNew(filter: {dates: [$date]}) {
                        id
                    }
                }
                storage {
                    id
                    name
                    order
                }
                group {
                    id
                }
            }
            cleaners {
                id
                firstName
                lastName
                availability(filter: {dateFrom: $date, dateTo: $date}) {
                    id
                    availability
                }
                groups {
                    id
                    userAvailabilityTypes {
                        id
                        availability
                        color
                    }
                }
                organization {
                    id
                    userAvailabilityTypes {
                        id
                        availability
                        color
                    }
                }
            }
        }
    }
`;

const CREATE_CLEANING_GROUP_APARTMENTS_MUTATION = gql`
    mutation CreateCleaningGroupApartments($input: CreateCleaningGroupApartmentsInput!) {
        createCleaningGroupApartments(input: $input) {
            error {
                type
                message
            }
            cleaningGroup {
                id
                apartments {
                    id
                }
            }
        }
    }
`;

const UPDATE_CLEANING_GROUP_MUTATION = gql`
    mutation UpdateCleaningGroup($input: UpdateCleaningGroupInput!) {
        updateCleaningGroup(input: $input) {
            error {
                type
                message
            }
            cleaningGroup {
                id
                startTime
                endTime
                note
            }
        }
    }
`;

const UPDATE_CLEANING_GROUP_STATUS_MUTATION = gql`
    mutation UpdateCleaningGroupStatus($input: UpdateCleaningGroupStatusInput!) {
        updateCleaningGroupStatus(input: $input) {
            error {
                type
                message
            }
            cleaningGroup {
                id
                status
            }
        }
    }
`;

const DELETE_CLEANING_GROUP_MUTATION = gql`
    mutation DeleteCleaningGroup($input: DeleteCleaningGroupInput!) {
        deleteCleaningGroup(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

export default function CleaningGroup(props) {
    const {
        cleaningGroupId,
        date,
        selectedCleaningGroupApartmentIds,
        usedCleanerIds,
        moveHereMode,
        size,
        onClick,
        onMoveToGroup,
        onMoveToNewGroup,
    } = props;

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [addApartmentModalOpen, setAddApartmentModalOpen] = useState(false);
    const [editForm] = Form.useForm();
    const [addApartmentForm] = Form.useForm();

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            cleaningGroupId,
            date: formatISO(date, { representation: 'date' }),
        },
    });
    const [createCleaningGroupApartments, { loading: createCleaningGroupApartmentsLoading }] = useMutation(CREATE_CLEANING_GROUP_APARTMENTS_MUTATION, {
        update(cache) {
            cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'apartmentsForCleaning',
            });
        },
    });
    const [updateCleaningGroup] = useMutation(UPDATE_CLEANING_GROUP_MUTATION);
    const [updateCleaningGroupStatus] = useMutation(UPDATE_CLEANING_GROUP_STATUS_MUTATION);
    const [deleteCleaningGroup] = useMutation(DELETE_CLEANING_GROUP_MUTATION, {
        update(cache) {
            cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'cleaningGroups',
            });
            cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'apartmentsForCleaning',
            });
        },
    });

    if (loading) {
        return (
            null
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load cleaning group"
            />
        );
    }

    function handleAction(action) {
        if (action === 'edit') {
            handleEdit();
        }
        if (action === 'addApartment') {
            handleAddApartment();
        }
        if (action === 'submit') {
            handleSubmit();
        }
        if (action === 'withdraw') {
            handleWithdraw();
        }
        if (action === 'confirm') {
            handleConfirm();
        }
        if (action === 'copyAsText') {
            handleCopyAsText();
        }
        if (action === 'delete') {
            handleDelete();
        }
    }

    function handleEdit() {
        setEditModalOpen(true);
    }

    function handleAddApartment() {
        setAddApartmentModalOpen(true);
    }

    function handleSubmit() {
        updateCleaningGroupStatus({
            variables: {
                input: {
                    cleaningGroupId,
                    status: CLEANING_GROUP_STATUS_SUBMITTED,
                },
            },
        });
    }

    function handleWithdraw() {
        updateCleaningGroupStatus({
            variables: {
                input: {
                    cleaningGroupId,
                    status: CLEANING_GROUP_STATUS_PLANNED,
                },
            },
        });
    }

    function handleConfirm() {
        updateCleaningGroupStatus({
            variables: {
                input: {
                    cleaningGroupId,
                    status: 'confirmed',
                },
            },
        });
    }

    function handleCopyAsText() {
        const cleanersText = data.cleaningGroup.cleaners
            .map(cleaner => `${cleaner.firstName} ${cleaner.lastName}`)
            .join(', ');

        const startTimeText = data.cleaningGroup.startTime
            ? `start: ${data.cleaningGroup.startTime}\n`
            : '';

        const noteText = data.cleaningGroup.note
            ? `${data.cleaningGroup.note}\n`
            : '';

        const apartmentsText = [...data.cleaningGroup.apartments ?? []]
            .sort((a, b) => a.order - b.order)
            .map(item => {
                const guestsText = item.apartment.previousReservation
                    ? `${item.apartment.previousReservation.numberOfGuests} os -> ${item.numberOfGuests} os`
                    : `brak rezerwacji -> ${item.numberOfGuests} os`;

                const checkinText = item.apartment.arrivingReservation
                    ? item.checkinTime || 'NW'
                    : 'brak przyjazdu'

                const checkoutText = item.checkoutTime
                    ? `- wymeldowanie ${item.checkoutTime}`
                    : '';

                const noteText = item.note
                    ? `- ${item.note}`
                    : '';

                return `${item.apartment.name} - ${guestsText} - ${checkinText} ${checkoutText} ${noteText}`;
            })
            .join('\n');

        const text = `${cleanersText}\n${startTimeText}${noteText}${apartmentsText}`;

        navigator.clipboard.writeText(text);
    }

    function handleDelete() {
        deleteCleaningGroup({
            variables: {
                input: {
                    cleaningGroupId,
                },
            },
        });
    }

    function handleEditSave() {
        editForm
            .validateFields()
            .then(values => {
                updateCleaningGroup({
                    variables: {
                        input: {
                            cleaningGroupId,
                            startTime: values.startTime ?? null,
                            endTime: values.endTime ?? null,
                            note: values.note,
                        },
                    },
                })
                    .then(response => {
                        if (response.data.updateCleaningGroup.error) {
                            message.error('Failed to update group');
                        }
                        setEditModalOpen(false);
                    });
            });
    }

    function handleAddApartmentSave() {
        addApartmentForm
            .validateFields()
            .then(values => {
                createCleaningGroupApartments({
                    variables: {
                        input: {
                            cleaningGroupId,
                            apartments: [
                                {
                                    apartmentId: values.apartmentId,
                                    storageId: values.storageId,
                                    numberOfGuests: values.numberOfGuests,
                                    checkinTime: values.checkinTime,
                                    checkoutTime: values.checkoutTime,
                                    tags: values.tags,
                                    note: values.note,
                                },
                            ],
                        },
                    },
                })
                    .then(response => {
                        if (response.data.createCleaningGroupApartments.error) {
                            message.error("Failed to add apartment");
                        }
                        setAddApartmentModalOpen(false);
                    })
            })
    }

    const usedStorageIds = [...data.cleaningGroup.apartments ?? []]
        .map(item => item.apartment.storage.id)
        .filter((storageId, index, storageIds) => storageIds.indexOf(storageId) === index);

    const cleanersCount = data.cleaningGroup.cleaners.length;
    const status = data.cleaningGroup.status;
    const submitDisabled = cleanersCount === 0 || status !== CLEANING_GROUP_STATUS_PLANNED;
    const withdrawDisabled = cleanersCount === 0 || status === CLEANING_GROUP_STATUS_PLANNED;
    const confirmDisabled = cleanersCount === 0 || status !== CLEANING_GROUP_STATUS_SUBMITTED;
    const copyAsTextDisabled = status !== CLEANING_GROUP_STATUS_SUBMITTED;

    return (
        <ContextMenu
            trigger={["contextMenu"]}
            submitDisabled={submitDisabled}
            withdrawDisabled={withdrawDisabled}
            confirmDisabled={confirmDisabled}
            copyAsTextDisabled={copyAsTextDisabled}
            onClick={action => handleAction(action)}
        >
            <div className="cleaning-group-container">
                {moveHereMode && (
                    <div
                        className="cleaning-group-move-here-container"
                        onClick={() => onClick()}
                    >
                        <div className="cleaning-group-move-here-text">
                            <span>
                                Move here
                            </span>
                        </div>
                    </div>
                )}
                <div
                    className={classNames({
                        'cleaning-group-status': true,
                        'cleaning-group-status-submitted': status === CLEANING_GROUP_STATUS_SUBMITTED,
                        'cleaning-group-status-confirmed': status === CLEANING_GROUP_STATUS_CONFIRMED || status === CLEANING_GROUP_STATUS_STARTED || status === CLEANING_GROUP_STATUS_FINISHED,
                    })}
                />
                <div className="cleaning-group-container-left">
                    <div className="cleaning-group-actions">
                        <ContextMenu
                            trigger={["click"]}
                            submitDisabled={submitDisabled}
                            withdrawDisabled={withdrawDisabled}
                            confirmDisabled={confirmDisabled}
                            copyAsTextDisabled={copyAsTextDisabled}
                            onClick={action => handleAction(action)}
                        >
                            <EllipsisOutlined />
                        </ContextMenu>
                    </div>
                    <div className="cleaning-group-info">
                        <div className="cleaning-group-info-header">
                            <CleaningGroupCleaners
                                cleaningGroupId={cleaningGroupId}
                                date={date}
                                usedStorageIds={usedStorageIds}
                                usedCleanerIds={usedCleanerIds}
                            />
                            {data.cleaningGroup.note && (
                                <Tooltip title={data.cleaningGroup.note}>
                                    <MailOutlined
                                        onClick={() => setEditModalOpen(true)}
                                    />
                                </Tooltip>
                            )}
                        </div>
                        <div className="cleaning-group-info-item">
                            <span className="cleaning-group-info-item-label">
                                Start time
                            </span>
                            <span
                                className="cleaning-group-info-item-value"
                                onClick={() => setEditModalOpen(true)}
                            >
                                {data.cleaningGroup.startTime ?? DEFAULT_CLEANING_START_TIME}
                            </span>
                        </div>
                        {data.cleaningGroup.endTime && (
                            <div className="cleaning-group-info-item">
                                <span className="cleaning-group-info-item-label">
                                    End time
                                </span>
                                <span
                                    className="cleaning-group-info-item-value"
                                    onClick={() => setEditModalOpen(true)}
                                >
                                    {data.cleaningGroup.endTime}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="cleaning-group-container-right">
                    <CleaningGroupApartments
                        cleaningGroupId={cleaningGroupId}
                        date={date}
                        selectedCleaningGroupApartmentIds={selectedCleaningGroupApartmentIds}
                        size={size}
                        onMoveToGroup={cleaningGroupApartmentId => onMoveToGroup(cleaningGroupApartmentId)}
                        onMoveToNewGroup={cleaningGroupApartmentId => onMoveToNewGroup(cleaningGroupApartmentId)}
                    />
                </div>
                <Modal
                    title="Options"
                    open={editModalOpen}
                    onOk={() => handleEditSave()}
                    onCancel={() => setEditModalOpen(false)}
                >
                    <CleaningGroupForm
                        form={editForm}
                        cleaningGroup={data.cleaningGroup}
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                    />
                </Modal>
                <Modal
                    title="Add apartment"
                    open={addApartmentModalOpen}
                    onOk={() => handleAddApartmentSave()}
                    okButtonProps={{
                        loading: createCleaningGroupApartmentsLoading,
                    }}
                    onCancel={() => setAddApartmentModalOpen(false)}
                    destroyOnClose
                >
                    <ApartmentCleaningForm
                        date={date}
                        form={addApartmentForm}
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        preserve={false}
                    />
                </Modal>
            </div>
        </ContextMenu>
    );
}