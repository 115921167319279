import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, PageHeader } from "antd";
import AutomationsList from "automation/components/automations-list/AutomationsList";
import { Link, useNavigate } from "react-router-dom";

export default function ListAutomationsView() {
    const navigate = useNavigate();

    return (
        <PageHeader
            title="Automations"
            onBack={() => navigate(-1)}
            extra={[
                <Link
                    to="/automations/create"
                    key="create"
                >
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                    >
                        Create automation
                    </Button>
                </Link>
            ]}
        >
            <Card>
                <AutomationsList />
            </Card>
        </PageHeader>
    );
}