import { PlusOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Button, Card, Col, Form, message, PageHeader, Row } from "antd";
import AutomationForm from "automation/forms/AutomationForm";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const FORM_LAYOUT = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

const MUTATION = gql`
    mutation CreateAutomation($input: CreateAutomationInput!) {
        createAutomation(input: $input) {
            error {
                type
                message
            }
            automation {
                id
            }
        }
    }
`;

export default function CreateAutomationView() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [createAutomation] = useMutation(MUTATION);

    const [form] = Form.useForm();

    function handleCreate() {
        form
            .validateFields()
            .then(values => {
                setLoading(true);
                createAutomation({
                    variables: {
                        input: {
                            name: values.name,
                        },
                    },
                })
                    .then(response => {
                        if (response?.data?.createAutomation?.error) {
                            message.error("Failed to create automation");
                        }
                        else {
                            message.success("Automation created");
                            navigate(`/automations/${response?.data?.createAutomation?.automation?.id}/edit`);
                        }
                        setLoading(false);
                    })
                    .catch(() => {
                        message.error("Network error");
                        setLoading(false);
                    });
            });
    }

    return (
        <PageHeader
            title="Create automation"
            onBack={() => navigate(-1)}
        >
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <AutomationForm
                            form={form}
                            {...FORM_LAYOUT}
                        />
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Col>
                                <Button
                                    type="primary"
                                    onClick={() => handleCreate()}
                                    icon={<PlusOutlined />}
                                    loading={loading}
                                >
                                    Create automation
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </PageHeader>
    )
}