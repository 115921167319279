import { Alert, Button, Form, message, Modal, PageHeader, Skeleton, Space } from "antd";
import { SaveOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import NodeEditor from "automation/components/node-editor/NodeEditor";
import { useEdgesState, useNodesState } from "@xyflow/react";
import TriggerAutomationForm from "automation/forms/TriggerAutomationForm";
import { taskPromise } from "common/task";

const QUERY = gql`
    query GetAutomationForEditor($automationId: ID!) {
        automation(automationId: $automationId) {
            id
            name
            currentVersion {
                id
                content
                triggers
            }
        }
    }
`;

const MUTATION_UPDATE = gql`
    mutation UpdateAutomation($input: UpdateAutomationInput!) {
        updateAutomation(input: $input) {
            error {
                type
                message
            }
            automation {
                id
                currentVersion {
                    id
                    content
                }
            }
        }
    }
`;

const MUTATION_TRIGGER = gql`
    mutation TriggerAutomation($input: TriggerAutomationInput!) {
        triggerAutomation(input: $input) {
            error {
                type
                message
            }
            automationResult {
                id
                trigger
                triggerData
                status
                status
                finishedAt
                result
            }
            runAutomationTask {
                id
            }
        }
    }
`;

export default function UpdateAutomationView() {
    const { automationId } = useParams();

    const [triggers, setTriggers] = useState([]);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [instance, setInstance] = useState();

    const { data, loading, error } = useQuery(QUERY, { variables: { automationId } });
    const [updateAutomation, { loading: updateLoading }] = useMutation(MUTATION_UPDATE);
    const [triggerAutomation, { loading: triggerLoading }] = useMutation(MUTATION_TRIGGER);

    const [triggerModalOpen, setTriggerModalOpen] = useState(false);
    const [triggerForm] = Form.useForm();

    useEffect(() => {
        if (data) {
            const currentVersion = data?.automation?.currentVersion;
            setTriggers(currentVersion?.triggers ?? []);
            const state = window.structuredClone(currentVersion?.content?.state ?? {});
            setNodes(state.nodes);
            setEdges(state.edges);
        }
    }, [data, setNodes, setEdges]);

    function handleRun() {
        triggerForm
            .validateFields()
            .then(values => {
                triggerAutomation({
                    variables: {
                        input: {
                            automationId,
                            trigger: values.trigger,
                            triggerData: JSON.parse(values.triggerData),
                            dryRun: !!values.dryRun,
                        },
                    },
                })
                    .then(response => {
                        if (response?.data?.triggerAutomation?.error) {
                            message.error("Failed to run automation");
                        }
                        else {
                            taskPromise(response?.data?.triggerAutomation?.runAutomationTask?.id)
                                .then(result => {
                                    if (result.status === 'error') {
                                        result.errors.forEach(error => {
                                            message.error(error);
                                        });
                                    }
                                    else {
                                        message.success("Automation run successfuly");
                                    }
                                    setTriggerModalOpen(false);
                                })
                                .catch(() => {
                                    message.error("Failed to run automation");
                                });
                        }
                    })
                    .catch(() => {
                        message.error("Network error");
                    });
            });
    }

    function handleSave() {
        const content = {
            triggers,
            nodes: nodes.map(node => ({
                id: node.id,
                type: node.type,
                props: Object.fromEntries(Object.entries(node.data).filter(([_, value]) => typeof value !== 'function')),
            })),
            edges: edges.map(edge => ({
                source: edge.source,
                source_handle: edge.sourceHandle,
                target: edge.target,
                target_handle: edge.targetHandle,
            })),
            state: instance.toObject(),
        };

        updateAutomation({
            variables: {
                input: {
                    automationId,
                    content,
                    triggers,
                },
            },
        })
            .then(response => {
                if (response?.data?.updateAutomation?.error) {
                    message.error("Failed to update automation");
                }
                else {
                    message.success("Automation updated");
                }
            })
    }

    return (
        <PageHeader
            title={data?.automation?.name}
            extra={
                <Space>
                    <Button
                        onClick={() => setTriggerModalOpen(true)}
                        icon={<ThunderboltOutlined />}
                        loading={triggerLoading}
                    >
                        Run
                    </Button>
                    <Button
                        onClick={() => handleSave()}
                        icon={<SaveOutlined />}
                        type="primary"
                        loading={updateLoading}
                    >
                        Save
                    </Button>
                    <Modal
                        title="Run automation"
                        open={triggerModalOpen}
                        onCancel={() => setTriggerModalOpen(false)}
                        onOk={() => handleRun()}
                        okText="Run"
                        okButtonProps={{
                            icon: <ThunderboltOutlined />,
                            loading: triggerLoading,
                        }}
                        width={700}
                        destroyOnClose
                    >
                        <TriggerAutomationForm
                            form={triggerForm}
                            availableTriggers={triggers}
                            preserve={false}
                            labelCol={{
                                span: 6,
                            }}
                            wrapperCol={{
                                span: 18,
                            }}
                        />
                    </Modal>
                </Space>
            }
        >
            {loading && !data && (
                <Skeleton />
            )}
            {!loading && error && (
                <Alert
                    type="error"
                    showIcon
                    message="Failed to load automation"
                />
            )}
            {!loading && data && (
                <NodeEditor
                    nodes={nodes}
                    onSetNodes={setNodes}
                    handleNodesChange={onNodesChange}
                    edges={edges}
                    onSetEdges={setEdges}
                    handleEdgesChange={onEdgesChange}
                    triggers={triggers}
                    onTriggersChange={setTriggers}
                    onInit={setInstance}
                />
            )}
        </PageHeader>
    );
}