import { Form } from "antd";
import ApartmentTagsSelect from "apartments/components/apartment-select/ApartmentTagsSelect";

export default function ApartmentTagsForm(props) {
    const {
        form,
        apartment,
        ...otherProps
    } = props;

    return (
        <Form
            form={form}
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 12,
            }}
            {...otherProps}
        >
            <Form.Item
                name="tags"
                label="Tags"
            >
                <ApartmentTagsSelect
                    mode="multiple"
                />
            </Form.Item>
        </Form>
    );
}