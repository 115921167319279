import { Button, Col, InputNumber, Row, Typography } from "antd";
import { useState } from "react";

export default function MinimumStayInput(props) {
    const {
        value,
        onChange,
    } = props;

    const weekdays = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
    ];

    const [showMore, setShowMore] = useState(value && value.some(v => v !== value[0]));

    function handleOnChangeSimple(value) {
        onChange(Array(7).fill(value));
    }

    function handleOnChangeFull(singleValue, index) {
        const newValue = [...value];
        newValue[index] = singleValue;
        onChange(newValue);
    }

    return (
        <Row
            gutter={[16, 16]}
            justify="space-between"
            wrap={false}
        >
            {showMore && (
                <>
                    <Col>
                        <Row gutter={[16, 16]}>
                            {weekdays.map((weekday, index) => (
                                <Col
                                    span={24}
                                    key={weekday}
                                >
                                    <Row
                                        gutter={[16, 16]}
                                        align="middle"
                                    >
                                        <Col>
                                            <InputNumber
                                                value={value?.[index]}
                                                onChange={value => handleOnChangeFull(value, index)}
                                                addonAfter={value?.[index] === 1 ? 'day' : 'days'}
                                                min={1}
                                            />
                                        </Col>
                                        <Col>
                                            <Typography.Text>
                                                on {weekday}
                                            </Typography.Text>
                                        </Col>
                                    </Row>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col>
                        <Button
                            type="link"
                            onClick={() => setShowMore(false)}
                        >
                            Show less
                        </Button>
                    </Col>
                </>
            )}
            {!showMore && (
                <>
                    <Col>
                        <Row
                            gutter={[16, 16]}
                            align="middle"
                        >
                            <Col>
                                <InputNumber
                                    value={value?.[0]}
                                    onChange={value => handleOnChangeSimple(value)}
                                    addonAfter={value?.[0] === 1 ? 'day' : 'days'}
                                    min={1}
                                />
                            </Col>
                            <Col>
                                <Typography.Text>
                                    every day
                                </Typography.Text>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => setShowMore(true)}
                        >
                            Show more
                        </Button>
                    </Col>
                </>
            )}
        </Row>
    );
}