import { List } from "antd";
import { Link } from "react-router-dom";

export default function ListItem(props) {
    const {
        automation,
    } = props;

    return (
        <List.Item>
            <List.Item.Meta
                title={
                    <Link to={`/automations/${automation.id}/edit`}>
                        {automation.name}
                    </Link>
                }
            />
        </List.Item>
    );
}