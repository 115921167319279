import { Col, Input, Row, Select, Switch } from "antd";

export default function AirbnbInstantBookingInput(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <Row
            gutter={[16, 16]}
            style={{
                paddingTop: value?.enabled && '5px',
            }}
        >
            <Col span={24}>
                <Switch
                    checked={value?.enabled}
                    onChange={enabled => onChange({ ...value ?? {}, enabled })}
                    disabled
                />
            </Col>
            {value?.enabled && (
                <>
                    <Col span={24}>
                        <Select
                            options={[
                                {
                                    label: 'Everyone',
                                    value: 'everyone',
                                },
                                {
                                    label: 'Well reviewed guests',
                                    value: 'wellReviewedGuests',
                                },
                                {
                                    label: 'Guests with verified identity',
                                    value: 'guestsWithVerifiedIdentity',
                                },
                                {
                                    label: 'Well reviewed guests with verified identity',
                                    value: 'wellReviewedGuestsWithVerifiedIdentity',
                                },
                            ]}
                            value={value?.category}
                            onChange={category => onChange({ ...value ?? {}, category })}
                            placeholder="Category"
                            style={{
                                width: '100%',
                            }}
                        />
                    </Col>
                    <Col span={24}>
                        <Input.TextArea
                            value={value?.message}
                            onChange={e => onChange({ ...value ?? {}, message: e.target.value })}
                            placeholder="Welcome message"
                            autoSize={{
                                minRows: 3,
                            }}
                            maxLength={370}
                            showCount
                        />
                    </Col>
                </>
            )}
        </Row>
    )
}