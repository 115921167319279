import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row } from "antd";
import UserLanguageSelect from "users/components/user-language-select/UserLanguageSelect";

export default function TranslationsInput(props) {
    const {
        value,
        onChange,
    } = props;

    function handleAddTranslation() {
        onChange([...value ?? [], { language: undefined, translation: undefined }]);
    }

    function handleLanguageChange(index, language) {
        const newValue = [...value];
        newValue[index].language = language;
        onChange(newValue);
    }

    function handleTranslationChange(index, translation) {
        const newValue = [...value];
        newValue[index].translation = translation;
        onChange(newValue);
    }

    function handleRemoveItem(index) {
        const newValue = [...value].filter((_, otherIndex) => otherIndex !== index);
        onChange(newValue);
    }

    console.log(value);

    return (
        <Row gutter={[16, 16]}>
            {[...value ?? []].map((item, index) => (
                <Col
                    span={24}
                    key={index}
                >
                    <Row
                        gutter={[16, 16]}
                        align="middle"
                    >
                        <Col flex={0}>
                            <UserLanguageSelect
                                value={item.language}
                                onChange={value => handleLanguageChange(index, value)}
                                placeholder="Language"
                                style={{
                                    width: '150px',
                                }}
                            />
                        </Col>
                        <Col flex={1}>
                            <Input
                                value={item.translation}
                                onChange={e => handleTranslationChange(index, e.target.value)}
                                placeholder="Translation"
                            />
                        </Col>
                        <Col flex={0}>
                            <CloseOutlined
                                onClick={() => handleRemoveItem(index)}
                            />
                        </Col>
                    </Row>
                </Col>
            ))}
            <Col span={24}>
                <Button
                    block
                    onClick={() => handleAddTranslation()}
                >
                    Add translation
                </Button>
            </Col>
        </Row>
    );
}