import { Col, InputNumber, Row, Select } from "antd";

export default function BookingFeeInput(props) {
    const {
        value,
        onChange,
        currency,
    } = props;

    return (
        <Row
            gutter={[16, 16]}
            justify="space-between"
            align="middle"
            wrap={false}
        >
            <Col flex={0}>
                <Select
                    options={[
                        {
                            label: 'Service charge',
                            value: 'serviceCharge',
                        },
                        {
                            label: 'Resort fee',
                            value: 'resortFee',
                        },
                        {
                            label: 'Cleaning fee',
                            value: 'cleaningFee',
                        },
                        {
                            label: 'Towel fee',
                            value: 'towelFee',
                        },
                        {
                            label: 'Electricity fee',
                            value: 'electricityFee',
                        },
                        {
                            label: 'Bed linen fee',
                            value: 'bedLinenFee',
                        },
                        {
                            label: 'Gas fee',
                            value: 'gasFee',
                        },
                        {
                            label: 'Oil fee',
                            value: 'oilFee',
                        },
                        {
                            label: 'Wood fee',
                            value: 'woodFee',
                        },
                        {
                            label: 'Water fee',
                            value: 'waterFee',
                        },
                        {
                            label: 'Environment fee',
                            value: 'environmentFee',
                        },
                        {
                            label: 'Spa fee',
                            value: 'spaFee',
                        },
                        {
                            label: 'Transfer fee',
                            value: 'transferFee',
                        },
                    ]}
                    value={value?.type}
                    onChange={type => onChange({ ...value, type, unit: 'perReservation' })}
                    placeholder="Type"
                    style={{
                        width: '200px',
                    }}
                />
            </Col>
            {value?.type && (
                <>
                    <Col flex={1}>
                        <InputNumber
                            value={value?.amount}
                            onChange={amount => onChange({ ...value ?? {}, amount })}
                            placeholder="Amount"
                            style={{
                                width: '100%',
                            }}
                        />
                    </Col>
                    <Col flex={0}>
                        <Select
                            options={[
                                {
                                    label: `${currency} per reservation`,
                                    value: 'perReservation',
                                },
                                {
                                    label: `${currency} per guest`,
                                    value: 'perGuest',
                                },
                                {
                                    label: `${currency} per night`,
                                    value: 'perNight',
                                },
                                {
                                    label: `${currency} per guest per night`,
                                    value: 'perGuestPerNight',
                                },
                                {
                                    label: '% of reservation price',
                                    value: 'percentOfReservationPrice',
                                },
                            ]}
                            value={value?.unit}
                            onChange={unit => onChange({ ...value, unit })}
                            style={{
                                width: '250px',
                            }}
                        />
                    </Col>
                </>
            )}
        </Row>
    );
}