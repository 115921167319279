import { Form, InputNumber } from "antd";

export default function ApartmentOccupancySettingsForm(props) {
    const {
        form,
        ...otherProps
    } = props;

    const maxGuestCount = Form.useWatch('maxGuestCount', form);

    return (
        <Form
            form={form}
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 18,
            }}
            {...otherProps}
        >
            <Form.Item
                name="maxGuestCount"
                label="Maximum occupancy"
                rules={[{ required: true, message: 'Maximum occupancy is required' }]}
            >
                <InputNumber
                    addonAfter={maxGuestCount === 1 ? 'guest' : 'guests'}
                    min={1}
                    max={18}
                />
            </Form.Item>
        </Form>
    );
}