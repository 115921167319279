import { DeleteOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";

export default function DeletableInputWrapper(props) {
    const {
        value,
        onChange,
        onDelete,
        children,
        ...otherProps
    } = props;

    return (
        <Row
            gutter={[16, 16]}
            wrap={false}
            align="middle"
        >
            <Col flex={1}>
                {React.cloneElement(children, { value, onChange, ...otherProps })}
            </Col>
            <Col flex={0}>
                <DeleteOutlined
                    onClick={() => onDelete()}
                />
            </Col>
        </Row>
    );
}