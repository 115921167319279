import { EditOutlined, SaveOutlined, ThunderboltOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Card, Col, Form, message, PageHeader, Row, Skeleton, Space, Tag } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CodeEditor from "stayql/components/code-editor/CodeEditor";
import StayqlTable from "stayql/components/stayql-table/StayqlTable";
import QueryParamsForm from "stayql/forms/QueryParamsForm";
import QueryVariablesForm from "stayql/forms/QueryVariablesForm";

const QUERY = gql`
    query GetStayqlQuery($stayqlQueryId: ID!) {
        stayqlQuery(stayqlQueryId: $stayqlQueryId) {
            id
            name
            query
            tags
            variables {
                name
                type
            }
        }
    }
`;

const RUN_INPLACE_QUERY = gql`
    query GetResultForCreateStayqlQuery($input: RunStayqlQueryInplaceInput!) {
        runStayqlQueryInplace(input: $input) {
            columns {
                name
                type
            }
            rows {
                values
            }
            error {
                message
            }
        }
    }
`;

const MUTATION = gql`
    mutation UpdateStayqlQuery($input: UpdateStayqlQueryInput!) {
        updateStayqlQuery(input: $input) {
            stayqlQuery {
                id
                name
                tags
                query
                variables {
                    name
                    type
                }
            }
            error {
                type
                message
            }
        }
    }
`;

export default function EditStayqlQueryView() {
    const { stayqlQueryId } = useParams();
    const navigate = useNavigate();

    const [query, setQuery] = useState();
    const [variablesModalOpen, setVariablesModalOpen] = useState(false);
    const [variables, setVariables] = useState();
    const [updateModalOpen, setUpdateModalOpen] = useState(false);

    const [variablesForm] = Form.useForm();
    const [paramsForm] = Form.useForm();

    const { data, loading, error } = useQuery(QUERY, { variables: { stayqlQueryId } });
    const [runStayqlQueryInplace, { data: resultData, loading: resultLoading, error: resultError, called: resultCalled }] = useLazyQuery(RUN_INPLACE_QUERY);
    const [updateStayqlQuery, { loading: updateStayqlQueryLoading }] = useMutation(MUTATION);

    useEffect(() => {
        setQuery(data?.stayqlQuery?.query);
    }, [data]);

    const existingVariables = [...data?.stayqlQuery?.variables ?? []]
        .map(variable => ({
            name: variable.name,
            type: variable.type,
        }));

    function handleRunQuery() {
        runStayqlQueryInplace({
            variables: {
                input: {
                    query,
                    variables: variables ?? [],
                },
            },
            fetchPolicy: 'no-cache',
        });
    }

    function handleVariablesSave() {
        variablesForm
            .validateFields()
            .then(values => {
                setVariables(values.variables);
                setVariablesModalOpen(false);
            });
    }

    function handleUpdateQuery() {
        updateStayqlQuery({
            variables: {
                input: {
                    stayqlQueryId,
                    query,
                    variables: variables.map(variable => ({
                        name: variable.name,
                        type: variable.type,
                    })),
                },
            },
        })
            .then(response => {
                if (response.data.updateStayqlQuery.error) {
                    message.error("Failed to update query");
                }
                else {
                    message.success("Query updated");
                }
            })
            .catch(() => {
                message.error("Failed to update query");
            });
    }

    function handleUpdateParams() {
        paramsForm
            .validateFields()
            .then(values => {
                return updateStayqlQuery({
                    variables: {
                        input: {
                            stayqlQueryId,
                            name: values.name,
                            tags: values.tags ?? [],
                            query,
                            variables: [],
                        },
                    },
                })
                    .then(response => {
                        if (response.data.updateStayqlQuery.error) {
                            message.error("Failed to update query");
                        }
                        else {
                            message.success("Query updated");
                        }
                    })
                    .catch(() => {
                        message.error("Network error");
                    });
            })
            .then(() => {
                setUpdateModalOpen(false);
            })
    }

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load query"
            />
        );
    }

    return (
        <PageHeader
            title={
                <Space>
                    <Space>
                        <span>
                            {data.stayqlQuery.name}
                        </span>
                        {[...data.stayqlQuery.tags ?? []].map(tag => (
                            <Tag key={tag}>
                                {tag}
                            </Tag>
                        ))}
                    </Space>
                    <EditOutlined
                        onClick={() => setUpdateModalOpen(true)}
                    />
                </Space>
            }
            onBack={() => navigate(-1)}
            extra={[
                <Button
                    type="primary"
                    onClick={() => handleUpdateQuery()}
                    icon={<SaveOutlined />}
                    loading={updateStayqlQueryLoading}
                    disabled={query === data.stayqlQuery.query}
                    key="saveQueryButton"
                >
                    Save query
                </Button>
            ]}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <CodeEditor
                                    value={query}
                                    onChange={value => setQuery(value)}
                                    language="sql"
                                />
                            </Col>
                            <Col span={24}>
                                <Row
                                    justify="end"
                                    gutter={[16, 16]}
                                >
                                    <Col>
                                        <Button
                                            onClick={() => setVariablesModalOpen(true)}
                                            icon={<UnorderedListOutlined />}
                                        >
                                            Variables
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            type="primary"
                                            onClick={() => handleRunQuery()}
                                            icon={<ThunderboltOutlined />}
                                            loading={resultLoading}
                                        >
                                            Run
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                {resultCalled && (
                    <Col span={24}>
                        {resultError && (
                            <Alert
                                type="error"
                                showIcon
                                message="Failed to load query results"
                            />
                        )}
                        {resultData?.runStayqlQueryInplace?.error && (
                            <Alert
                                type="error"
                                showIcon
                                message={resultData.runStayqlQueryInplace.error.message}
                            />
                        )}
                        {!resultData?.runStayqlQueryInplace?.error && resultData?.runStayqlQueryInplace?.columns && resultData?.runStayqlQueryInplace?.rows && (
                            <StayqlTable
                                columns={resultData.runStayqlQueryInplace.columns}
                                rows={resultData.runStayqlQueryInplace.rows}
                            />
                        )}
                    </Col>
                )}
            </Row>
            <Modal
                open={updateModalOpen}
                title="Update query"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: updateStayqlQueryLoading,
                }}
                onOk={() => handleUpdateParams()}
                onCancel={() => setUpdateModalOpen(false)}
                destroyOnClose
            >
                <QueryParamsForm
                    form={paramsForm}
                    stayqlQuery={data.stayqlQuery}
                    preserve={false}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                />
            </Modal>
            <Modal
                open={variablesModalOpen}
                title="Variables"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />
                }}
                onOk={() => handleVariablesSave()}
                onCancel={() => setVariablesModalOpen(false)}
                destroyOnClose
            >
                <QueryVariablesForm
                    form={variablesForm}
                    variables={variables ?? existingVariables}
                    preserve={false}
                />
            </Modal>
        </PageHeader>
    );
}