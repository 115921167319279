import { Col, InputNumber, Row, Switch } from "antd";

export default function BookingNonRefundableRateInput(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <Row
            gutter={[16, 16]}
            style={{
                paddingTop: value?.enabled && '5px',
            }}
        >
            <Col span={24}>
                <Switch
                    checked={value?.enabled}
                    onChange={enabled => onChange({ ...value ?? {}, enabled })}
                />
            </Col>
            {value?.enabled && (
                <Col span={24}>
                    <InputNumber
                        value={value?.discount}
                        onChange={discount => onChange({ ...value ?? {}, discount })}
                        min={1}
                        max={100}
                        placeholder="Discount"
                        addonAfter="%"
                    />
                </Col>
            )}
        </Row>
    );
}