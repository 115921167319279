import { Form, InputNumber } from "antd";
import { useAuth } from "auth";
import InputDefaultWrapper from "components/InputDefaultWrapper";

export default function ApartmentCalendarSettingsForm(props) {
    const {
        form,
        ...otherProps
    } = props;

    const { user } = useAuth();

    return (
        <Form
            form={form}
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 18,
            }}
            {...otherProps}
        >
            <Form.Item
                name="calendarLength"
                label="Calendar length"
                rules={[{ required: true, message: 'Calendar length is required' }]}
            >
                <InputDefaultWrapper
                    defaultWrapperValue={user?.organization?.defaultApartmentCalendarLength}
                    renderValue={value => `${value} days`}
                    description="From organization defaults"
                >
                    <InputNumber
                        min={100}
                        max={730}
                        addonAfter="days"
                    />
                </InputDefaultWrapper>
            </Form.Item>
        </Form>
    );
}