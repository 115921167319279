import { Col, Row, Select } from "antd";

export default function BookingCancelationPolicyInput(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <Row
            gutter={[16, 16]}
            align="middle"
            wrap={false}
        >
            <Col span={12}>
                <Select
                    options={[
                        {
                            label: 'Disabled',
                            value: 'disabled',
                        },
                        {
                            label: '1 hour',
                            value: '1',
                        },
                        {
                            label: '4 hours',
                            value: '4',
                        },
                        {
                            label: '24 hours',
                            value: '24',
                        },
                    ]}
                    value={value?.gracePeriod}
                    onChange={gracePeriod => onChange({ ...value ?? {}, gracePeriod })}
                    placeholder="Grace period"
                    style={{
                        width: '100%',
                    }}
                />
            </Col>
            <Col span={12}>
                <Select
                    options={[
                        {
                            label: 'Disabled',
                            value: 'disabled',
                        },
                        {
                            label: '4 weeks',
                            value: '4',
                        },
                        {
                            label: '8 weeks',
                            value: '8',
                        },
                        {
                            label: '12 weeks',
                            value: '12',
                        },
                    ]}
                    value={value?.advanceCancelation}
                    onChange={advanceCancelation => onChange({ ...value ?? {}, advanceCancelation })}
                    placeholder="Advance cancelation"
                    style={{
                        width: '100%',
                    }}
                />
            </Col>
        </Row>
    );
}