import { gql, useQuery } from "@apollo/client";
import { Alert, Col, PageHeader, Row, Skeleton } from "antd";
import { useAuth } from "auth";
import { Link, useNavigate, useParams } from "react-router-dom";
import { STAYQL_TYPE_INTEGER } from "stayql/common";
import { TileContext } from "tiles/components/tile-context/TileContext";
import TileRow from "tiles/components/tile-row/TileRow";

const QUERY = gql`
    query GetApartmentForShowApartmentView($apartmentId: ID!) {
        apartment(apartmentId: $apartmentId) {
            id
            name
            tags
            onboardingTagsDone
            onboardingMetadataDone
            onboardingLocationDone
            onboardingCalendarDone
            onboardingOccupancyDone
            onboardingAvailabilityDone
            onboardingPricingDone
            onboardingChannelsDone
            onboardingOpenCalendarDone
        }
    }
`;

export default function ShowApartmentView() {
    const { apartmentId } = useParams();
    const navigate = useNavigate();

    const { user } = useAuth();
    const { data, loading, error } = useQuery(QUERY, { variables: { apartmentId } });

    const apartmentTiles = user?.organization?.apartmentTiles;
    const onboardingFinished = [
        data?.apartment?.onboardingTagsDone,
        data?.apartment?.onboardingMetadataDone,
        data?.apartment?.onboardingLocationDone,
        data?.apartment?.onboardingCalendarDone,
        data?.apartment?.onboardingOccupancyDone,
        data?.apartment?.onboardingAvailabilityDone,
        data?.apartment?.onboardingPricingDone,
        data?.apartment?.onboardingChannelsDone,
        data?.apartment?.onboardingOpenCalendarDone,
    ].every(done => done);

    return (
        <PageHeader
            title={data?.apartment?.name}
            onBack={() => navigate(-1)}
        >
            <Row gutter={[16, 16]}>
                {loading && (
                    <Col span={24}>
                        <Skeleton />
                    </Col>
                )}
                {error && (
                    <Col span={24}>
                        <Alert
                            type="error"
                            showIcon
                            message="Failed to load apartment"
                        />
                    </Col>
                )}
                {!loading && data && !apartmentTiles && (
                    <Col span={24}>
                        <Alert
                            type="error"
                            showIcon
                            message="Apartment tiles are not configured for you"
                        />
                    </Col>
                )}
                {!loading && !onboardingFinished && (
                    <Col span={24}>
                        <Alert
                            type="warning"
                            showIcon
                            message="You haven't finished onboarding this apartment"
                            action={
                                <Link to={`/apartments/${apartmentId}/onboarding`}>
                                    Finish onboarding
                                </Link>
                            }
                        />
                    </Col>
                )}
                {!loading && data && apartmentTiles && (
                    <Col span={24}>
                        <TileContext.Provider
                            value={{
                                apartmentId,
                                variables: [
                                    {
                                        name: 'apartment_id',
                                        type: STAYQL_TYPE_INTEGER,
                                        value: apartmentId,
                                    },
                                ],
                            }}
                        >
                            <TileRow cols={apartmentTiles.cols} />
                        </TileContext.Provider>
                    </Col>
                )}
            </Row>
        </PageHeader>
    );
}