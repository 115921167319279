import { Form } from "antd";
import UserSelect from "users/components/user-select/UserSelect";

export default function UserIdForm(props) {
    const {
        form,
        ...otherProps
    } = props;

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="value"
                label="User"
                rules={[{ required: true, message: 'User is required' }]}
            >
                <UserSelect />
            </Form.Item>
        </Form>
    );
}