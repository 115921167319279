import { Col, InputNumber, Row } from "antd";
import DatePicker from "components/DatePicker";
import WeekdaySelectorInput from "../weekday-selector-input/WeekdaySelectorInput";

export default function BookingDiscountInput(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <Row
            gutter={[16, 16]}
            align="middle"
        >
            <Col span={12}>
                Booking period
            </Col>
            <Col span={12}>
                Stay period
            </Col>
            <Col span={12}>
                <DatePicker.RangePicker
                    value={value?.bookingPeriod}
                    onChange={bookingPeriod => onChange({ ...value ?? {}, bookingPeriod })}
                />
            </Col>
            <Col span={12}>
                <DatePicker.RangePicker
                    value={value?.stayPeriod}
                    onChange={stayPeriod => onChange({ ...value ?? {}, stayPeriod })}
                />
            </Col>
            <Col span={24}>
                Included weekdays
            </Col>
            <Col span={24}>
                <WeekdaySelectorInput
                    value={value?.includeWeekdays}
                    onChange={includeWeekdays => onChange({ ...value ?? {}, includeWeekdays })}
                />
            </Col>
            <Col span={24}>
                <InputNumber
                    value={value?.amount}
                    onChange={amount => onChange({ ...value ?? {}, amount })}
                    placeholder="Discount"
                    addonAfter="%"
                    min={1}
                    max={99}
                />
            </Col>
        </Row>
    );
}