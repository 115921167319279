import { Col, InputNumber, Row, Switch } from "antd";

export default function BookingAgeRestrictionInput(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Switch
                    checked={value?.enabled}
                    onChange={enabled => onChange({ ...value ?? {}, enabled })}
                />
            </Col>
            {value?.enabled && (
                <>
                    <Col span={12}>
                        <InputNumber
                            value={value?.min}
                            onChange={min => onChange({ ...value ?? {}, min })}
                            min={18}
                            max={99}
                            placeholder="Min age"
                            addonAfter="years"
                        />
                    </Col>
                    <Col span={12}>
                        <InputNumber
                            value={value?.max}
                            onChange={max => onChange({ ...value ?? {}, max })}
                            min={18}
                            max={99}
                            placeholder="Max age"
                            addonAfter="years"
                        />
                    </Col>
                </>
            )}
        </Row>
    );
}