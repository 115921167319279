import { Form, InputNumber } from "antd";
import { useAuth } from "auth";
import CurrencySelect from "components/CurrencySelect";
import InputDefaultWrapper from "components/InputDefaultWrapper";

export default function ApartmentOnboardingPricingForm(props) {
    const {
        form,
        ...otherProps
    } = props;

    const { user } = useAuth();

    const currency = Form.useWatch('currency', form);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="currency"
                label="Currency"
                rules={[{ required: true, message: 'Currency is required' }]}
            >
                <InputDefaultWrapper
                    defaultWrapperValue={user?.organization?.currency}
                    description="From organization defaults"
                >
                    <CurrencySelect
                        searchable
                    />
                </InputDefaultWrapper>
            </Form.Item>
            <Form.Item
                name="defaultPrice"
                label="Default price"
                rules={[{ required: true, message: 'Default price is required' }]}
            >
                <InputDefaultWrapper
                    defaultWrapperValue={user?.organization?.defaultApartmentDefaultPrice}
                    renderValue={value => `${value} ${currency}`}
                    description="From organization defaults"
                >
                    <InputNumber
                        min={0}
                        addonAfter={currency}
                    />
                </InputDefaultWrapper>
            </Form.Item>
        </Form>
    )
}