import { Select } from "antd";

export default function AirbnbCancelationPolicyInput(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <Select
            options={[
                {
                    label: 'Flexible',
                    value: 'flexible',
                },
                {
                    label: 'Moderate',
                    value: 'moderate',
                },
                {
                    label: 'Strict',
                    value: 'strict',
                },
                {
                    label: 'Better strict with grace period',
                    value: 'betterStrictWithGracePeriod',
                },
                {
                    label: 'Strict 14 with grace period',
                    value: 'strict14WithGracePeriod',
                },
                {
                    label: 'Super strict 30',
                    value: 'superStrict30',
                },
                {
                    label: 'Super strict 60',
                    value: 'superStrict60',
                },
                {
                    label: 'Flexible new',
                    value: 'flexibleNew',
                },
                {
                    label: 'Strict new',
                    value: 'strictNew',
                },
                {
                    label: 'Super strict 30 new',
                    value: 'superStrict30New',
                },
                {
                    label: 'Super strict 60 new',
                    value: 'superStrict60New',
                },
            ]}
            value={value}
            onChange={value => onChange(value)}
            style={{
                width: '250px',
            }}
        />
    );
}