import { Checkbox } from "antd";
import { toggleSelect } from "common/common";
import "./style.css"


const TRIGGER_CATEGORIES = [
    {
        category: 'Time',
        triggers: [
            {
                key: 'timer_year',
                label: 'Every year',
            },
            {
                key: 'timer_quarter',
                label: 'Every quarter',
            },
            {
                key: 'timer_month',
                label: 'Every month',
            },
            {
                key: 'timer_week',
                label: 'Every week',
            },
            {
                key: 'timer_day',
                label: 'Every day',
            },
            {
                key: 'timer_hour',
                label: 'Every hour',
            },
            {
                key: 'timer_minute',
                label: 'Every minute',
            },
        ],
    },
    {
        category: 'User',
        triggers: [
            {
                key: 'user_created',
                label: 'User created',
            },
            {
                key: 'user_updated',
                label: 'User updated',
            },
            {
                key: 'user_deleted',
                label: 'User deleted',
            },
            {
                key: 'user_logged_in',
                label: 'User logged in',
            },
            {
                key: 'user_logged_out',
                label: 'User logged out',
            },
            {
                key: 'user_availability_updated',
                label: 'User availability updated',
            },
            {
                key: 'user_relation_created',
                label: 'User relation created',
            },
            {
                key: 'user_relation_deleted',
                label: 'User relation deleted',
            },
        ],
    },
    {
        category: 'User group',
        triggers: [
            {
                key: 'user_group_created',
                label: 'User group created',
            },
            {
                key: 'user_group_updated',
                label: 'User group updated',
            },
            {
                key: 'user_group_deleted',
                label: 'User group deleted',
            },
            {
                key: 'user_group_added_user',
                label: 'User group added user',
            },
            {
                key: 'user_group_removed_user',
                label: 'User group removed user',
            },
        ],
    },
    {
        category: 'File',
        triggers: [
            {
                key: 'file_created',
                label: 'File created',
            },
        ],
    },
    {
        category: 'Storage',
        triggers: [
            {
                key: 'storage_created',
                label: 'Storage created',
            },
            {
                key: 'storage_updated',
                label: 'Storage updated',
            },
            {
                key: 'storage_deleted',
                label: 'Storage deleted',
            },
        ],
    },
    {
        category: 'Apartment',
        triggers: [
            {
                key: 'apartment_created',
                label: 'Apartment created',
            },
            {
                key: 'apartment_updated',
                label: 'Apartment updated',
            },
            {
                key: 'apartment_deleted',
                label: 'Apartment deleted',
            },
            {
                key: 'apartment_calendar_updated',
                label: 'Apartment calendar updated',
            },
            {
                key: 'apartment_channel_created',
                label: 'Apartment channel created',
            },
            {
                key: 'apartment_channel_deleted',
                label: 'Apartment channel deleted',
            },
        ],
    },
    {
        category: 'Expense',
        triggers: [
            {
                key: 'apartment_expense_created',
                label: 'Expense created',
            },
            {
                key: 'apartment_expense_updated',
                label: 'Expense updated',
            },
            {
                key: 'apartment_expense_deleted',
                label: 'Expense deleted',
            },
        ],
    },
    {
        category: 'Price config',
        triggers: [
            {
                key: 'apartment_price_config_updated',
                label: 'Price config updated',
            },
        ],
    },
    {
        category: 'Reservation',
        triggers: [
            {
                key: 'reservation_confirmed',
                label: 'Reservation confirmed',
            },
            {
                key: 'reservation_updated',
                label: 'Reservation updated',
            },
            {
                key: 'reservation_canceled',
                label: 'Reservation canceled',
            },
            {
                key: 'reservation_message_received',
                label: 'Reservation message received',
            },
            {
                key: 'reservation_message_sent',
                label: 'Reservation message sent',
            },
            {
                key: 'reservation_review_received',
                label: 'Reservation review received',
            },
            {
                key: 'reservation_guest_review_sent',
                label: 'Reservation guest review sent',
            },
        ],
    },
    {
        category: 'Cleaning',
        triggers: [
            {
                key: 'cleaning_group_created',
                label: 'Cleaning group created',
            },
            {
                key: 'cleaning_group_submitted',
                label: 'Cleaning group submitted',
            },
            {
                key: 'cleaning_group_confirmed',
                label: 'Cleaning group confirmed',
            },
            {
                key: 'cleaning_group_started',
                label: 'Cleaning group started',
            },
            {
                key: 'cleaning_group_finished',
                label: 'Cleaning group finished',
            },
            {
                key: 'cleaning_group_apartment_created',
                label: 'Cleaning group apartment created',
            },
            {
                key: 'cleaning_group_apartment_updated',
                label: 'Cleaning group apartment updated',
            },
            {
                key: 'cleaning_group_apartment_deleted',
                label: 'Cleaning group apartment deleted',
            },
            {
                key: 'cleaning_group_apartment_started',
                label: 'Cleaning group apartment started',
            },
            {
                key: 'cleaning_group_apartment_finished',
                label: 'Cleaning group apartment finished',
            },
            {
                key: 'cleaning_group_cleaner_added',
                label: 'Cleaning group cleaner added',
            },
            {
                key: 'cleaning_group_cleaner_removed',
                label: 'Cleaning group cleaner removed',
            },
        ],
    },
    {
        category: 'Jobs',
        triggers: [
            {
                key: 'job_created',
                label: 'Job created',
            },
            {
                key: 'job_updated',
                label: 'Job updated',
            },
            {
                key: 'job_deleted',
                label: 'Job deleted',
            },
            {
                key: 'job_assigner_created',
                label: 'Job assigner created',
            },
            {
                key: 'job_assigner_removed',
                label: 'Job assigner removed',
            },
            {
                key: 'job_comment_created',
                label: 'Job comment created',
            },
            {
                key: 'job_comment_updated',
                label: 'Job comment updated',
            },
            {
                key: 'job_comment_deleted',
                label: 'Job comment deleted',
            },
        ],
    },
];


export default function TriggersList(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <div className="triggers-list-container">
            {TRIGGER_CATEGORIES.map(item => (
                <div
                    className="triggers-list-category-container"
                    key={item.category}
                >
                    <div className="triggers-list-category-header">
                        {item.category}
                    </div>
                    {item.triggers.map(trigger => (
                        <div
                            className="triggers-list-trigger-container"
                            onClick={() => onChange(toggleSelect(value ?? [], trigger.key, (a, b) => a === b))}
                            key={trigger.key}
                        >
                            <div className="triggers-list-trigger-checkbox">
                                <Checkbox
                                    checked={[...value ?? []].includes(trigger.key)}
                                />
                            </div>
                            <div className="triggers-list-trigger-label">
                                {trigger.label}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}