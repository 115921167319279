import { gql, useQuery } from "@apollo/client";
import { Alert, List, Skeleton } from "antd";
import ListItem from "./ListItem";

const QUERY = gql`
    query GetAutomationsForList {
        automations {
            id
            name
        }
    }
`;

export default function AutomationsList(props) {
    const { data, loading, error } = useQuery(QUERY);

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load automations"
            />
        );
    }

    const automations = [...data?.automations ?? []]
        .sort((a, b) => a.name.localeCompare(b.name));

    return (
        <List
            dataSource={automations}
            renderItem={automation => (
                <ListItem
                    automation={automation}
                />
            )}
        />
    );
}