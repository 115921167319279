import "@xyflow/react/dist/style.css";
import "./style.css";
import { addEdge, ReactFlow } from "@xyflow/react";
import { Button, Drawer } from "antd";
import { useState } from "react";
import NodesList from "../nodes-list/NodesList";
import TriggersList from "../triggers-list/TriggersList";
import ExtractNode from "automation/components/node-editor/ExtractNode";
import CombineNode from "automation/components/node-editor/CombineNode";
import StringValueNode from "automation/components/node-editor/StringValueNode";
import NumberValueNode from "automation/components/node-editor/NumberValueNode";
import DateValueNode from "automation/components/node-editor/DateValueNode";
import DateTimeValueNode from "automation/components/node-editor/DateTimeValueNode";
import { createNodeType } from "./common";
import TriggerDataNode from "./TriggerDataNode";
import { NODE_DEFINITIONS } from "automation/common";
import UserIdNode from "./UserIdNode";
import UserGroupIdNode from "./UserGroupIdNode";
import ApartmentIdNode from "./ApartmentIdNode";
import ArrayNode from "./ArrayNode";


const NODE_TYPES = {
    ...Object.fromEntries(
        NODE_DEFINITIONS.map(nodeDefinition => ([
            nodeDefinition.type, createNodeType(nodeDefinition),
        ]))
    ),
    trigger_data: TriggerDataNode,
    extract: ExtractNode,
    combine: CombineNode,
    string: StringValueNode,
    number: NumberValueNode,
    array: ArrayNode,
    date: DateValueNode,
    datetime: DateTimeValueNode,
    user_id: UserIdNode,
    user_group_id: UserGroupIdNode,
    apartment_id: ApartmentIdNode,
};

export default function NodeEditor(props) {
    const {
        nodes,
        onSetNodes,
        handleNodesChange,
        edges,
        onSetEdges,
        handleEdgesChange,
        triggers,
        onTriggersChange,
        onInit,
    } = props;

    const [instance, setInstance] = useState();
    const [nodesDrawerOpen, setNodesDrawerOpen] = useState(false);
    const [triggersDrawerOpen, setTriggersDrawerOpen] = useState(false);

    function handleSetInstance(value) {
        setInstance(value);
        onInit(value);
    }

    function handleNodeOnChange(nodeId, value) {
        onSetNodes(prevNodes => prevNodes.map(prevNode => {
            if (prevNode.id === nodeId) {
                return {
                    ...prevNode,
                    data: {
                        ...(prevNode?.data ?? {}),
                        ...value,
                    },
                };
            }

            return prevNode;
        }));
    }

    function handleTriggersChange(value) {
        onSetNodes(prevNodes => [...prevNodes ?? []].map(prevNode => {
            if (prevNode.type === 'trigger_data') {
                return {
                    ...prevNode,
                    data: {
                        ...(prevNode?.data ?? {}),
                        triggers: value,
                    },
                };
            }

            return prevNode;
        }));
        onTriggersChange(value);
    }

    function handleAddNode(type) {
        const centerPosition = instance.screenToFlowPosition({
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
        });
        const extraData = {};
        if (type === 'trigger_data') {
            extraData.triggers = triggers;
        }
        const nodeId = crypto.randomUUID();
        onSetNodes(prevNodes => ([
            ...prevNodes ?? [],
            {
                id: nodeId,
                type,
                data: {
                    onChange: (value) => handleNodeOnChange(nodeId, value),
                    ...extraData,
                },
                position: {
                    x: centerPosition.x,
                    y: centerPosition.y,
                },
            },
        ]));
        setNodesDrawerOpen(false);
    }

    function handleOnConnect(connection) {
        onSetEdges(prevEdges => addEdge({
            ...connection,
            interactionWidth: 3,
        }, [...prevEdges ?? []]));
    }

    return (
        <div className="node-editor-container">
            <div className="node-editor-controls">
                <Button onClick={() => setTriggersDrawerOpen(value => !value)}>
                    Triggers
                </Button>
                <Button onClick={() => setNodesDrawerOpen(value => !value)}>
                    Add node
                </Button>
            </div>
            <div className="node-editor">
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    nodeTypes={NODE_TYPES}
                    onNodesChange={handleNodesChange}
                    onEdgesChange={handleEdgesChange}
                    onConnect={handleOnConnect}
                    onInit={handleSetInstance}
                    fitView
                    panOnScroll
                    maxZoom={1}
                    minZoom={1}
                    snapToGrid
                    snapGrid={[24, 24]}
                />
            </div>
            <Drawer
                open={triggersDrawerOpen}
                title="Triggers"
                onClose={() => setTriggersDrawerOpen(false)}
            >
                <TriggersList
                    value={triggers}
                    onChange={value => handleTriggersChange(value)}
                />
            </Drawer>
            <Drawer
                open={nodesDrawerOpen}
                title="Nodes"
                onClose={() => setNodesDrawerOpen(false)}
            >
                <NodesList
                    onClick={type => handleAddNode(type)}
                />
            </Drawer>
        </div>
    );
}