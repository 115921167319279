import { Form, Input } from "antd";
import { useEffect } from "react";

export default function AutomationForm(props) {
    const {
        form,
        automation,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            name: automation?.name,
        });
    }, [form, automation]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Name is required' }]}
            >
                <Input />
            </Form.Item>
        </Form>
    );
}