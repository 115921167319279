import { useState } from "react"
import { Button, Col, Form, message, Modal, Row, Typography } from "antd";
import { green, grey } from "@ant-design/colors";
import { CheckCircleFilled, HourglassOutlined, SaveOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import ApartmentTagsForm from "apartments/forms/ApartmentTagsForm";

const QUERY = gql`
    query GetApartmentForOnboarding($apartmentId: ID!) {
        apartment(apartmentId: $apartmentId) {
            id
            tags
            onboardingTagsDone
        }
    }
`;

const MUTATION_ONBOARDING = gql`
    mutation UpdateApartmentOnboarding($input: UpdateApartmentOnboardingInput!) {
        updateApartmentOnboarding(input: $input) {
            error {
                type
                message
            }
            apartment {
                id
                onboardingTagsDone
            }
        }
    }
`;

const MUTATION_TAGS = gql`
    mutation UpdateApartmentTags($input: UpdateApartmentTagsInput!) {
        updateApartmentTags(input: $input) {
            error {
                type
                message
            }
            apartment {
                id
                tags
            }
        }
    }
`;

export default function ApartmentTagsOnboardingItem(props) {
    const {
        apartmentId,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { data } = useQuery(QUERY, { variables: { apartmentId } });
    const [updateApartmentOnboarding] = useMutation(MUTATION_ONBOARDING);
    const [updateApartmentTags] = useMutation(MUTATION_TAGS);

    const [form] = Form.useForm();

    const done = data?.apartment?.onboardingTagsDone;
    const disabled = false;

    async function handleSave() {
        try {
            const values = await form.validateFields();

            setLoading(true)

            const updateTagsResponse = await updateApartmentTags({
                variables: {
                    input: {
                        apartmentId,
                        tags: values.tags,
                    },
                },
            });

            if (updateTagsResponse?.data?.updateApartmentTags?.error) {
                message.error("Failed to update apartment tags");
                setLoading(false);
                return;
            }

            const updateOnboardingResponse = await updateApartmentOnboarding({
                variables: {
                    input: {
                        apartmentId,
                        onboardingTagsDone: true,
                    },
                },
            });

            if (updateOnboardingResponse?.data?.updateApartmentOnboarding?.error) {
                message.error("Failed to update apartment onboarding");
                setLoading(false);
                return;
            }

            message.success("Apartment tags updated");
            setLoading(false);
            setModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setLoading(false);
        }
    }

    return (
        <Row gutter={[16, 16]}>
            <Col flex={0}>
                {done && (
                    <CheckCircleFilled
                        style={{
                            color: green.primary,
                        }}
                    />
                )}
                {!done && (
                    <HourglassOutlined
                        style={{
                            color: grey.primary,
                        }}
                    />
                )}
            </Col>
            <Col flex={1}>
                Setup tags
            </Col>
            <Col>
                <Typography.Link
                    disabled={disabled}
                    onClick={() => setModalOpen(true)}
                >
                    Setup
                </Typography.Link>
            </Col>
            <Modal
                open={modalOpen}
                title="Setup tags"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: loading,
                }}
                onOk={() => handleSave()}
                onCancel={() => setModalOpen(false)}
                destroyOnClose
                width={700}
            >
                <ApartmentTagsForm
                    form={form}
                    preserve={false}
                    initialValues={{
                        tags: data.apartment.tags ?? [],
                    }}
                />
            </Modal>
        </Row>
    );
}