
export const NODE_DEFINITIONS = [
    {
        type: 'for_each',
        label: 'For each',
        inputs: [
            {
                key: 'input',
            },
        ],
        outputs: [
            {
                key: 'output',
            },
        ],
    },
    {
        type: 'if',
        lebel: 'IF',
        inputs: [
            {
                key: 'input',
                label: 'Input',
            },
            {
                key: 'value_true',
                label: 'Value true',
            },
            {
                key: 'value_false',
                label: 'Value false',
            },
        ],
        outputs: [
            {
                key: 'output',
            },
        ],
    },
    {
        type: 'and',
        label: 'AND',
        inputs: [
            {
                key: 'a',
                label: 'A',
            },
            {
                key: 'b',
                label: 'B',
            },
        ],
        outputs: [
            {
                key: 'output',
            },
        ],
    },
    {
        type: 'or',
        label: 'OR',
        inputs: [
            {
                key: 'a',
                label: 'A',
            },
            {
                key: 'b',
                label: 'B',
            },
        ],
        outputs: [
            {
                key: 'output',
            },
        ],
    },
    {
        type: 'eq',
        label: '==',
        inputs: [
            {
                key: 'a',
                label: 'A',
            },
            {
                key: 'b',
                label: 'B',
            },
        ],
        outputs: [
            {
                key: 'output',
            },
        ],
    },
    {
        type: 'gt',
        label: '>',
        inputs: [
            {
                key: 'a',
                label: 'A',
            },
            {
                key: 'b',
                label: 'B',
            },
        ],
        outputs: [
            {
                key: 'output',
            },
        ],
    },
    {
        type: 'gte',
        label: '>=',
        inputs: [
            {
                key: 'a',
                label: 'A',
            },
            {
                key: 'b',
                label: 'B',
            },
        ],
        outputs: [
            {
                key: 'output',
            },
        ],
    },
    {
        type: 'lt',
        label: '<',
        inputs: [
            {
                key: 'a',
                label: 'A',
            },
            {
                key: 'b',
                label: 'B',
            },
        ],
        outputs: [
            {
                key: 'output',
            },
        ],
    },
    {
        type: 'lte',
        label: '<=',
        inputs: [
            {
                key: 'a',
                label: 'A',
            },
            {
                key: 'b',
                label: 'B',
            },
        ],
        outputs: [
            {
                key: 'output',
            },
        ],
    },
    {
        type: 'not',
        label: 'NOT',
        inputs: [
            {
                key: 'input',
            },
        ],
        outputs: [
            {
                key: 'output',
            },
        ],
    },
    {
        type: 'contains',
        label: 'Contains',
        inputs: [
            {
                key: 'input',
                label: 'Input',
            },
            {
                key: 'value',
                label: 'Value',
            },
        ],
        outputs: [
            {
                key: 'output',
            },
        ],
    },
    {
        type: 'add',
        label: '+',
        inputs: [
            {
                key: 'a',
                label: 'A',
            },
            {
                key: 'b',
                label: 'B',
            },
        ],
        outputs: [
            {
                key: 'output',
            },
        ],
    },
    {
        type: 'subtract',
        label: '-',
        inputs: [
            {
                key: 'a',
                label: 'A',
            },
            {
                key: 'b',
                label: 'B',
            },
        ],
        outputs: [
            {
                key: 'output',
            },
        ],
    },
    {
        type: 'multiply',
        label: '*',
        inputs: [
            {
                key: 'a',
                label: 'A',
            },
            {
                key: 'b',
                label: 'B',
            },
        ],
        outputs: [
            {
                key: 'output',
            },
        ],
    },
    {
        type: 'divide',
        label: '/',
        inputs: [
            {
                key: 'a',
                label: 'A',
            },
            {
                key: 'b',
                label: 'B',
            },
        ],
        outputs: [
            {
                key: 'output',
            },
        ],
    },
    {
        type: 'today',
        label: 'Today',
        outputs: [
            {
                key: 'output',
            },
        ],
    },
    {
        type: 'now',
        label: 'Now',
        outputs: [
            {
                key: 'output',
            },
        ],
    },
    {
        type: 'get_year',
        label: 'Get year',
        inputs: [
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
        outputs: [
            {
                key: 'output',
                label: 'Year',
            },
        ],
    },
    {
        type: 'get_month',
        label: 'Get month',
        inputs: [
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
        outputs: [
            {
                key: 'output',
                label: 'Month',
            },
        ],
    },
    {
        type: 'get_week',
        label: 'Get week',
        inputs: [
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
        outputs: [
            {
                key: 'output',
                label: 'Week',
            },
        ],
    },
    {
        type: 'get_day_of_week',
        label: 'Get day of week',
        inputs: [
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
        outputs: [
            {
                key: 'output',
                label: 'Day of week',
            },
        ],
    },
    {
        type: 'get_day_of_month',
        label: 'Get day of month',
        inputs: [
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
        outputs: [
            {
                key: 'output',
                label: 'Day of month',
            },
        ],
    },
    {
        type: 'get_hour',
        label: 'Get hour',
        inputs: [
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
        outputs: [
            {
                key: 'output',
                label: 'Hour',
            },
        ],
    },
    {
        type: 'get_minute',
        label: 'Get minute',
        inputs: [
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
        outputs: [
            {
                key: 'output',
                label: 'Minute',
            },
        ],
    },
    {
        type: 'create_apartment',
        label: 'Create apartment',
        inputs: [
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'run',
                label: 'Run',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
            {
                key: 'apartment_id',
                label: 'Apartment ID',
            },
        ],
    },
    {
        type: 'update_apartment',
        label: 'Update apartment',
        inputs: [
            {
                key: 'apartment_id',
                label: 'Apartment ID',
            },
            {
                key: 'apartment',
                label: 'Apartment',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'delete_apartment',
        label: 'Delete apartment',
        inputs: [
            {
                key: 'apartment_id',
                label: 'Apartment ID',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'update_apartment_calendar',
        label: 'Update apartment calendar',
        inputs: [
            {
                key: 'apartment_id',
                label: 'Apartment ID',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'price',
                label: 'Price',
            },
            {
                key: 'min_stay',
                label: 'Min stay',
            },
            {
                key: 'comment',
                label: 'Comment',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'sync_apartment_calendar_down',
        label: 'Sync apartment calendar down',
        inputs: [
            {
                key: 'apartment_id',
                label: 'Apartment ID',
            },
            {
                key: 'date_from',
                label: 'Date from',
            },
            {
                key: 'date_to',
                label: 'Date to',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'sync_apartment_calendar_up',
        label: 'Sync apartment calendar up',
        inputs: [
            {
                key: 'apartment_id',
                label: 'Apartment ID',
            },
            {
                key: 'date_from',
                label: 'Date from',
            },
            {
                key: 'date_to',
                label: 'Date to',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'create_apartment_expense',
        label: 'Create apartment expense',
        inputs: [
            {
                key: 'apartment_id',
                label: 'Apartment ID',
            },
            {
                key: 'expense',
                label: 'Expense',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
            {
                key: 'expense_id',
                label: 'Expense ID',
            },
        ],
    },
    {
        type: 'update_apartment_expense',
        label: 'Update apartment expense',
        inputs: [
            {
                key: 'expense_id',
                label: 'Expense ID',
            },
            {
                key: 'expense',
                label: 'Expense',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'delete_apartment_expense',
        label: 'Delete apartment expense',
        inputs: [
            {
                key: 'expense_id',
                label: 'Expense ID',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'update_apartment_price_config',
        label: 'Update apartment price config',
        inputs: [
            {
                key: 'apartment_id',
                label: 'Apartment ID',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'min_price',
                label: 'Min price',
            },
            {
                key: 'delte',
                label: 'Delta',
            },
            {
                key: 'slope',
                label: 'Slope',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'sync_apartment_reservations_down',
        label: 'Sync apartment reservations down',
        inputs: [
            {
                key: 'apartment_id',
                label: 'Apartment ID',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
            {
                key: 'reservations',
                label: 'Reservations',
            },
        ],
    },
    {
        type: 'update_cleaning_group',
        label: 'Update cleaning group',
        inputs: [
            {
                key: 'cleaning_group_id',
                label: 'Cleaning group ID',
            },
            {
                key: 'cleaning_group',
                label: 'Cleaning group',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'delete_cleaning_group',
        label: 'Delete cleaning group',
        inputs: [
            {
                key: 'cleaning_group_id',
                label: 'Cleaning group ID',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'update_cleaning_group_status',
        label: 'Update cleaning group status',
        inputs: [
            {
                key: 'cleaning_group_id',
                label: 'Cleaning group ID',
            },
            {
                key: 'status',
                label: 'Status',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'update_cleaning_group_apartment',
        label: 'Update cleaning group apartment',
        inputs: [
            {
                key: 'cleaning_group_apartment_id',
                label: 'Cleaning group apartment ID',
            },
            {
                key: 'cleaning_group_apartment',
                label: 'Cleaning group apartment',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'delete_cleaning_group_apartment',
        label: 'Delete cleaning group apartment',
        inputs: [
            {
                key: 'cleaning_group_apartment_id',
                label: 'Cleaning group apartment ID',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'update_cleaning_group_apartment_status',
        label: 'Update cleaning group apartment status',
        inputs: [
            {
                key: 'cleaning_group_apartment_id',
                label: 'Cleaning group apartment ID',
            },
            {
                key: 'status',
                label: 'Status',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'upload_file',
        label: 'Upload file',
        inputs: [
            {
                key: 'file',
                label: 'File',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
            {
                key: 'file_id',
                label: 'File ID',
            },
        ],
    },
    {
        type: 'create_job',
        label: 'Create job',
        inputs: [
            {
                key: 'job',
                label: 'Job',
            },
            {
                key: 'run',
                label: 'Run',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
            {
                key: 'job_id',
                label: 'Job ID',
            },
        ],
    },
    {
        type: 'update_job',
        label: 'Update job',
        inputs: [
            {
                key: 'job_id',
                label: 'Job ID',
            },
            {
                key: 'job',
                label: 'Job',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'delete_job',
        label: 'Create job',
        inputs: [
            {
                key: 'job_id',
                label: 'Job ID',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'update_job_status',
        label: 'Update job status',
        inputs: [
            {
                key: 'job_id',
                label: 'Job ID',
            },
            {
                key: 'status',
                label: 'Status',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'add_job_assigner',
        label: 'Add job assigner',
        inputs: [
            {
                key: 'job_id',
                label: 'Job ID',
            },
            {
                key: 'assigner_id',
                label: 'Assigner ID',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'remove_job_assigner',
        label: 'Remove job assigner',
        inputs: [
            {
                key: 'job_id',
                label: 'Job ID',
            },
            {
                key: 'assigner_id',
                label: 'Assigner ID',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'create_job_comment',
        label: 'Create job comment',
        inputs: [
            {
                key: 'job_id',
                label: 'Job ID',
            },
            {
                key: 'comment',
                label: 'Comment',
            },
            {
                key: 'attachments',
                label: 'Attachments',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
            {
                key: 'comment_id',
                label: 'Comment ID',
            },
        ],
    },
    {
        type: 'create_reservation',
        label: 'Create reservation',
        inputs: [
            {
                key: 'apartment_id',
                label: 'Apartment ID',
            },
            {
                key: 'reservation',
                label: 'Reservation',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
            {
                key: 'reservation_id',
                label: 'Reservation ID',
            },
        ],
    },
    {
        type: 'update_reservation',
        label: 'Update reservation',
        inputs: [
            {
                key: 'reservation_id',
                label: 'Reservation ID',
            },
            {
                key: 'reservation',
                label: 'Reservation',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'cancel_reservation',
        label: 'Cancel reservation',
        inputs: [
            {
                key: 'reservation_id',
                label: 'Reservation ID',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'send_reservation_message',
        label: 'Send reservation message',
        inputs: [
            {
                key: 'reservation_id',
                label: 'Reservation ID',
            },
            {
                key: 'message',
                label: 'Message',
            },
            {
                key: 'attachments',
                label: 'Attachments',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
            {
                key: 'message_id',
                label: 'Message ID',
            },
        ],
    },
    {
        type: 'send_reservation_guest_review',
        label: 'Send reservation guest review',
        inputs: [
            {
                key: 'reservation_id',
                label: 'Reservation ID',
            },
            {
                key: 'review',
                label: 'Review',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
            {
                key: 'review_id',
                label: 'Review ID',
            },
        ],
    },
    {
        type: 'sync_reservation_review',
        label: 'Sync reservation review',
        inputs: [
            {
                key: 'reservation_id',
                label: 'Reservation ID',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
            {
                key: 'review',
                label: 'Review',
            },
        ],
    },
    {
        type: 'create_storage',
        label: 'Create storage',
        inputs: [
            {
                key: 'storage',
                label: 'Storage',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
            {
                key: 'storage_id',
                label: 'Storage ID',
            },
        ],
    },
    {
        type: 'update_storage',
        label: 'Update storage',
        inputs: [
            {
                key: 'storage_id',
                label: 'Storage ID',
            },
            {
                key: 'storage',
                label: 'Storage',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'delete_storage',
        label: 'Delete storage',
        inputs: [
            {
                key: 'storage_id',
                label: 'Storage ID',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'create_user',
        label: 'Create user',
        inputs: [
            {
                key: 'user',
                label: 'User',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
            {
                key: 'user_id',
                label: 'User ID',
            },
        ],
    },
    {
        type: 'update_user',
        label: 'Update user',
        inputs: [
            {
                key: 'user_id',
                label: 'User ID',
            },
            {
                key: 'user',
                label: 'User',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'delete_user',
        label: 'Delete user',
        inputs: [
            {
                key: 'user_id',
                label: 'User ID',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'create_user_group',
        label: 'Create user group',
        inputs: [
            {
                key: 'user_group',
                label: 'User group',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
            {
                key: 'user_group_id',
                label: 'User group ID',
            },
        ],
    },
    {
        type: 'update_user_group',
        label: 'Update user group',
        inputs: [
            {
                key: 'user_group_id',
                label: 'User group ID',
            },
            {
                key: 'user_group',
                label: 'User group',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'delete_user_group',
        label: 'Delete user group',
        inputs: [
            {
                key: 'user_group_id',
                label: 'User group ID',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'add_user_to_user_group',
        label: 'Add user to user group',
        inputs: [
            {
                key: 'user_group_id',
                label: 'User group ID',
            },
            {
                key: 'user_id',
                label: 'User ID',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'remove_user_from_user_group',
        label: 'Remove user from user group',
        inputs: [
            {
                key: 'user_group_id',
                label: 'User group ID',
            },
            {
                key: 'user_id',
                label: 'User ID',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'create_user_relation',
        label: 'Create user relation',
        inputs: [
            {
                key: 'user_id',
                label: 'User ID',
            },
            {
                key: 'relation',
                label: 'Relation',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'delete_user_relation',
        label: 'Delete user relation',
        inputs: [
            {
                key: 'user_id',
                label: 'User ID',
            },
            {
                key: 'relation',
                label: 'Relation',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'send_email',
        label: 'Send email',
        inputs: [
            {
                key: 'to',
                label: 'To',
            },
            {
                key: 'title',
                label: 'Title',
            },
            {
                key: 'body_template',
                label: 'Body template',
            },
            {
                key: 'attachments',
                label: 'Attachments',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'send_sms',
        label: 'Send SMS',
        inputs: [
            {
                key: 'to',
                label: 'To',
            },
            {
                key: 'body',
                label: 'Body',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'send_notification',
        label: 'Send notification',
        inputs: [
            {
                key: 'user_id',
                label: 'User ID',
            },
            {
                key: 'title',
                label: 'Title',
            },
            {
                key: 'body',
                label: 'Body',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
        ],
    },
    {
        type: 'generate_pdf',
        label: 'Generate PDF',
        inputs: [
            {
                key: 'template',
                label: 'Template',
            },
            {
                key: 'date',
                label: 'Date',
            },
        ],
        outputs: [
            {
                key: 'status',
                label: 'Status',
            },
            {
                key: 'file',
                label: 'File',
            },
        ],
    },
];

export const TRIGGER_DEFINITIONS = [
    {
        type: 'timer_year',
        label: 'Every year',
        outputs: [
            {
                key: 'year',
                label: 'year',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'timer_quarter',
        label: 'Every quarter',
        outputs: [
            {
                key: 'year',
                label: 'Year',
            },
            {
                key: 'quarter',
                label: 'Quarter',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'timer_month',
        label: 'Every month',
        outputs: [
            {
                key: 'year',
                label: 'Year',
            },
            {
                key: 'month',
                label: 'Month',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'timer_week',
        label: 'Every week',
        outputs: [
            {
                key: 'year',
                label: 'Year',
            },
            {
                key: 'month',
                label: 'Month',
            },
            {
                key: 'week',
                label: 'Week',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'timer_day',
        label: 'Every day',
        outputs: [
            {
                key: 'year',
                label: 'Year',
            },
            {
                key: 'month',
                label: 'Month',
            },
            {
                key: 'day_of_month',
                label: 'Day of month',
            },
            {
                key: 'day_of_week',
                label: 'Day of week',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'timer_hour',
        label: 'Every hour',
        outputs: [
            {
                key: 'year',
                label: 'Year',
            },
            {
                key: 'month',
                label: 'Month',
            },
            {
                key: 'day_of_month',
                label: 'Day of month',
            },
            {
                key: 'day_of_week',
                label: 'Day of week',
            },
            {
                key: 'hour',
                label: 'Hour',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'timer_minute',
        label: 'Every minute',
        outputs: [
            {
                key: 'year',
                label: 'Year',
            },
            {
                key: 'month',
                label: 'Month',
            },
            {
                key: 'day_of_month',
                label: 'Day of month',
            },
            {
                key: 'day_of_week',
                label: 'Day of week',
            },
            {
                key: 'hour',
                label: 'Hour',
            },
            {
                key: 'minute',
                label: 'Minute',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'user_created',
        label: 'User created',
        outputs: [
            {
                key: 'user',
                label: 'User',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'user_updated',
        label: 'User updated',
        outputs: [
            {
                key: 'user',
                label: 'User',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'user_deleted',
        label: 'User deleted',
        outputs: [
            {
                key: 'user',
                label: 'User',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'user_logged_in',
        label: 'User logged in',
        outputs: [
            {
                key: 'user',
                label: 'User',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'user_logged_out',
        label: 'User logged out',
        outputs: [
            {
                key: 'user',
                label: 'User',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'user_availability_updated',
        label: 'User availability updated',
        outputs: [
            {
                key: 'user',
                label: 'User',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'availability',
                label: 'Availability',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'user_relation_created',
        label: 'User relation created',
        outputs: [
            {
                key: 'user',
                label: 'User',
            },
            {
                key: 'relation',
                label: 'Relation',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'user_relation_deleted',
        label: 'User relation deleted',
        outputs: [
            {
                key: 'user',
                label: 'User',
            },
            {
                key: 'relation',
                label: 'Relation',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'user_group_created',
        label: 'User group created',
        outputs: [
            {
                key: 'user_group',
                label: 'User group',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'user_group_updated',
        label: 'User group updated',
        outputs: [
            {
                key: 'user_group',
                label: 'User group',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'user_group_deleted',
        label: 'User group deleted',
        outputs: [
            {
                key: 'user_group',
                label: 'User group',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'user_group_added_user',
        label: 'User group added user',
        outputs: [
            {
                key: 'user_group',
                label: 'User group',
            },
            {
                key: 'user',
                label: 'User',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'user_group_removed_user',
        label: 'User group removed user',
        outputs: [
            {
                key: 'user_group',
                label: 'User group',
            },
            {
                key: 'user',
                label: 'User',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'file_created',
        label: 'File created',
        outputs: [
            {
                key: 'file',
                label: 'File',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'storage_created',
        label: 'Storage created',
        outputs: [
            {
                key: 'storage',
                label: 'Storage',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'storage_updated',
        label: 'Storage updated',
        outputs: [
            {
                key: 'storage',
                label: 'Storage',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'storage_deleted',
        label: 'Storage deleted',
        outputs: [
            {
                key: 'storage',
                label: 'Storage',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'apartment_created',
        label: 'Apartment created',
        outputs: [
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'apartment_updated',
        label: 'Apartment updated',
        outputs: [
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'apartment_deleted',
        label: 'Apartment deleted',
        outputs: [
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'apartment_calendar_updated',
        label: 'Apartment calendar updated',
        outputs: [
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'change',
                label: 'Change',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'apartment_channel_created',
        label: 'Apartment channel created',
        outputs: [
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'channel',
                label: 'Channel',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'apartment_channel_deleted',
        label: 'Apartment channel deleted',
        outputs: [
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'channel',
                label: 'Channel',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'apartment_expense_created',
        label: 'Apartment expense created',
        outputs: [
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'expense',
                label: 'Expense',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'apartment_expense_updated',
        label: 'Apartment expense updated',
        outputs: [
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'expense',
                label: 'Expense',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'apartment_expense_deleted',
        label: 'Apartment expense deleted',
        outputs: [
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'expense',
                label: 'Expense',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'apartment_price_config_updated',
        label: 'Apartment price config updated',
        outputs: [
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'price_config',
                label: 'Price config',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'reservation_confirmed',
        label: 'Reservation confirmed',
        outputs: [
            {
                key: 'reservation',
                label: 'Reservation',
            },
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'reservation_updated',
        label: 'Reservation updated',
        outputs: [
            {
                key: 'reservation',
                label: 'Reservation',
            },
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'reservation_canceled',
        label: 'Reservation canceled',
        outputs: [
            {
                key: 'reservation',
                label: 'Reservation',
            },
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'reservation_checked',
        label: 'Reservation checked',
        outputs: [
            {
                key: 'reservation',
                label: 'Reservation',
            },
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'reservation_message_received',
        label: 'Reservation message received',
        outputs: [
            {
                key: 'message',
                label: 'Message',
            },
            {
                key: 'reservation',
                label: 'Reservation',
            },
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'reservation_message_sent',
        label: 'Reservation message sent',
        outputs: [
            {
                key: 'message',
                label: 'Message',
            },
            {
                key: 'reservation',
                label: 'Reservation',
            },
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'reservation_review_received',
        label: 'Reservation review received',
        outputs: [
            {
                key: 'review',
                label: 'Review',
            },
            {
                key: 'reservation',
                label: 'Reservation',
            },
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'reservation_guest_review_sent',
        label: 'Reservation guest review sent',
        outputs: [
            {
                key: 'review',
                label: 'Review',
            },
            {
                key: 'reservation',
                label: 'Reservation',
            },
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'cleaning_group_created',
        label: 'Cleaning group created',
        outputs: [
            {
                key: 'cleaning_group',
                label: 'Cleaning group',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'cleaning_group_submitted',
        label: 'Cleaning group submitted',
        outputs: [
            {
                key: 'cleaning_group',
                label: 'Cleaning group',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'cleaning_group_confirmed',
        label: 'Cleaning group confirmed',
        outputs: [
            {
                key: 'cleaning_group',
                label: 'Cleaning group',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'cleaning_group_started',
        label: 'Cleaning group started',
        outputs: [
            {
                key: 'cleaning_group',
                label: 'Cleaning group',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'cleaning_group_finished',
        label: 'Cleaning group finished',
        outputs: [
            {
                key: 'cleaning_group',
                label: 'Cleaning group',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'cleaning_group_apartment_created',
        label: 'Cleaning group apartment created',
        outputs: [
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'cleaning_group',
                label: 'Cleaning group',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'cleaning_group_apartment_updated',
        label: 'Cleaning group apartment updated',
        outputs: [
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'cleaning_group',
                label: 'Cleaning group',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'cleaning_group_apartment_deleted',
        label: 'Cleaning group apartment deleted',
        outputs: [
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'cleaning_group',
                label: 'Cleaning group',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'cleaning_group_apartment_started',
        label: 'Cleaning group apartment started',
        outputs: [
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'cleaning_group',
                label: 'Cleaning group',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'cleaning_group_apartment_finished',
        label: 'Cleaning group apartment finished',
        outputs: [
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'cleaning_group',
                label: 'Cleaning group',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'cleaning_group_cleaner_added',
        label: 'Cleaning group cleaner added',
        outputs: [
            {
                key: 'cleaner',
                label: 'Cleaner',
            },
            {
                key: 'cleaning_group',
                label: 'Cleaning group',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'cleaning_group_cleaner_removed',
        label: 'Cleaning group cleaner removed',
        outputs: [
            {
                key: 'cleaner',
                label: 'Cleaner',
            },
            {
                key: 'cleaning_group',
                label: 'Cleaning group',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'job_created',
        label: 'Job created',
        outputs: [
            {
                key: 'job',
                label: 'Job',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'reservation',
                label: 'Reservation',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'job_updated',
        label: 'Job updated',
        outputs: [
            {
                key: 'job',
                label: 'Job',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'reservation',
                label: 'Reservation',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'job_deleted',
        label: 'Job deleted',
        outputs: [
            {
                key: 'job',
                label: 'Job',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'reservation',
                label: 'Reservation',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'job_assigner_created',
        label: 'Job assigner created',
        outputs: [
            {
                key: 'assigner',
                label: 'Assigner',
            },
            {
                key: 'job',
                label: 'Job',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'reservation',
                label: 'Reservation',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'job_assigner_removed',
        label: 'Job assigner removed',
        outputs: [
            {
                key: 'assigner',
                label: 'Assigner',
            },
            {
                key: 'job',
                label: 'Job',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'reservation',
                label: 'Reservation',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'job_comment_created',
        label: 'Job comment created',
        outputs: [
            {
                key: 'comment',
                label: 'Comment',
            },
            {
                key: 'author',
                label: 'Author',
            },
            {
                key: 'job',
                label: 'Job',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'reservation',
                label: 'Reservation',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'job_comment_updated',
        label: 'Job comment updated',
        outputs: [
            {
                key: 'comment',
                label: 'Comment',
            },
            {
                key: 'author',
                label: 'Author',
            },
            {
                key: 'job',
                label: 'Job',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'reservation',
                label: 'Reservation',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
    {
        type: 'job_comment_deleted',
        label: 'Job comment deleted',
        outputs: [
            {
                key: 'comment',
                label: 'Comment',
            },
            {
                key: 'author',
                label: 'Author',
            },
            {
                key: 'job',
                label: 'Job',
            },
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'apartment',
                label: 'Apartment',
            },
            {
                key: 'reservation',
                label: 'Reservation',
            },
            {
                key: 'timestamp',
                label: 'Timestamp',
            },
        ],
    },
];