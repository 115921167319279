import { Form, Input, Select } from "antd";

export default function ApartmentMappingForm(props) {
    const {
        form,
        ...otherProps
    } = props;

    const otaName = Form.useWatch('otaName', form);

    return (
        <Form
            form={form}
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 18,
            }}
            {...otherProps}
        >
            <Form.Item
                name="otaName"
                label="Channel"
                rules={[{ required: true, message: 'Channel is required' }]}
            >
                <Select
                    options={[
                        {
                            label: 'Booking.com',
                            value: 'BookingCom',
                        },
                        {
                            label: 'Airbnb.com',
                            value: 'AirBNB',
                        },
                    ]}
                    style={{
                        width: '200px',
                    }}
                />
            </Form.Item>
            {otaName === 'BookingCom' && (
                <>
                <Form.Item
                    name={['mapping', 'hotel_id']}
                    label="Hotel ID"
                    rules={[{ required: true, message: 'Hotel ID is required' }]}
                >
                    <Input />
                </Form.Item>
                    <Form.Item
                        name={['mapping', 'room_id']}
                        label="Room ID"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={['mapping', 'rate_id']}
                        label="Rate ID"
                    >
                        <Input />
                    </Form.Item>
                </>
            )}
            {otaName === 'AirBNB' && (
                <Form.Item
                    name={['mapping', 'listing_id']}
                    label="Listing ID"
                    rules={[{ required: true, message: 'Listing ID is required' }]}
                >
                    <Input />
                </Form.Item>
            )}
        </Form>
    );
}