import { Col, Input, Row, Select } from "antd";

export default function AirbnbGuestExpectationInput(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <Row gutter={[16, 16]}>
            <Col flex={0}>
                <Select
                    options={[
                        {
                            label: 'Requires stairs',
                            value: 'requiresStairs',
                        },
                        {
                            label: 'Potential noise',
                            value: 'potentialNoise',
                        },
                        {
                            label: 'Has pets',
                            value: 'hasPets',
                        },
                        {
                            label: 'Limited parking',
                            value: 'limitedParking',
                        },
                        {
                            label: 'Shared spaces',
                            value: 'sharedSpaces',
                        },
                        {
                            label: 'Limited amenities',
                            value: 'limitedAmenities',
                        },
                        {
                            label: 'Surveillance',
                            value: 'surveillance',
                        },
                        {
                            label: 'Noise monitor',
                            value: 'noiseMonitor',
                        },
                        {
                            label: 'Weapons',
                            value: 'weapons',
                        },
                        {
                            label: 'Animals',
                            value: 'animals',
                        },
                        {
                            label: 'Pool or jacuzzi with no fence',
                            value: 'poolOrJacuzziWithNoFence',
                        },
                        {
                            label: 'Lake or river or water body',
                            value: 'lakeOrRiverOrWaterBody',
                        },
                        {
                            label: 'Climbing or play structure',
                            value: 'climbingOrPlayStructure',
                        },
                        {
                            label: 'Heights with no fence',
                            value: 'heightsWithNoFence',
                        },
                    ]}
                    value={value?.type}
                    onChange={type => onChange({ ...value ?? {}, type })}
                    placeholder="Expectation"
                    style={{
                        width: '300px',
                    }}
                />
            </Col>
            <Col flex={1}>
                <Input.TextArea
                    value={value?.description}
                    onChange={e => onChange({ ...value ?? {}, description: e.target.value })}
                    placeholder="Description"
                    maxLength={300}
                    showCount
                />
            </Col>
        </Row>
    )
}