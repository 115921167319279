import { Handle, Position } from "@xyflow/react";
import { TRIGGER_DEFINITIONS } from "automation/common";
import { useRef } from "react";
import { fixNodeWidth } from "./common";

export default function TriggerDataNode(props) {
    const { data } = props;

    const containerRef = useRef();

    fixNodeWidth(containerRef);

    const triggers = data.triggers ?? [];
    const outputs = TRIGGER_DEFINITIONS
        .filter(nodeDefinition => triggers.includes(nodeDefinition.type))
        .map(nodeDefinition => nodeDefinition.outputs)
        .flat()
        .filter((item, index, array) => array.findIndex(i => i.key === item.key) === index);

    return (
        <>
            <div
                className="node-container"
                ref={containerRef}
            >
                <div className="node-title">
                    <span>
                        Trigger data
                    </span>
                </div>
                {outputs.length === 0 && (
                    <div className="node-empty">
                        No triggers
                    </div>
                )}
                <div className="node-labels">
                    <div className="node-input-labels">
                    </div>
                    <div className="node-output-labels">
                        {[...outputs ?? []].map(output => (
                            <div
                                className="node-output-label"
                                key={output.key}
                            >
                                {output.label}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {[...outputs ?? []].map((output, index) => (
                <Handle
                    type="source"
                    id={output.key}
                    position={Position.Right}
                    style={{
                        top: 36 + (index * 24)
                    }}
                    className="node-handle"
                    key={output.key}
                />
            ))}
        </>
    );
}