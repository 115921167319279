import { Col, InputNumber, Row, Switch } from "antd";

export default function AirbnbAllowPetsInput(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Switch
                    checked={value?.enabled}
                    onChange={enabled => onChange({ ...value ?? {}, enabled })}
                />
            </Col>
            {value?.enabled && (
                <Col span={24}>
                    <InputNumber
                        value={value?.maxPetCount}
                        onChange={maxPetCount => onChange({ ...value ?? {}, maxPetCount })}
                        min={1}
                        max={5}
                        placeholder="Capacity"
                        addonAfter="pets"
                    />
                </Col>
            )}
        </Row>
    );
}