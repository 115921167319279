import { Col, Row, Switch } from "antd";
import DatePicker from "components/DatePicker";

export default function BookingRenovationInput(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Switch
                    checked={value?.enabled}
                    onChange={enabled => onChange({ ...value ?? {}, enabled })}
                />
            </Col>
            {value?.enabled && (
                <>
                    <Col span={12}>
                        <DatePicker
                            value={value?.start}
                            onChange={start => onChange({ ...value ?? {}, start })}
                        />
                    </Col>
                    <Col span={12}>
                        <DatePicker
                            value={value?.end}
                            onChange={end => onChange({ ...value ?? {}, end })}
                        />
                    </Col>
                </>
            )}
        </Row>
    )
}