const FEE_TYPE_FROM_BACKEND = {
    cleaning_fee: 'cleaningFee',
    short_term_cleaning_fee: 'shortTermCleaningFee',
    pet_fee: 'petFee',
    management_fee: 'managementFee',
    community_fee: 'communityFee',
    linen_fee: 'linenFee',
    resort_fee: 'resortFee',
};

const FEE_TYPE_TO_BACKEND = {
    cleaningFee: 'cleaning_fee',
    shortTermCleaningFee: 'short_term_cleaning_fee',
    petFee: 'pet_fee',
    managementFee: 'management_fee',
    communityFee: 'community_fee',
    linenFee: 'linen_fee',
    resortFee: 'resort_fee',
};

const FEE_UNIT_FROM_BACKEND = {
    per_reservation: 'perReservation',
    per_pet: 'perPet',
    per_guest: 'perGuest',
    per_night: 'perNight',
    per_pet_per_night: 'perPetPerNight',
    per_guest_per_night: 'perGuestPerNight',
    percent_of_reservation_price: 'percentOfReservationPrice',
};

const FEE_UNIT_TO_BACKEND = {
    perReservation: 'per_reservation',
    perPet: 'per_pet',
    perGuest: 'per_guest',
    perNight: 'per_night',
    perPetPerNight: 'per_pet_per_night',
    perGuestPerNight: 'per_guest_per_night',
    percentOfReservationPrice: 'percent_of_reservation_price',
};

const DISCOUNT_TYPE_FROM_BACKEND = {
    weekly_discount: 'weeklyDiscount',
    monthly_discount: 'monthlyDiscount',
    length_of_stay: 'lengthOfStay',
    last_minute: 'lastMinute',
    first_minute: 'firstMinute',
};

const DISCOUNT_TYPE_TO_BACKEND = {
    weeklyDiscount: 'weekly_discount',
    monthlyDiscount: 'monthly_discount',
    lengthOfStay: 'length_of_stay',
    lastMinute: 'last_minute',
    firstMinute: 'first_minute',
};

const GUEST_EXPECTATIONS_FROM_BACKEND = {
    requires_stairs: 'requiresStairs',
    potential_noise: 'potentialNoise',
    has_pets: 'hasPets',
    limited_parking: 'limitedParking',
    shared_spaces: 'sharedSpaces',
    limited_amenities: 'limitedAmenities',
    surveillance: 'surveillance',
    noise_monitor: 'noiseMonitor',
    weapons: 'weapons',
    animals: 'animals',
    pool_or_jacuzzi_with_no_fence: 'poolOrJacuzziWithNoFence',
    lake_or_river_or_water_body: 'lakeOrRiverOrWaterBody',
    climbing_or_play_structure: 'climbingOrPlayStructure',
    heights_with_no_fence: 'heightsWithNoFence',
};

const GUEST_EXPECTATIONS_TO_BACKEND = {
    requiresStairs: 'requires_stairs',
    potentialNoise: 'potential_noise',
    hasPets: 'has_pets',
    limitedParking: 'limited_parking',
    sharedSpaces: 'shared_spaces',
    limitedAmenities: 'limited_amenities',
    surveillance: 'surveillance',
    noiseMonitor: 'noise_monitor',
    weapons: 'weapons',
    animals: 'animals',
    poolOrJacuzziWithNoFence: 'pool_or_jacuzzi_with_no_fence',
    lakeOrRiverOrWaterBody: 'lake_or_river_or_water_body',
    climbingOrPlayStructure: 'climbing_or_play_structure',
    heightsWithNoFence: 'heights_with_no_fence',
};

const INSTANT_BOOKING_CATEGORY_FROM_BACKEND = {
    everyone: 'everyone',
    well_reviewed_guests: 'wellReviewedGuests',
    guests_with_verified_identity: 'guestsWithVerifiedIdentity',
    well_reviewed_guests_with_verified_identity: 'wellReviewedGuestsWithVerifiedIdentity',
};

const INSTANT_BOOKING_CATEGORY_TO_BACKEND = {
    everyone: 'everyone',
    wellReviewedGuests: 'well_reviewed_guests',
    guestsWithVerifiedIdentity: 'guests_with_verified_identity',
    wellReviewedGuestsWithVerifiedIdentity: 'well_reviewed_guests_with_verified_identity',
};

const CANCELATION_POLICY_FROM_BACKEND = {
    flexible: 'flexible',
    moderate: 'moderate',
    strict: 'strict',
    better_strict_with_grace_period: 'betterStrictWithGracePeriod',
    strict_14_with_grace_period: 'strict14WithGracePeriod',
    super_strict_30: 'superStrict30',
    super_strict_60: 'superStrict60',
    flexible_new: 'flexibleNew',
    strict_new: 'strictNew',
    super_strict_30_new: 'superStrict30New',
    super_strict_60_new: 'superStrict60New',
};

const CANCELATION_POLICY_TO_BACKEND = {
    flexible: 'flexible',
    moderate: 'moderate',
    strict: 'strict',
    betterStrictWithGracePeriod: 'better_strict_with_grace_period',
    strict14WithGracePeriod: 'strict_14_with_grace_period',
    superStrict30: 'super_strict_30',
    superStrict60: 'super_strict_60',
    flexibleNew: 'flexible_new',
    strictNew: 'strict_new',
    superStrict30New: 'super_strict_30_new',
    superStrict60New: 'super_strict_60_new',
};

export function airbnbFromBackend(data) {
    const guestPricing = {
        mode: data?.guest_pricing?.mode,
    };

    if (data?.guest_pricing?.mode === 'simple') {
        guestPricing.pricing = {
            baseGuestCount: data?.guest_pricing?.pricing?.base_guest_count,
            extraGuestFee: data?.guest_pricing?.pricing?.extra_guest_fee,
        }
    }
    if (data?.guest_pricing?.mode === 'complex') {
        guestPricing.pricing = data?.guest_pricing?.pricing;
    }

    const fees = [...data?.fees ?? []].map(fee => ({
        type: FEE_TYPE_FROM_BACKEND[fee.type],
        unit: FEE_UNIT_FROM_BACKEND[fee.unit],
        amount: fee.amount,
    }));

    const discounts = [...data?.discounts ?? []].map(discount => ({
        type: DISCOUNT_TYPE_FROM_BACKEND[discount.type],
        threshold: discount.threshold,
        amount: discount.amount,
    }));

    const guestExpectations = [...data?.guest_expectations ?? []].map(guestExpectation => ({
        type: GUEST_EXPECTATIONS_FROM_BACKEND[guestExpectation.type],
        description: guestExpectation.description,
    }));

    const instantBooking = {
        enabled: data?.instant_booking?.enabled,
    };

    if (data?.instant_booking?.enabled) {
        instantBooking.category = INSTANT_BOOKING_CATEGORY_FROM_BACKEND[data?.instant_booking?.category];
        instantBooking.message = data?.instant_booking?.message;
    }

    const nonRefundableRate = {
        enabled: data?.non_refundable_rate?.enabled,
    };

    if (data?.non_refundable_rate?.enabled) {
        nonRefundableRate.discount = data?.non_refundable_rate?.discount;
    }

    const allowChildren = {
        enabled: data?.allow_children?.enabled,
    };

    if (!data?.allow_children?.enabled) {
        allowChildren.description = data?.allow_children?.description;
    }

    const allowPets = {
        enabled: data?.allow_pets?.enabled,
    };

    if (data?.allow_pets?.enabled) {
        allowPets.maxPetCount = data?.allow_pets?.max_pet_count;
    }

    return {
        guestPricing,
        fees,
        discounts,
        guestExpectations,
        instantBooking,
        nonRefundableRate,
        cancelationPolicy: CANCELATION_POLICY_FROM_BACKEND[data?.cancelation_policy],
        checkInTimeStart: data?.check_in_time_start,
        checkInTimeEnd: data?.check_in_time_end,
        checkOutTimeEnd: data?.check_out_time_end,
        allowInfants: data?.allow_infants,
        allowChildren,
        allowEvents: data?.allow_events,
        allowPets,
        allowSmoking: data?.allow_smoking,
    };
}

export function airbnbToBackend(data) {
    const guest_pricing = {
        mode: data?.guestPricing?.mode,
    };

    if (data?.guestPricing?.mode === 'simple') {
        guest_pricing.pricing = {
            base_guest_count: data?.guestPricing?.pricing?.baseGuestCount,
            extra_guest_fee: data?.guestPricing?.pricing?.extraGuestFee,
        }
    }
    if (data?.guestPricing?.mode === 'complex') {
        guest_pricing.pricing = data?.guestPricing?.pricing;
    }

    const fees = [...data?.fees ?? []].map(fee => ({
        type: FEE_TYPE_TO_BACKEND[fee.type],
        unit: FEE_UNIT_TO_BACKEND[fee.unit],
        amount: fee.amount,
    }));

    const discounts = [...data?.discounts ?? []].map(discount => ({
        type: DISCOUNT_TYPE_TO_BACKEND[discount.type],
        threshold: discount.threshold,
        amount: discount.amount,
    }));

    const guest_expectations = [...data?.guestExpectations ?? []].map(guestExpectation => ({
        type: GUEST_EXPECTATIONS_TO_BACKEND[guestExpectation.type],
        description: guestExpectation.description,
    }));

    const instant_booking = {
        enabled: data?.instantBooking?.enabled,
    };

    if (data?.instantBooking?.enabled) {
        instant_booking.category = INSTANT_BOOKING_CATEGORY_TO_BACKEND[data?.instantBooking?.category];
        instant_booking.message = data?.instantBooking?.message;
    }

    const non_refundable_rate = {
        enabled: data?.nonRefundableRate?.enabled,
    };

    if (data?.nonRefundableRate?.enabled) {
        non_refundable_rate.discount = data?.nonRefundableRate?.discount;
    }

    const allow_children = {
        enabled: data?.allowChildren?.enabled,
    };

    if (!data?.allowChildren?.enabled) {
        allow_children.description = data?.allowChildren?.description;
    }

    const allow_pets = {
        enabled: data?.allowPets?.enabled,
    };

    if (data?.allowPets?.enabled) {
        allow_pets.max_pet_count = data?.allowPets?.maxPetCount;
    }

    return {
        guest_pricing,
        fees,
        discounts,
        guest_expectations,
        instant_booking,
        non_refundable_rate,
        cancelation_policy: CANCELATION_POLICY_TO_BACKEND[data?.cancelationPolicy],
        check_in_time_start: data?.checkInTimeStart,
        check_in_time_end: data?.checkInTimeEnd,
        check_out_time_end: data?.checkOutTimeEnd,
        allow_infants: data?.allowInfants,
        allow_children,
        allow_events: data?.allowEvents,
        allow_pets,
        allow_smoking: data?.allowSmoking,
    };
}