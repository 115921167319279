import { Col, InputNumber, Row, Switch } from "antd";

export default function BookingAllowChildrenInput(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Switch
                    checked={value?.enabled}
                    onChange={enabled => onChange({ ...value ?? {}, enabled })}
                />
            </Col>
            {value?.enabled && (
                <Col span={24}>
                    <InputNumber
                        value={value?.minAge}
                        onChange={minAge => onChange({ ...value ?? {}, minAge })}
                        placeholder="Min age"
                        addonAfter="years"
                    />
                </Col>
            )}
        </Row>
    );
}