import { Form } from "antd";
import ApartmentSelect from "apartments/components/apartment-select/ApartmentSelect";

export default function ApartmentIdForm(props) {
    const {
        form,
        ...otherProps
    } = props;

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="value"
                label="Apartment"
                rules={[{ required: true, message: 'Apartment is required' }]}
            >
                <ApartmentSelect />
            </Form.Item>
        </Form>
    );
}