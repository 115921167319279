import { useState } from "react"
import { Col, Form, message, Modal, Row, Typography } from "antd";
import { green, grey } from "@ant-design/colors";
import { CheckCircleFilled, HourglassOutlined, SaveOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { taskPromise } from "common/task";
import { cache } from "apollo";
import ApartmentMappingForm from "apartments/forms/ApartmentMappingForm";
import ApartmentChannelOnboardingItem from "./ApartmentChannelOnboardingItem";

const QUERY = gql`
    query GetApartmentForOnboarding($apartmentId: ID!) {
        apartment(apartmentId: $apartmentId) {
            id
            currency
            maxGuestCount
            channels {
                id
                active
                otaName
                mapping
                otaSettings
                status
                statusMessage
            }
            onboardingLocationDone
            onboardingCalendarDone
            onboardingOccupancyDone
            onboardingAvailabilityDone
            onboardingPricingDone
            onboardingChannelsDone
        }
    }
`;

const MUTATION_ONBOARDING = gql`
    mutation UpdateApartmentOnboarding($input: UpdateApartmentOnboardingInput!) {
        updateApartmentOnboarding(input: $input) {
            error {
                type
                message
            }
            apartment {
                id
                onboardingChannelsDone
            }
        }
    }
`;

const MUTATION_CREATE_CHANNEL = gql`
    mutation CreateApartmentChannel($input: CreateApartmentChannelInput!) {
        createApartmentChannel(input: $input) {
            error {
                type
                message
            }
            apartmentChannel {
                id
                otaName
                active
                mapping
                otaSettings
                status
                statusMessage
                createdAt
            }
            syncApartmentChannelUpTask {
                id
            }
        }
    }
`;

export default function ApartmentChannelsOnboardingItem(props) {
    const {
        apartmentId,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { data } = useQuery(QUERY, { variables: { apartmentId } });
    const [updateApartmentOnboarding] = useMutation(MUTATION_ONBOARDING);
    const [createApartmentChannel] = useMutation(MUTATION_CREATE_CHANNEL);

    const [form] = Form.useForm();

    const done = data?.apartment?.onboardingChannelsDone;
    const disabled = !data?.apartment?.onboardingLocationDone || !data?.apartment?.onboardingCalendarDone || !data?.apartment?.onboardingOccupancyDone || !data?.apartment?.onboardingAvailabilityDone || !data?.apartment?.onboardingPricingDone;

    async function handleSave() {
        try {
            const values = await form.validateFields();
            setLoading(true);

            const createApartmentChannelResponse = await createApartmentChannel({
                variables: {
                    input: {
                        apartmentId,
                        active: true,
                        otaName: values.otaName,
                        mapping: values.mapping,
                    },
                },
            });

            if (createApartmentChannelResponse?.data?.createApartmentChannel?.error) {
                if (createApartmentChannelResponse.data.createApartmentChannel.error.type === 'already_exists') {
                    message.error("Channel with given mapping already exists");
                }
                else {
                    message.error("Unknown error");
                }
                setLoading(false);
                return;
            }

            const createApartmentChannelResult = await taskPromise(createApartmentChannelResponse?.data?.createApartmentChannel?.syncApartmentChannelUpTask?.id);

            cache.evict({
                id: cache.identify({
                    __typename: 'Apartment',
                    id: apartmentId,
                }),
                fieldName: 'channels',
            });

            if (createApartmentChannelResult.status !== 'success') {
                message.error('Failed to connect channel');
                setLoading(false);
                setModalOpen(false);
                return;
            }

            const updateOnboardingResponse = await updateApartmentOnboarding({
                variables: {
                    input: {
                        apartmentId,
                        onboardingChannelsDone: true,
                    },
                },
            });

            if (updateOnboardingResponse?.data?.updateApartmentOnboarding?.error) {
                message.error(`Failed to update apartment onboarding`);
                setLoading(false);
                return;
            }

            message.success("Channel created");
            setLoading(false);
            setModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setLoading(false);
        }
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Row gutter={[16, 16]}>
                    <Col flex={0}>
                        {done && (
                            <CheckCircleFilled
                                style={{
                                    color: green.primary,
                                }}
                            />
                        )}
                        {!done && (
                            <HourglassOutlined
                                style={{
                                    color: grey.primary,
                                }}
                            />
                        )}
                    </Col>
                    <Col flex={1}>
                        Connect channels
                    </Col>
                    <Col>
                        <Typography.Link
                            onClick={() => setModalOpen(true)}
                            disabled={disabled}
                        >
                            Connect
                        </Typography.Link>
                    </Col>
                </Row>
                <Modal
                    open={modalOpen}
                    title="Connect channels"
                    okText="Connect"
                    okButtonProps={{
                        icon: <SaveOutlined />,
                        loading: loading,
                    }}
                    onOk={() => handleSave()}
                    onCancel={() => setModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    <ApartmentMappingForm
                        form={form}
                        preserve={false}
                    />
                </Modal>
            </Col>
            {[...data?.apartment?.channels ?? []].map(channel => (
                <Col
                    span={24}
                    key={channel.id}
                >
                    <ApartmentChannelOnboardingItem
                        channel={channel}
                        apartment={data?.apartment}
                    />
                </Col>
            ))}
        </Row>
    );
}