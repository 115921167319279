import { Button, Col, Row, Typography } from "antd";
import React, { useState } from "react";

export default function InputDefaultWrapper(props) {
    const {
        value,
        onChange,
        defaultWrapperValue,
        renderValue,
        description,
        children,
    } = props;

    const [useDefault, setUseDefault] = useState(!value);

    if (useDefault) {
        return (
            <Row
                gutter={[16, 16]}
                justify="space-between"
                align="middle"
            >
                <Col>
                    <Row gutter={[16, 16]}>
                        <Col>
                            <Typography.Text>
                                {renderValue ? renderValue(defaultWrapperValue) : defaultWrapperValue}
                            </Typography.Text>
                        </Col>
                        {description && (
                            <Col>
                                <Typography.Text type="secondary">
                                    {description}
                                </Typography.Text>
                            </Col>
                        )}
                    </Row>
                </Col>
                <Col>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => setUseDefault(false)}
                    >
                        Change
                    </Button>
                </Col>
            </Row>
        );
    }
    else {
        return React.cloneElement(children, { value, onChange });
    }
}