import { Col, Row, Select } from "antd";
import GuestPricingSimpleInput from "../guest-pricing-simple-input/GuestPricingSimpleinput";
import GuestPricingComplexInput from "../guest-pricing-complex-input/GuestPricingComplexInput";

export default function GuestPricingInput(props) {
    const {
        value,
        onChange,
        currency,
        maxGuestCount,
    } = props;

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Select
                    options={[
                        {
                            label: 'Simple',
                            value: 'simple',
                        },
                        {
                            label: 'Complex',
                            value: 'complex',
                        },
                    ]}
                    value={value?.mode}
                    onChange={mode => onChange({ ...value ?? {}, mode })}
                    placeholder="Mode"
                    style={{
                        width: '200px',
                    }}
                />
            </Col>
            {value?.mode === 'simple' && (
                <Col span={24}>
                    <GuestPricingSimpleInput
                        value={value?.pricing}
                        onChange={pricing => onChange({ ...value ?? {}, pricing })}
                        maxGuestCount={maxGuestCount}
                        currency={currency}
                    />
                </Col>
            )}
            {value?.mode === 'complex' && (
                <Col span={24}>
                    <GuestPricingComplexInput
                        value={value?.pricing}
                        onChange={pricing => onChange({ ...value ?? {}, pricing })}
                        maxGuestCount={maxGuestCount}
                        currency={currency}
                    />
                </Col>
            )}
        </Row>
    );
}