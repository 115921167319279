import "./style.css"

const NODE_CATEGORIES = [
    {
        category: 'Trigger',
        nodes: [
            {
                label: 'Trigger data',
                key: 'trigger_data',
            },
        ],
    },
    {
        category: 'Flow control',
        nodes: [
            {
                label: "For each",
                key: "for_each"
            },
            {
                label: 'IF',
                key: "if"
            },
        ],
    },
    {
        category: 'Logical',
        nodes: [
            {
                label: "AND",
                key: "and"
            },
            {
                label: "OR",
                key: "or"
            },
            {
                label: '==',
                key: 'eq',
            },
            {
                label: ">",
                key: "gt"
            },
            {
                label: ">=",
                key: "gte"
            },
            {
                label: "<",
                key: "lt"
            },
            {
                label: "<=",
                key: "lte"
            },
            {
                label: "NOT",
                key: "not"
            },
            {
                label: "Contains",
                key: "contains"
            },
        ],
    },
    {
        category: 'Arithmetic',
        nodes: [
            {
                label: "+",
                key: "add"
            },
            {
                label: "-",
                key: "subtract"
            },
            {
                label: "*",
                key: "multiply"
            },
            {
                label: "/",
                key: "divide"
            },
        ]
    },
    {
        category: 'Manipulation',
        nodes: [
            {
                label: 'Extract',
                key: 'extract'
            },
            {
                label: 'Combine',
                key: 'combine',
            },
        ],
    },
    {
        category: 'Value',
        nodes: [
            {
                label: 'String',
                key: 'string',
            },
            {
                label: 'Number',
                key: 'number',
            },
            {
                label: 'Array',
                key: 'array',
            },
            {
                label: 'Date',
                key: 'date',
            },
            {
                label: 'Date time',
                key: 'datetime',
            },
            {
                label: "Today",
                key: "today"
            },
            {
                label: "Now",
                key: "now"
            },
            {
                label: 'User',
                key: 'user_id',
            },
            {
                label: 'User group',
                key: 'user_group_id',
            },
            {
                label: 'Apartment',
                key: 'apartment_id',
            },
            {
                label: 'Storage',
                key: 'storage_id',
            },
        ],
    },
    {
        category: 'Date',
        nodes: [
            {
                label: 'Get year',
                key: 'get_year',
            },
            {
                label: 'Get month',
                key: 'get_month',
            },
            {
                label: 'Get week',
                key: 'get_week',
            },
            {
                label: 'Get day of week',
                key: 'get_day_of_week',
            },
            {
                label: 'Get day of month',
                key: 'get_day_of_month',
            },
            {
                label: 'Get hour',
                key: 'get_hour',
            },
            {
                label: 'Get minute',
                key: 'get_minute',
            },
        ],
    },
    {
        category: 'Action',
        nodes: [
            {
                label: "Create apartment",
                key: "create_apartment"
            },
            {
                label: "Update apartment",
                key: "update_apartment"
            },
            {
                label: "Delete apartment",
                key: "delete_apartment"
            },
            {
                label: "Update apartment calendar",
                key: "update_apartment_calendar"
            },
            {
                label: "Sync apartment calendar down",
                key: "sync_apartment_calendar_down"
            },
            {
                label: "Sync apartment calendar up",
                key: "sync_apartment_calendar_up"
            },
            {
                label: "Create apartment expense",
                key: "create_apartment_expense"
            },
            {
                label: "Update apartment expense",
                key: "update_apartment_expense"
            },
            {
                label: "Delete apartment expense",
                key: "delete_apartment_expense"
            },
            {
                label: "Update apartment price config",
                key: "update_apartment_price_config"
            },
            {
                label: "Sync apartment reservations down",
                key: "sync_apartment_reservations_down"
            },
            {
                label: "Update cleaning group",
                key: "update_cleaning_group"
            },
            {
                label: "Delete cleaning group",
                key: "delete_cleaning_group"
            },
            {
                label: "Update cleaning group status",
                key: "update_cleaning_group_status"
            },
            {
                label: "Update cleaning group apartment",
                key: "update_cleaning_group_apartment"
            },
            {
                label: "Delete cleaning group apartment",
                key: "delete_cleaning_group_apartment"
            },
            {
                label: "Update cleaning group apartment status",
                key: "update_cleaning_group_apartment_status"
            },
            {
                label: "Upload file",
                key: "upload_file"
            },
            {
                label: "Create job",
                key: "create_job"
            },
            {
                label: "Update job",
                key: "update_job"
            },
            {
                label: "Create job",
                key: "delete_job"
            },
            {
                label: "Update job status",
                key: "update_job_status"
            },
            {
                label: "Add job assigner",
                key: "add_job_assigner"
            },
            {
                label: "Remove job assigner",
                key: "remove_job_assigner"
            },
            {
                label: "Create job comment",
                key: "create_job_comment"
            },
            {
                label: "Create reservation",
                key: "create_reservation"
            },
            {
                label: "Update reservation",
                key: "update_reservation"
            },
            {
                label: "Cancel reservation",
                key: "cancel_reservation"
            },
            {
                label: "Send reservation message",
                key: "send_reservation_message"
            },
            {
                label: "Send reservation guest review",
                key: "send_reservation_guest_review"
            },
            {
                label: "Sync reservation review",
                key: "sync_reservation_review"
            },
            {
                label: "Create storage",
                key: "create_storage"
            },
            {
                label: "Update storage",
                key: "update_storage"
            },
            {
                label: "Delete storage",
                key: "delete_storage"
            },
            {
                label: "Create user",
                key: "create_user"
            },
            {
                label: "Update user",
                key: "update_user"
            },
            {
                label: "Delete user",
                key: "delete_user"
            },
            {
                label: "Create user group",
                key: "create_user_group"
            },
            {
                label: "Update user group",
                key: "update_user_group"
            },
            {
                label: "Delete user group",
                key: "delete_user_group"
            },
            {
                label: "Add user to user group",
                key: "add_user_to_user_group"
            },
            {
                label: "Remove user from user group",
                key: "remove_user_from_user_group"
            },
            {
                label: "Create user relation",
                key: "create_user_relation"
            },
            {
                label: "Delete user relation",
                key: "delete_user_relation"
            },
            {
                label: "Send email",
                key: "send_email"
            },
            {
                label: "Send SMS",
                key: "send_sms"
            },
            {
                label: "Send notification",
                key: "send_notification"
            },
            {
                label: "Generate PDF",
                key: "generate_pdf"
            },
        ],
    },
];

export default function NodesList(props) {
    const {
        onClick,
    } = props;

    return (
        <div className="nodes-list-container">
            {NODE_CATEGORIES.map(item => (
                <div
                    className="nodes-list-category-container"
                    key={item.category}
                >
                    <div className="nodes-list-category-header">
                        {item.category}
                    </div>
                    {item.nodes.map(node => (
                        <div
                            className="nodes-list-node-container"
                            onClick={() => onClick(node.key)}
                            key={node.key}
                        >
                            {node.label}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}