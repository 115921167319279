import { Checkbox, Col, Row } from "antd";

export default function WeekdaySelectorInput(props) {
    const {
        value,
        onChange,
    } = props;

    function handleOnChange(index) {
        const newValue = [...value ?? [false, false, false, false, false, false, false]];
        newValue[index] = !newValue[index];
        onChange(newValue);
    }

    return (
        <Row wrap={false}>
            <Col flex={1}>
                <Checkbox
                    checked={value?.[0]}
                    onChange={value => handleOnChange(0)}
                >
                    Mon
                </Checkbox>
            </Col>
            <Col flex={1}>
                <Checkbox
                    checked={value?.[1]}
                    onChange={value => handleOnChange(1)}
                >
                    Tue
                </Checkbox>
            </Col>
            <Col flex={1}>
                <Checkbox
                    checked={value?.[2]}
                    onChange={value => handleOnChange(2)}
                >
                    Wed
                </Checkbox>
            </Col>
            <Col flex={1}>
                <Checkbox
                    checked={value?.[3]}
                    onChange={value => handleOnChange(3)}
                >
                    Thu
                </Checkbox>
            </Col>
            <Col flex={1}>
                <Checkbox
                    checked={value?.[4]}
                    onChange={value => handleOnChange(4)}
                >
                    Fri
                </Checkbox>
            </Col>
            <Col flex={1}>
                <Checkbox
                    checked={value?.[5]}
                    onChange={value => handleOnChange(5)}
                >
                    Sat
                </Checkbox>
            </Col>
            <Col flex={1}>
                <Checkbox
                    checked={value?.[6]}
                    onChange={value => handleOnChange(6)}
                >
                    Sun
                </Checkbox>
            </Col>
        </Row>
    );
}