import { Select } from "antd";

function generateOptions(minHour, maxHour) {
    const options = [];
    for (let i = minHour; i <= maxHour; i++) {
        const hour = i % 24;
        const isNextDay = hour > 24 ? ' next day' : '';
        options.push({
            label: `${hour.toString().padStart(2, '0')}:00${isNextDay}`,
            value: i.toString(),
        });
    }

    return options;
}

export default function AirbnbCheckinTimeInput(props) {
    const {
        value,
        onChange,
        minHour,
        maxHour,
    } = props;

    return (
        <Select
            options={[
                ...generateOptions(minHour, maxHour),
                {
                    label: 'Flexible',
                    value: 'FLEXIBLE',
                },
            ]}
            value={value}
            onChange={value => onChange(value)}
            style={{
                width: '100px',
            }}
        />
    );
}