import { useState } from "react"
import { Col, Form, message, Modal, Row, Typography } from "antd";
import { green, grey } from "@ant-design/colors";
import { CheckCircleFilled, HourglassOutlined, SaveOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { taskPromise } from "common/task";
import ApartmentOpenCalendarForm from "apartments/forms/ApartmentOpenCalendarForm";

const QUERY = gql`
    query GetApartmentForOnboarding($apartmentId: ID!) {
        apartment(apartmentId: $apartmentId) {
            id
            onboardingLocationDone
            onboardingCalendarDone
            onboardingOccupancyDone
            onboardingAvailabilityDone
            onboardingPricingDone
            onboardingOpenCalendarDone
        }
    }
`;

const MUTATION_ONBOARDING = gql`
    mutation UpdateApartmentOnboarding($input: UpdateApartmentOnboardingInput!) {
        updateApartmentOnboarding(input: $input) {
            error {
                type
                message
            }
            apartment {
                id
                onboardingOpenCalendarDone
            }
        }
    }
`;

const MUTATION_OPEN_CALENDAR = gql`
    mutation OpenApartmentCalendar($input: OpenApartmentCalendarInput!) {
        openApartmentCalendar(input: $input) {
            error {
                type
                message
            }
            openApartmentCalendarTask {
                id
            }
        }
    }
`;

export default function ApartmentOpenCalendarOnboardingItem(props) {
    const {
        apartmentId,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { data } = useQuery(QUERY, { variables: { apartmentId } });
    const [updateApartmentOnboarding] = useMutation(MUTATION_ONBOARDING);
    const [openApartmentCalendar] = useMutation(MUTATION_OPEN_CALENDAR);

    const [form] = Form.useForm();

    const done = data?.apartment?.onboardingOpenCalendarDone;
    const disabled = !data?.apartment?.onboardingLocationDone || !data?.apartment?.onboardingCalendarDone || !data?.apartment?.onboardingOccupancyDone || !data?.apartment?.onboardingAvailabilityDone || !data?.apartment?.onboardingPricingDone;

    async function handleSave() {
        try {
            const values = await form.validateFields();
            setLoading(true);

            const openApartmentCalendarResponse = await openApartmentCalendar({
                variables: {
                    input: {
                        apartmentId,
                        availableFrom: values.availableFrom,
                    },
                },
            });

            if (openApartmentCalendarResponse?.data?.openApartmentCalendar?.error) {
                message.error("Failed to open calendar");
                setLoading(false);
                return;
            }

            try {
                await taskPromise(openApartmentCalendarResponse?.data?.openApartmentCalendar?.openApartmentCalendarTask?.id);
            }
            catch {
                message.error(`Failed to open calendar`);
                setLoading(false);
                return;
            }

            const updateOpenApartmentCalendarOnboardingResponse = await updateApartmentOnboarding({
                variables: {
                    input: {
                        apartmentId,
                        onboardingOpenCalendarDone: true,
                    },
                },
            });

            if (updateOpenApartmentCalendarOnboardingResponse?.data?.updateApartmentOnboarding?.error) {
                message.error(`Failed to update apartment onboarding`);
                setLoading(false);
                return;
            }

            message.success("Apartment calendar opened");
            setLoading(false);
            setModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setLoading(false);
        }
    }

    return (
        <Row gutter={[16, 16]}>
            <Col flex={0}>
                {done && (
                    <CheckCircleFilled
                        style={{
                            color: green.primary,
                        }}
                    />
                )}
                {!done && (
                    <HourglassOutlined
                        style={{
                            color: grey.primary,
                        }}
                    />
                )}
            </Col>
            <Col flex={1}>
                Open calendar
            </Col>
            <Col>
                <Typography.Link
                    onClick={() => setModalOpen(true)}
                    disabled={disabled}
                >
                    Setup
                </Typography.Link>
            </Col>
            <Modal
                open={modalOpen}
                title="Open calendar"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: loading,
                }}
                onOk={() => handleSave()}
                onCancel={() => setModalOpen(false)}
                destroyOnClose
                width={700}
            >
                <ApartmentOpenCalendarForm
                    form={form}
                    preserve={false}
                />
            </Modal>
        </Row>
    );
}