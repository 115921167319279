import { useRef, useState } from "react";
import { Form, Modal } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { Handle, Position } from "@xyflow/react";
import { gql, useQuery } from "@apollo/client";
import UserGroupIdForm from "automation/forms/UserGroupIdForm";
import { fixNodeWidth } from "./common";

const QUERY = gql`
    query GetUserGroupsForUserGroupIdNode {
        userGroups {
            id
            name
        }
    }
`;

export default function UserGroupIdNode(props) {
    const { data } = props;

    const { data: queryData } = useQuery(QUERY);

    const [modalOpen, setModalOpen] = useState(false);
    const [form] = Form.useForm();
    const containerRef = useRef();

    function handleModalOpen() {
        form.setFieldValue('value', data?.value);
        setModalOpen(true);
    }

    function handleSubmit() {
        form
            .validateFields()
            .then(values => {
                data.onChange({ value: values.value });
                setModalOpen(false);
            });
    }

    fixNodeWidth(containerRef);

    const userGroup = [...queryData?.userGroups ?? []].find(userGroup => userGroup.id === data?.value);

    return (
        <>
            <div
                className="node-container"
                ref={containerRef}
            >
                <div className="node-title">
                    <span>
                        User group
                    </span>
                    <SettingOutlined
                        onClick={() => handleModalOpen()}
                    />
                </div>
                <div className="node-labels">
                    <div className="node-input-labels">
                    </div>
                    <div className="node-output-labels">
                        <div className="node-output-label">
                            {userGroup?.name}
                        </div>
                    </div>
                </div>
            </div>
            <Handle
                type="source"
                id="output"
                position={Position.Right}
                style={{
                    top: 36
                }}
                className="node-handle"
            />
            <Modal
                open={modalOpen}
                title="Set value"
                okButtonProps={{
                    type: 'primary',
                }}
                onOk={() => handleSubmit()}
                onCancel={() => setModalOpen(false)}
            >
                <UserGroupIdForm
                    form={form}
                />
            </Modal>
        </>
    );
}