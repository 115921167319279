import { Select } from "antd";

export default function BookingAllowPetsInput(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <Select
            options={[
                {
                    label: 'No alowed',
                    value: 'noAllowed',
                },
                {
                    label: 'Allowed',
                    value: 'allowed',
                },
                {
                    label: 'Allowed on request',
                    value: 'allowedOnRequest',
                },
            ]}
            value={value}
            onChange={onChange}
            style={{
                width: '200px',
            }}
        />
    );
}