import { useQuery } from "@apollo/client";
import { Button, Col, Row, Select } from "antd";
import { fuzzySearch } from "common/common";
import { gql } from "graphql.macro";

const QUERY = gql`
    query GetApartmentTags {
        organization(organizationId: "self") {
            id
            apartmentTags
        }
    }
`;

function OrIcon() {
    return (
        <div
            style={{
                width: '30px',
                fontSize: '10px',
                textAlign: 'center',
            }}
        >
            OR
        </div>
    )
}

function AndIcon() {
    return (
        <div
            style={{
                width: '30px',
                fontSize: '10px',
                textAlign: 'center',
            }}
        >
            AND
        </div>
    )
}

export default function ApartmentTagsSelect(props) {
    const {
        value,
        onChange,
        showLogicalOperator,
        logicalOperator,
        onLogicalOperatorChange,
        ...otherProps
    } = props;

    const { data, loading, error } = useQuery(QUERY);

    if (loading) {
        return (
            <Select
                loading
                style={{
                    width: '250px',
                }}
            />
        );
    }

    if (error) {
        return (
            <Select
                status="error"
                style={{
                    width: '250px',
                }}
            />
        );
    }

    const options = [...data.organization.apartmentTags ?? []]
        .map(tag => ({
            label: tag,
            value: tag,
        }));

    if (showLogicalOperator) {
        return (
            <Row>
                <Col>
                    <Select
                        options={options}
                        value={value}
                        onChange={value => onChange(value)}
                        showSearch
                        filterOption={(input, option) => fuzzySearch(option.label, input)}
                        style={{
                            width: '250px',
                        }}
                        {...otherProps}
                    />
                </Col>
                <Col
                    style={{
                        marginLeft: '-1px',
                    }}
                >
                    <Button
                        icon={logicalOperator === 'or' ? <OrIcon /> : <AndIcon />}
                        onClick={() => onLogicalOperatorChange(logicalOperator === 'or' ? 'and' : 'or')}
                    />
                </Col>
            </Row>
        );
    }
    else {
        return (
            <Select
                options={options}
                value={value}
                onChange={value => onChange(value)}
                showSearch
                filterOption={(input, option) => fuzzySearch(option.label, input)}
                style={{
                    width: '100%',
                }}
                {...otherProps}
            />
        );
    }
}