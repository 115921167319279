import { Col, Input, Row, Switch } from "antd";

export default function AirbnbAllowChildrenInput(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <Row
            gutter={[16, 16]}
            style={{
                paddingTop: !value?.enabled && '5px',
            }}
        >
            <Col span={24}>
                <Switch
                    checked={value?.enabled}
                    onChange={enabled => onChange({ ...value ?? {}, enabled })}
                />
            </Col>
            {!value?.enabled && (
                <Col span={24}>
                    <Input.TextArea
                        value={value?.description}
                        onChange={e => onChange({ ...value ?? {}, description: e.target.value })}
                        placeholder="Explanation"
                    />
                </Col>
            )}
        </Row>
    );
}