import { SaveOutlined, ThunderboltOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Alert, Button, Card, Col, Form, message, PageHeader, Row } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CodeEditor from "stayql/components/code-editor/CodeEditor";
import StayqlTable from "stayql/components/stayql-table/StayqlTable";
import QueryParamsForm from "stayql/forms/QueryParamsForm";
import QueryVariablesForm from "stayql/forms/QueryVariablesForm";

const QUERY = gql`
    query GetResultForCreateStayqlQuery($input: RunStayqlQueryInplaceInput!) {
        runStayqlQueryInplace(input: $input) {
            columns {
                name
                type
            }
            rows {
                values
            }
            error {
                message
            }
        }
    }
`;

const MUTATION = gql`
    mutation CreateStayqlQuery($input: CreateStayqlQueryInput!) {
        createStayqlQuery(input: $input) {
            stayqlQuery {
                id
                name
                tags
                query
                variables {
                    name
                    type
                }
            }
            error {
                type
                message
            }
        }
    }
`;

export default function CreateStayqlQueryView() {
    const navigate = useNavigate();

    const [query, setQuery] = useState();
    const [variablesModalOpen, setVariablesModalOpen] = useState(false);
    const [variables, setVariables] = useState();
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const [variablesForm] = Form.useForm();
    const [paramsForm] = Form.useForm();

    const [runStayqlQueryInplace, { data, loading, error, called }] = useLazyQuery(QUERY);
    const [createStayqlQuery, { loading: createStayqlQueryLoading }] = useMutation(MUTATION, {
        update(cache) {
            cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'stayqlQueries',
            });
        },
    });

    function handleRunQuery() {
        runStayqlQueryInplace({
            variables: {
                input: {
                    query,
                    variables: variables ?? [],
                },
            },
            fetchPolicy: 'no-cache',
        });
    }

    function handleVariablesSave() {
        variablesForm
            .validateFields()
            .then(values => {
                setVariables(values.variables);
                setVariablesModalOpen(false);
            });
    }

    function handleCreateQuery() {
        paramsForm
            .validateFields()
            .then(values => {
                createStayqlQuery({
                    variables: {
                        input: {
                            name: values.name,
                            tags: values.tags ?? [],
                            query,
                            variables: [...variables ?? []].map(variable => ({
                                name: variable.name,
                                type: variable.type,
                            })),
                        },
                    },
                })
                    .then(response => {
                        if (response.data.createStayqlQuery.error) {
                            message.error("Failed to create query");
                        }
                        else {
                            message.success("Query created");

                            const stayqlQueryId = response.data.createStayqlQuery.stayqlQuery.id;
                            navigate(`/stayql/${stayqlQueryId}/edit`, { replace: true });
                        }
                    })
                    .catch(() => {
                        message.error("Network error");
                    });
            })
    }

    return (
        <PageHeader
            title="Create query"
            onBack={() => navigate(-1)}
            extra={[
                <Button
                    type="primary"
                    onClick={() => setCreateModalOpen(true)}
                    icon={<SaveOutlined />}
                    disabled={!query}
                    key="createQueryButton"
                >
                    Create query
                </Button>
            ]}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <CodeEditor
                                    value={query}
                                    onChange={value => setQuery(value)}
                                    language="sql"
                                />
                            </Col>
                            <Col span={24}>
                                <Row
                                    justify="end"
                                    gutter={[16, 16]}
                                >
                                    <Col>
                                        <Button
                                            onClick={() => setVariablesModalOpen(true)}
                                            icon={<UnorderedListOutlined />}
                                        >
                                            Variables
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            type="primary"
                                            onClick={() => handleRunQuery()}
                                            icon={<ThunderboltOutlined />}
                                            loading={loading}
                                        >
                                            Run
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                {called && (
                    <Col span={24}>
                        {error && (
                            <Alert
                                type="error"
                                showIcon
                                message="Failed to load query results"
                            />
                        )}
                        {data?.runStayqlQueryInplace?.error && (
                            <Alert
                                type="error"
                                showIcon
                                message={data.runStayqlQueryInplace.error.message}
                            />
                        )}
                        {!data?.runStayqlQueryInplace?.error && (
                            <StayqlTable
                                columns={data?.runStayqlQueryInplace?.columns}
                                rows={data?.runStayqlQueryInplace?.rows}
                            />
                        )}
                    </Col>
                )}
            </Row>
            <Modal
                open={createModalOpen}
                title="Create query"
                okText="Create"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: createStayqlQueryLoading,
                }}
                onOk={() => handleCreateQuery()}
                onCancel={() => setCreateModalOpen(false)}
                destroyOnClose
            >
                <QueryParamsForm
                    form={paramsForm}
                    preserve={false}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                />
            </Modal>
            <Modal
                open={variablesModalOpen}
                title="Variables"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />
                }}
                onOk={() => handleVariablesSave()}
                onCancel={() => setVariablesModalOpen(false)}
                destroyOnClose
            >
                <QueryVariablesForm
                    form={variablesForm}
                    variables={variables}
                    preserve={false}
                />
            </Modal>
        </PageHeader>
    );
}